<template>
	<card>
		<div class="table-full-width table-responsive">
			<base-table
				v-if="services.length > 0"
				:data="services"
				:columns="serviceAttributes"
				thead-classes="text-primary"
			>
				<template slot-scope="{ row }">
					<td :key="column.id" v-for="column in Object.entries(row)">
						<template v-if="column[0] == 'slug'">
							<router-link
								:to="{
									name: 'homeApp',
									params: { slug: column[1] }
								}"
								>{{ column[1] }}</router-link
							>
						</template>
						<template v-else>
							{{ column[1] }}
						</template>
					</td>
					<base-button @click="selectService(row)" type="link" aria-label="edit button">
						<i class="tim-icons icon-pencil"></i>
					</base-button>
				</template>
			</base-table>
		</div>
	</card>
</template>
<script>
import { BaseTable } from '@/components';
import BaseButton from '../../components/BaseButton.vue';
import ServiceForm from './ServiceForm';

export default {
	components: {
		BaseTable,
		BaseButton,
		ServiceForm
	},
	data() {
		return {
			name: 'Servizi',
			model: {
				type: Object,
				default: () => {
					return {};
				}
			}
		};
	},
	methods: {
		selectService(model) {
		
			this.$router.push({ name: 'dashboard', params: { slug: model.slug } }).catch(() => {});
		}
	},
	computed: {
		services() {
			return this.$store.getters.services;
		},
		serviceAttributes() {
			let attributes = Object.keys(this.services[0]);
			return attributes;
		}
	},
	created() {

	}
};
</script>
<style></style>
