<template>
<div>
    <div class="card">
        <div class="row center">
            <div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
                <img class="logo" :src="logo" />
            </div>

            <div class="col-sm-8 col-md-8 col-lg-10">
                <div class="card-body">
                    <h4 class="card-title">
                        <strong>Ministero dell'Interno</strong>
                        <br />
                        DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
                        <br />Albo nazionale dei Segretari Comunali e Provinciali
                    </h4>
                </div>
            </div>
        </div>
    </div>
    <card v-if="app.code == 'coa7' || app.code == 'coa2021'">
        <div v-if="app.public || user.type == 'admin'">
            <b> CONCORSO {{ app.code.toUpperCase() }} </b> <span> </span> dal {{ app.openTime }} al
            {{ app.closeTime }}
            <h4 class="card-title">{{ app.title }}</h4>
            <div v-if="!isLoggedIn">
                <base-alert class="col-md-12" type="default">
                    Non è più possibile effettuare domanda di partecipazione.
                </base-alert>
                <base-button @click="signin" class="btn btn-primary">Accedi</base-button>
            </div>
            <div v-else-if="isOpen">
                <p class="card-text"><b>Per presentare domanda è necessario accedere</b></p>
                <base-button @click="signin" class="btn btn-primary">Accedi</base-button>
            </div>
            <ul v-if="!user">
                <br>
                <h4 style="margin-left: 10px">Comunicazioni</h4>
                <li style="text-align:justify;  margin-top:30px;">
                    <h4>
                        <small>Avviso del 19/12/2023</small> <br /><b>Corso-concorso di formazione “Co.A 2021”. Graduatoria ed elenco ammessi. Comunicazioni.

</b>
                    </h4>

                    <p style="font-size:14px;">
                        In riferimento al concorso pubblico, per esami, per l'ammissione di quattrocentoquarantotto borsisti al corso concorso selettivo di
                        formazione – edizione 2021 per il conseguimento dell'abilitazione richiesta ai fini dell'iscrizione di trecentoquarantacinque segretari
                         comunali nella fascia inziale dell'Albo dei segretari comunali e provinciali, si pubblica il decreto prot. n. 35764 del 15 dicembre 2023
                         del Vice Capo Dipartimento Vicario, Direttore Centrale per le Autonomie, Prefetto Caterina Amato <b>con il quale si approvano la graduatoria
                          di ammissione, con i relativi punteggi, e l'elenco dei 448 candidati ammessi a partecipare al corso concorso selettivo di formazione “Co.A 2021”
                        </b>
                    </p>

                    <p style="font-size:14px;">
                        Eventuali reclami contro la graduatoria di ammissione al corso-concorso potranno essere proposti entro e non oltre il termine di sette giorni
                        dalla data di pubblicazione. Detti reclami dovranno essere avanzati esclusivamente tramite pec all'indirizzo <a href="mailto:concorsi.scanshare@pec.it">concorsi.scanshare@pec.it</a> indicando le motivazioni del reclamo stesso.
                            <br><br>
                        Si evidenzia che a norma dell'art. 10, comma 5, del bando di concorso pubblicato nella G.U.R.I - 4ª  Serie  speciale  - n.89 del 9 novembre 2021:
                         <i>“<b>Entro quindici giorni</b> dalla data di pubblicazione della graduatoria di ammissione al corso-concorso i candidati ammessi dovranno, a pena di decadenza,
                          <b>confermare tramite piattaforma l'impegno a partecipare al corso-concorso</b>”.</i>
                          <br><br>

                        Si fornisce il <b>modulo di conferma della partecipazione</b> al corso-concorso, da caricare in formato.pdf sulla presente piattaforma accedendo all'area personale
                            <br><br>
                            Qualora non si intendesse partecipare al corso-concorso, la comunicazione della rinuncia deve essere tempestivamente inviata a mezzo posta elettronica
                            certificata all'indirizzo <a href="mailto:protocollo.albosegretari@pec.interno.it">protocollo.albosegretari@pec.interno.it</a> .

                            Le comunicazioni inerenti l'organizzazione e lo svolgimento del corso-concorso, compresi il calendario e le sue eventuali modifiche nonché la regolamentazione didattica saranno
                            pubblicate sul sito web dell'Albo nazionale <a href="https://albosegretari.interno.gov.it" target="_blank">https://albosegretari.interno.gov.it</a>  nella sezione dedicata al corso. Tali pubblicazioni avranno valore di notifica a tutti gli effetti di legge e nei confronti di tutti gli interessati.  </b>
                    </p>
                    <br><br>
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('DecretoCoa2021')" style="color:white">Decreto graduatoria</a>
                    </span>&emsp;
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('ElencoAmmessiCoa2021')" style="color:white">Elenco ammessi</a>
                    </span>&emsp;
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('GraduatoriaCoa2021')" style="color:white">Graduatoria</a>
                    </span>&emsp;
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('ModuloConferma2021')" style="color:white">Modulo partecipazione</a>
                    </span>
                </li>
                <br>
                <li v-for="comunication in comunications" :key="comunication.id">
                    <h4>
                        <small> {{ formatDate(new Date(comunication.createdAt)) }}</small> <br /> <b>{{ comunication.oggetto }} </b>
                    </h4>
                    <p v-html="comunication.testo.replace('\n', '<br />')"></p>
                    <span style="text-align: left">

                        <a class="btn btn-info btn-sm" @click="downloadAllegatoComunicazione(comunication.id)" style="color: white">Scarica Allegato</a>
                    </span>

                    <br />
                    <hr />
                </li>
            </ul>
            <ul v-if="!user">
                <li style="text-align:justify;  margin-top:30px;">
                    <h4>
                        <small>Avviso del 25/08/2023</small> <br /><b>Coa 2021. Completamento calendario prove orali
                        </b>
                    </h4>
                    <p>
                        Si pubblica, in allegato, il completamento del calendario delle prove orali del concorso pubblico, per esami, per l’ammissione di quattrocentoquarantotto borsisti al corso-concorso selettivo di formazione – edizione 2021, per il conseguimento dell’abilitazione richiesta ai fini dell’iscrizione di trecentoquarantacinque segretari comunali nella fascia iniziale dell’albo dei segretari comunali e provinciali pubblicato nella Gazzetta Ufficiale, 4a serie speciale, n. 89 del 9 novembre 2021 , definito dalla Commissione esaminatrice.<br>
                        Il calendario è formulato in ordine alfabetico.<br>
                        Le prove si svolgeranno presso la sede del Ministero dell’Interno – Albo nazionale dei segretari comunali e provinciali, sita in Piazza Cavour, 25.
                        I candidati dovranno presentarsi muniti di un documento di riconoscimento in corso di validità.
                        Il presente avviso ha valore di notifica a tutti gli effetti.
                    </p>
                    <br>
                    <hr>
                    <p style="font-size:12px;">
                        In relazione al quale l’art. 1, comma 825, della legge 29 dicembre 2022, n. 197 ha autorizzato il Ministero dell'interno ad iscrivere al menzionato albo, in aggiunta a quelli previsti dal bando, anche i borsisti non vincitori che abbiano conseguito il punteggio minimo di idoneità al termine del citato corso-concorso selettivo di formazione.
                    </p>
                    <br>
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('completamentocalendario')" style="color:white">completamento calendario prove orali</a>
                    </span>
                </li>
            </ul>

            <div v-else class="my-3">
                <b-tabs v-model="activeTab">
                    <b-tab title="Area riservata">
                        <card class="row">
                            <h4 class="my-3">
                                <b class="mx-2">Login effettuato con SPID.</b><br />

                                <!--     <li style="list-style-type: none;">
                                    <a href="#" @click="toggleTab('comunicazioni')">Visualizza Comunicazioni</a>
                                </li>
                                <li style="list-style-type: none;">
                                    <a href="#" @click="toggleTab('faq')">Visualizza Domande Frequenti</a>
                                </li> -->
                                <ul>
                                    <li v-if="isOpen">
                                        Nella compilazione della domanda non sarà necessario inserire i dati
                                        anagrafici in quanto già acquisiti attraverso SPID.
                                    </li>
                                </ul>
                            </h4>

                            <h4 class="my-3">
                                <b class="mx-2">Conferma partecipaziona COA2021</b>
                            </h4>
                            <div v-if="user.uploadTimeAcceptance">
                                <base-alert class="col-md-12" type="default" style="margin-bottom:5px">
                                    Conferma partecipazione COA2021 caricata correttamente. E' possibile caricare nuovamente il file. Il nuovo file sostituirà il precedente. </base-alert>
                                <a class="btn btn-info btn-sm" style="color:white" @click="downloadRicevutaAccettazione()">Scarica ricevuta conferma partecipazione</a>
                            </div>

                            <label style="margin-left:10px"><b>
                                    Peso massimo 8 Mb.</b></label><br />
                            <file-upload class="mb-2" :userId="user.accessToken" :url="'/uploadAcceptance'" style="margin-left:10px"></file-upload>

                            <br>
                            <div v-if="user">
                                <h4 class="my-3">
                                    <b class="mx-2">Titoli di preferenza</b>
                                    <ul>
                                        <li>
                                            Dal 5 Settembre è possibile allegare i titoli di preferenza.
                                        </li>
                                    </ul>
                                </h4>

                                <div v-if="user.uploadTime">
                                    <base-alert class="col-md-12" type="default" style="margin-bottom:5px">
                                        Titoli caricati correttamente. E' possibile caricare nuovamente il file. Il nuovo file sostituirà il precedente.
                                    </base-alert>
                                    <a class="btn btn-info btn-sm" style="color:white" @click="downloadRicevuta()">Scarica ricevuta caricamento titoli</a>
                                </div>
                                <br>
                                <label style="margin-left:10px"><b>
                                        Allegare un unico file pdf contenente i titoli di preferenza. Peso massimo 8 Mb.</b></label><br />
                                <file-upload class="mb-2" :userId="user.accessToken" :url="'/uploadFile'" style="margin-left:10px"></file-upload>
                                <h4 class="my-3">
                                    <b class="mx-2"></b>
                                    <ul>
                                        <li>
                                          Per presentare reclamo scrivere tramite pec all'indirizzo <a href="mailto:concorsi.scanshare@pec.it">concorsi.scanshare@pec.it</a> indicando le motivazioni dello stesso.                          </li>
                                    </ul>
                                </h4>
                            </div>
                            <br>
                            <h4 class="my-3">
                                <b class="mx-2">Domanda di candidatura</b><br />
                                <ul>
                                    <div v-if="isOpen">
                                        <li>E' possibile presentare domanda fino al {{ app.closeTime }}.</li>
                                        <li>
                                            Prima di compilare la domanda si consiglia di leggere attentamente il
                                            bando e le <b>FAQ</b>
                                        </li>
                                    </div>
                                </ul>
                            </h4>

                            <base-alert class="col-md-12" type="default">
                                <span v-if="!this.app.status">
                                    <b>Nessuna domanda di candidatura salvata.</b>
                                    <span v-if="isOpen"> Apri il form per compilare la domanda. </span><span v-else> Non è più possibile effettuare domanda di partecipazione</span>
                                </span>
                                <span v-else-if="this.app.status == 'salvata'">
                                    Stato domanda: <b>Salvata.</b>
                                    <span v-if="isOpen">
                                        E' possibile modificare la domanda prima di inviarla. La domanda deve essere
                                        inviata entro il {{ app.closeTime }}. </span><span v-else> Non è più possibile modificare la domanda di candidatura</span>
                                </span>
                                <span v-else-if="this.app.status == 'inviata'">
                                    Stato domanda: <b>Inviata.</b>
                                    <span v-if="isOpen">
                                        Nel caso si modifichi la domanda sarà necessario inviarla nuovamente. E'
                                        possibile farlo fino il {{ app.closeTime }}.</span>
                                </span>
                            </base-alert>
                            <div v-if="this.app.status" id="updateData">
                                <div class="row">
                                    <h4><b>Aggiorna dati</b></h4>
                                    <p>
                                        <b>
                                            Nel caso in cui i propri recapiti siano cambiati è necessario aggiornare
                                            i dati attraverso il form sottostante. La compilazione del domicilio può
                                            esssere omessa se coincidente con la propria residenza.
                                        </b>
                                    </p>
                                    <p>
                                        Per effettuare la modifica di pec, residenza o domicilio, premere il
                                        pulsante sblocca, aggiornare i dati necessari e successivamente premere il
                                        pulsante Salva i dati.
                                    </p>
                                    <br /><br />

                                    <div class="col-md-2">
                                        <base-button size="sm" type="info" :class="{ btn: true, 'btn-info': true, 'pull-left': true }" @click="toggleUpdateDataForm">
                                            {{ blockUpdateDataForm ? 'Sblocca' : 'Blocca' }}
                                        </base-button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="updateEmail">Email</label>
                                        <input type="email" name="updateEmail" id="updateEmail" class="form form-control updateDataForm" v-model="updateDataForm.email" style="pointer-events:none; background-color:#E3E3E3" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="updateRepeatEmail">Ripeti email</label>
                                        <input type="email" name="updateRepeatEmail" id="updateRepeatEmail" class="form form-control updateDataForm" v-model="updateDataForm.repeatEmail" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label for="updatePec">Pec</label>
                                        <input type="email" name="updatePec" id="updatePec" class="form form-control updateDataForm" v-model="updateDataForm.pec" style="pointer-events:none; background-color:#E3E3E3" />
                                    </div>
                                    <div class="col-md-6">
                                        <label for="updateRepeatPec">Ripeti pec</label>
                                        <input type="email" name="updateRepeatPec" id="updateRepeatPec" class="form form-control updateDataForm" v-model="updateDataForm.repeatPec" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}" />
                                    </div>
                                </div>

                                <br />
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="statoDomicilio">Stato Domicilio</label>
                                        <select type="text" name="statoDomicilio" id="statoDomicilio" class="form form-control updateDataForm" v-model="updateDataForm.statoDomicilio" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}">
                                            <option value=""> - Seleziona Stato - </option>
                                            <option v-for="state in updateDataForm.states" :key="state">
                                                {{ state }}
                                            </option>
                                        </select>
                                        <p>in caso di domicilio estero selezionare provincia EE</p>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="provinciaDomicilio">Provincia Domicilio</label>
                                        <select type="text" name="provinciaDomicilio" id="provinciaDomicilio" class="form form-control updateDataForm" v-model="updateDataForm.provinciaDomicilio" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}">
                                            <option value=""> - Seleziona Domicilio - </option>
                                            <option v-for="provincia in updateDataForm.province" :key="provincia">
                                                {{ provincia }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="comuneDomicilio">Comune Domicilio</label>
                                        <input type="text" name="comuneDomicilio" id="comuneDomicilio" class="form form-control updateDataForm" v-model="updateDataForm.comuneDomicilio" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}" />
                                    </div>
                                    <div class="col-md-5">
                                        <label for="domicilio">Indirizzo Domicilio</label>
                                        <input type="text" name="domicilio" id="domicilio" class="form form-control updateDataForm" v-model="updateDataForm.domicilio" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}" />
                                    </div>
                                    <div class="col-md-3">
                                        <label for="statoResidenza">Stato Residenza</label>
                                        <select type="text" name="statoResidenza" id="statoResidenza" class="form form-control updateDataForm" v-model="updateDataForm.statoResidenza" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}">
                                            <option value=""> - Seleziona Stato - </option>
                                            <option v-for="state in updateDataForm.states" :key="state">
                                                {{ state }}
                                            </option>
                                        </select>
                                        <p>in caso di residenza estera selezionare provincia EE</p>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="provinciaResidenza">Provincia Residenza</label>
                                        <select type="text" name="provinciaResidenza" id="provinciaResidenza" class="form form-control updateDataForm" v-model="updateDataForm.provinciaResidenza" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}">
                                            <option value=""> - Seleziona Provincia -</option>
                                            <option v-for="provincia in updateDataForm.province" :key="provincia">
                                                {{ provincia }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-md-2">
                                        <label for="comuneResidenza">Comune Residenza</label>
                                        <input type="text" name="comuneResidenza" id="comuneResidenza" class="form form-control updateDataForm" v-model="updateDataForm.comuneResidenza" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}" />
                                    </div>
                                    <div class="col-md-5">
                                        <label for="residenza">Indirizzo Residenza</label>
                                        <input type="text" name="residenza" id="residenza" class="form form-control updateDataForm" v-model="updateDataForm.residenza" :style="{

pointerEvents: 'none',

backgroundColor: '#E3E3E3'

}" />
                                    </div>
                                </div>
                                <div>
                                    <base-button size="sm" type="info" :class="{

'col-md-12': true,

btn: true,

'btn-primary': true,

disabled: blockUpdateDataForm

}" style="margin-top:30px;" @click="updateData">
                                        Salva dati
                                    </base-button>
                                    <br />
                                </div>
                            </div>
                            <div v-if="app.status == 'inviata'">
                                <h4 v-if="isOpen">
                                    Domanda inviata. E' possibile modificare la domanda fino allo scadere dei
                                    termini di presentazione. Se si modifica la domanda sarà necessario inviarla
                                    nuovamente.
                                </h4>
                            </div>
                            <div v-if="isOpen">
                                <div v-if="requiredUpload && uploaded && app.status != 'inviata'">
                                    <base-alert class="col-md-12" type="default">
                                        <span v-if="app.status == 'inviata'">
                                            Documentazione caricata correttamente. Ora è possibile inviare la
                                            domanda
                                        </span>
                                        <span v-else>
                                            <b>Allegato caricato correttamente</b>
                                        </span>
                                    </base-alert>
                                    <h5>
                                        <b>
                                            E' possibile sostituire la documentazione caricata allegando un nuovo
                                            file. Se non è necessario la domanda è pronta per essere inviata.</b>
                                    </h5>
                                    <file-upload class="mb-2" :userId="user.accessToken" :url="'/uploadFile'"></file-upload>
                                </div>
                                <div v-if="requiredUpload && !uploaded" class="col-md-12 my-4">
                                    <h5>
                                        Prima di inviare la domanda si richiede di allegare la documentazione di
                                        supporto alla dichiarazione resa sulla propria disabilità dovrà essere
                                        caricata in formato digitale (PDF)
                                    </h5>
                                    <file-upload :userId="user.accessToken" :url="'/uploadFile'"></file-upload>
                                </div>
                            </div>
                            <div v-else>
                                <div v-if="requiredUpload && uploaded && app.status != 'inviata'">
                                    <base-alert class="col-md-12" type="default">
                                        <span v-if="app.status == 'inviata'">
                                            Documentazione caricata correttamente.
                                        </span>
                                        <span v-else>
                                            <b>Allegato caricato correttamente</b>
                                        </span>
                                    </base-alert>
                                    <h5>
                                        <b> La domanda risulta non inviata</b>
                                    </h5>
                                </div>
                                <div v-if="requiredUpload && uploaded && app.status == 'inviata'">
                                    <base-alert class="col-md-12" type="default">
                                        <span v-if="app.status == 'inviata'">
                                            Documentazione caricata correttamente.
                                        </span>
                                        <span v-else>
                                            <b>Allegato caricato correttamente</b>
                                        </span>
                                    </base-alert>
                                </div>
                                <div v-if="requiredUpload && !uploaded" class="col-md-12 my-4"></div>
                            </div>
                            <div v-if="isOpen">
                                <base-button @click="expand = !expand" size="sm" type="info" :class="{ 'col-md-12': true, btn: true, 'btn-info': true }">
                                    {{ expand ? 'Chiudi form' : 'Apri form' }}
                                </base-button>
                                <base-button :class="{

'col-md-12': true,

btn: true,

'btn-info': true,

disabled: app.status ? false : true

}" size="sm" @click="preview = !preview">
                                    {{

!preview

? 'Visualizza anteprima Domanda di candidatura'

: 'Nascondi anteprima Domanda di candidatura'

}}</base-button>
                                <base-button :class="{

'col-md-12': true,

btn: true,

'btn-info': true,

disabled: !app.status

? true

: requiredUpload && !uploaded

? true

: app.status == 'inviata'

? true

: false

}" @click="inviaDomanda" size="sm">Invia Domanda di candidatura</base-button>
                            </div>
                            <div v-else></div>

                            <div class="card">
                                <base-button @click="scaricaDomanda" size="sm" type="info" :class="{

'col-md-12': true,

btn: true,

'btn-primary': true,

disabled: !(app.status == 'inviata')

}">
                                    Scarica ricevuta della domanda di candidatura
                                </base-button>
                            </div>
                            <form-wizard v-if="isLoggedIn && expand" :title="app.formTitle" subtitle="" nextButtonText="Prosegui" backButtonText="Indietro" finishButtonText="Salva" stepSize="xs" color="#2C69F6" @on-complete="onComplete" error-color="#a94442">
                                <tab-content title="Domanda di candidatura" icon="ti-user" :before-change="validateFirstTab">
                                    <h4 style="text-align:center">
                                        Il candidato dichiara, sotto la propria responsabilità e consapevole delle
                                        conseguenze derivanti da dichiarazioni mendaci ai sensi dell 'art. 76 del
                                        d.P.R n. 445/2000 quanto segue:
                                    </h4>
                                    <vue-form-generator :model="app.model" :schema="app.form" :options="formOptions" ref="firstTabForm">
                                    </vue-form-generator>
                                </tab-content>
                                <div></div>
                            </form-wizard>

                            <div v-show="preview">
                                <br />
                                <h2 style="text-align:center">
                                    <b>Ministero dell'Interno - Dipartimento per gli affari interni e territoriali
                                    </b>
                                </h2>
                                <h3 style="text-align:center"><b>Domanda di candidatura</b></h3>
                                <h3 style="text-align:center">{{ app.title }}</h3>

                                <ul>
                                    <li v-for="field in app.form.fields" :key="field.model" v-show="field.model !== 'repeatPec' && field.model !== 'repeatEmail'">
                                        <h4 :class="{ hidden: app.model[field.model] == '' }">
                                            <p>{{ field.label }}</p>
                                            <b v-if="field.model == 'titoliPreferenza'">{{ joinPreferenze }}</b>
                                            <b v-else>{{ app.model[field.model] }}</b>
                                        </h4>
                                    </li>
                                </ul>
                            </div>
                        </card>
                    </b-tab>

                    <b-tab title="Comunicazioni" class="active">
                        <card class="row">
                            <ul>
                                <li>
                                    <h4><b>Variazione recapiti</b></h4>
                                    <p>
                                        Si ricorda che, come stabilito all’articolo 3, comma 13 del bando di
                                        concorso, COA 2021, eventuali variazioni di indirizzo e/o di recapito della
                                        posta elettronica certificata (PEC) e/o della posta elettronica ordinaria
                                        dovranno essere comunicate dal candidato esclusivamente tramite la presente
                                        piattaforma. Grazie della collaborazione
                                    </p>
                                </li>
                            </ul>
                            <hr>
                            <br>
                            <ul>
                                <li style="text-align:justify;  margin-top:30px;">
                    <h4>
                        <small>Avviso del 19/12/2023</small> <br /><b>Corso-concorso di formazione “Co.A 2021”. Graduatoria ed elenco ammessi. Comunicazioni.

</b>
                    </h4>

                    <p style="font-size:14px;">
                        In riferimento al concorso pubblico, per esami, per l'ammissione di quattrocentoquarantotto borsisti al corso concorso selettivo di
                        formazione – edizione 2021 per il conseguimento dell'abilitazione richiesta ai fini dell'iscrizione di trecentoquarantacinque segretari
                         comunali nella fascia inziale dell'Albo dei segretari comunali e provinciali, si pubblica il decreto prot. n. 35764 del 15 dicembre 2023
                         del Vice Capo Dipartimento Vicario, Direttore Centrale per le Autonomie, Prefetto Caterina Amato <b>con il quale si approvano la graduatoria
                          di ammissione, con i relativi punteggi, e l'elenco dei 448 candidati ammessi a partecipare al corso concorso selettivo di formazione “Co.A 2021”
                        </b>
                    </p>

                    <p style="font-size:14px;">
                        Eventuali reclami contro la graduatoria di ammissione al corso-concorso potranno essere proposti entro e non oltre il termine di sette giorni
                        dalla data di pubblicazione. Detti reclami dovranno essere avanzati esclusivamente tramite pec all'indirizzo <a href="mailto:concorsi.scanshare@pec.it">concorsi.scanshare@pec.it</a> indicando le motivazioni del reclamo stesso.
                            <br><br>
                        Si evidenzia che a norma dell'art. 10, comma 5, del bando di concorso pubblicato nella G.U.R.I - 4ª  Serie  speciale  - n.89 del 9 novembre 2021:
                         <i>“<b>Entro quindici giorni</b> dalla data di pubblicazione della graduatoria di ammissione al corso-concorso i candidati ammessi dovranno, a pena di decadenza,
                          <b>confermare tramite piattaforma l'impegno a partecipare al corso-concorso</b>”.</i>
                          <br><br>

                        Si fornisce il <b>modulo di conferma della partecipazione</b> al corso-concorso, da caricare in formato.pdf sulla presente piattaforma accedendo all'area personale
                            <br><br>
                            Qualora non si intendesse partecipare al corso-concorso, la comunicazione della rinuncia deve essere tempestivamente inviata a mezzo posta elettronica
                            certificata all'indirizzo <a href="mailto:protocollo.albosegretari@pec.interno.it">protocollo.albosegretari@pec.interno.it</a> .

                            Le comunicazioni inerenti l'organizzazione e lo svolgimento del corso-concorso, compresi il calendario e le sue eventuali modifiche nonché la regolamentazione didattica saranno
                            pubblicate sul sito web dell'Albo nazionale <a href="https://albosegretari.interno.gov.it" target="_blank">https://albosegretari.interno.gov.it</a>  nella sezione dedicata al corso. Tali pubblicazioni avranno valore di notifica a tutti gli effetti di legge e nei confronti di tutti gli interessati.  </b>
                    </p>
                    <br><br>
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('DecretoCoa2021')" style="color:white">Decreto graduatoria</a>
                    </span>&emsp;
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('ElencoAmmessiCoa2021')" style="color:white">Elenco ammessi</a>
                    </span>&emsp;
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('GraduatoriaCoa2021')" style="color:white">Graduatoria</a>
                    </span>&emsp;
                    <span style="text-align:left">
                        <a class="btn btn-info btn-sm" @click="downloadAllegato('ModuloConferma2021')" style="color:white">Modulo partecipazione</a>
                    </span>
                </li>
                <br>
                                <li v-for="comunication in comunications" :key="comunication.id">
                                    <h4>
                                        <small> {{ formatDate(new Date(comunication.createdAt)) }}</small> <br /> <b>{{ comunication.oggetto }} </b>
                                    </h4>
                                    <p v-html="comunication.testo.replace('\n', '<br />')"></p>
                                    <span style="text-align: left">
                                        <a class="btn btn-info btn-sm" @click="downloadAllegatoComunicazione(comunication.id)" style="color: white">Scarica Allegato</a>
                                    </span>
                                    <br />
                                    <hr />
                                </li>
                            </ul>

                            <ul>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Avviso del 25/08/2023</small> <br /><b>Coa 2021. Completamento calendario prove orali
                                        </b>
                                    </h4>
                                    <p>
                                        Si pubblica, in allegato, il completamento del calendario delle prove orali del concorso pubblico, per esami, per l’ammissione di quattrocentoquarantotto borsisti al corso-concorso selettivo di formazione – edizione 2021, per il conseguimento dell’abilitazione richiesta ai fini dell’iscrizione di trecentoquarantacinque segretari comunali nella fascia iniziale dell’albo dei segretari comunali e provinciali pubblicato nella Gazzetta Ufficiale, 4a serie speciale, n. 89 del 9 novembre 2021 , definito dalla Commissione esaminatrice.<br>
                                        Il calendario è formulato in ordine alfabetico.<br>
                                        Le prove si svolgeranno presso la sede del Ministero dell’Interno – Albo nazionale dei segretari comunali e provinciali, sita in Piazza Cavour, 25.
                                        I candidati dovranno presentarsi muniti di un documento di riconoscimento in corso di validità.
                                        Il presente avviso ha valore di notifica a tutti gli effetti.
                                    </p>
                                    <br>
                                    <hr>
                                    <p style="font-size:12px;">
                                        In relazione al quale l’art. 1, comma 825, della legge 29 dicembre 2022, n. 197 ha autorizzato il Ministero dell'interno ad iscrivere al menzionato albo, in aggiunta a quelli previsti dal bando, anche i borsisti non vincitori che abbiano conseguito il punteggio minimo di idoneità al termine del citato corso-concorso selettivo di formazione.
                                    </p>
                                    <br>
                                    <span style="text-align:left">
                                        <a class="btn btn-info btn-sm" @click="downloadAllegato('completamentocalendario')" style="color:white">completamento calendario prove orali</a>
                                    </span>
                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Avviso del 28/07/2023</small> <br /><b>Oggetto: Coa 2021. Calendario prove orali mese di settembre 2023
                                        </b>
                                    </h4>
                                    <p>
                                        Si pubblica, in allegato, il calendario relativo al mese di settembre 2023 delle prove orali del concorso pubblico,
                                        per esami, per l’ammissione di quattrocentoquarantotto borsisti al corso-concorso selettivo di formazione – edizione 2021,
                                        per il conseguimento dell’abilitazione richiesta ai fini dell’iscrizione di trecentoquarantacinque segretari comunali nella
                                        fascia iniziale dell’albo dei segretari comunali e provinciali pubblicato nella Gazzetta Ufficiale, 4a serie speciale,
                                        n. 89 del 9 novembre 2021 , definito dalla Commissione esaminatrice.
                                        <br>
                                        Il calendario è formulato in ordine alfabetico a partire dalla lettera N, già estratta in occasione dello svolgimento delle prove scritte.
                                        <br>
                                        Le prove si svolgeranno presso la sede del Ministero dell’Interno – Albo nazionale dei segretari comunali e provinciali, sita in Piazza Cavour, 25.
                                        <br>
                                        I candidati dovranno presentarsi muniti di un documento di riconoscimento in corso di validità.
                                        <br>
                                        <b>Il presente avviso ha valore di notifica a tutti gli effetti.</b>
                                        <br><br>
                                        <hr>
                                        <p style="font-size:12px;">
                                            In relazione al quale l’art. 1, comma 825, della legge 29 dicembre 2022, n. 197 ha autorizzato il Ministero dell'interno ad
                                            iscrivere al menzionato albo, in aggiunta a quelli previsti dal bando, anche i borsisti non vincitori che abbiano conseguito
                                            il punteggio minimo di idoneità al termine del citato corso-concorso selettivo di formazione.
                                        </p>
                                        <br>
                                        <span style="text-align:left">
                                            <a class="btn btn-info btn-sm" @click="downloadAllegato('calendarioprovaoralesettembre')" style="color:white">Allegato calendario prova orale - Settembre</a>
                                        </span>
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 23/06/2023</small> <br /><b>Oggetto: Elenco candidati ammessi alla prova orale
                                        </b>
                                    </h4>
                                    <p>
                                        In data odierna si sono svolte, in seduta pubblica, le operazioni di abbinamento degli elaborati con i nominativi dei candidati che hanno partecipato alle prove scritte del concorso pubblico, per esami, per l’ammissione di quattrocentoquarantotto borsisti al corso-concorso selettivo di formazione – edizione 2021, per il conseguimento dell’abilitazione richiesta ai fini dell’iscrizione di trecentoquarantacinque segretari comunali nella fascia iniziale dell’albo dei segretari comunali e provinciali, tenutesi in data 23 novembre 2022.
                                        All’esito della procedura i candidati ammessi alla prova orale sono n. 566.<br>
                                        Ai sensi dell’articolo 8, comma 1 del bando di concorso è pubblicato l’elenco, in ordine alfabetico, dei candidati ammessi alla prova orale.
                                        L’elenco degli ammessi è altresì pubblicato sul sito istituzionale dell’Albo dei segretari all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a><br>
                                        Sulla piattaforma del Formez - “CONCORSISMART”, accessibile all’indirizzo:<a target="_blank" href=" https://formez.concorsismart.it"> https://formez.concorsismart.it</a>, utilizzando le proprie credenziali SPID, ciascun candidato potrà consultare il punteggio conseguito nelle singole prove.<br>
                                        Con successiva comunicazione verrà reso noto il calendario delle prove orali, confermando che la lettera estratta, già in sede delle prove scritte, e la <b>“N”.</b>
                                        <br><br>
                                        <b>Il presente avviso ha valore di notifica a tutti gli effetti.</b>
                                        <br><br>
                                        <span style="text-align:left">
                                            <a class="btn btn-info btn-sm" @click="downloadAllegato('elecoammessiorale')" style="color:white">Scarica Elenco ammessi</a>
                                        </span>
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 14/11/2022</small> <br /><b>Oggetto: conferma diario prove scritte
                                        </b>
                                    </h4>
                                    <p>
                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale “Concorsi ed esami” n. 89 dell’11 novembre 2022
                                        è pubblicato l’avviso di conferma del diario delle prove scritte, pubblicato con l'avviso nella Gazzetta Ufficiale
                                        della Repubblica italiana - 4ª Serie speciale «Concorsi ed esami» - n. 82 del 14 ottobre 2022
                                        Tale pubblicazione è effettuata anche sul sito internet dell’Albo nazionale all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti.
                                    </p>
                                    <p>
                                        pubblicazione nella Gazzetta Ufficiale:<br>
                                        <a target="_blank" href="https://albosegretari.interno.gov.it/formazione/corsi-istituzionali/coa/coa-2021/comunicato-conferma-diario-prove-scritte-coa-2021">https://albosegretari.interno.gov.it/formazione/corsi-istituzionali/coa/coa-2021/comunicato-conferma-diario-prove-scritte-coa-2021</a>
                                    </p>

                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 14/11/2022</small> <br /><b>Oggetto:PIANO OPERATIVO PER LA GESTIONE E LO SVOLGIMENTO DELLE PROVE SCRITTE
                                        </b>
                                    </h4>
                                    <p>
                                        Si pubblicano, di seguito, il piano operativo per la gestione e lo svolgimento delle prove scritte del corso-concorso "COA 2021" e il Protocollo per lo svolgimento dei concorsi pubblici del 25/05/2022
                                        <br /><br />

                                        <span style="text-align:left">
                                            <a class="btn btn-info btn-sm" @click="downloadAllegato('pianoOperativo')" style="color:white">Scarica il Piano operativo per le prove scritte</a>
                                        </span><br>
                                        <span style="text-align:left">
                                            <a class="btn btn-info btn-sm" @click="downloadAllegato('protocolloConcorsi')" style="color:white">Scarica il Protocollo per lo svolgimento dei Concorsi Pubblici</a>
                                        </span>
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4><small>Comunicazione</small> <br />
                                        <b>Oggetto: Lettera di partecipeazione Prove scritte
                                        </b>
                                    </h4>
                                    <p>
                                        È stata inviata ai candidati ammessi, all’indirizzo pec indicato dagli stessi, la lettera di partecipazione alle prove scritte che si terranno il 23 novembre 2022 a Roma.
                                        La lettera di partecipazione è inviata dall’indirizzo: <a href="mailto:noreply@concorsismart.it">noreply@concorsismart.it</a> e reca informazioni utili per lo svolgimento delle prove, nonché un QRCODE indispensabile per l’accesso alle prove medesime.
                                    </p>
                                </li>
                                <hr>

                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 14/10/2022</small> <br /><b>Oggetto: Diario prove scritte
                                        </b>
                                    </h4>
                                    <p>
                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale “Concorsi ed esami” n. 82 del 14
                                        ottobre 2022 è pubblicato il diario relativo allo svolgimento delle prove scritte, con precisazione della
                                        sede, della data e dell’ora di convocazione, nonché delle informazioni circa le modalità di svolgimento
                                        delle prove medesime, di cui all’articolo 7 del bando di concorso denominato “COA 2021”. Tale
                                        pubblicazione è effettuata anche sul sito internet dell’Albo nazionale all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti.

                                        <br /><br />
                                    </p>
                                    <p>
                                        pubblicazione nella Gazzetta Ufficiale:<br>
                                        <a target="_blank" href="https://www.gazzettaufficiale.it/atto/concorsi/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2022-10-14&atto.codiceRedazionale=22E12816">https://www.gazzettaufficiale.it/atto/concorsi/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2022-10-14&atto.codiceRedazionale=22E12816</a>
                                    </p>

                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 13/09/2022</small> <br /><b>Oggetto: Rinvio diario prove scritte
                                        </b>
                                    </h4>
                                    <p>

                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale “Concorsi ed esami” n. 73
                                        del 13 settembre 2022 è pubblicato l’avviso di rinvio alla Gazzetta Ufficiale, 4° serie speciale
                                        “Concorsi ed esami” del <u>14 ottobre 2022</u>, della pubblicazione del diario relativo allo svolgimento
                                        delle prove scritte, con precisazione della sede, della data e dell’ora di convocazione,
                                        di cui all’articolo 7 del bando di concorso denominato “COA 2021”. Tale pubblicazione,
                                        come previsto dal predetto articolo, è effettuata anche sul sito internet dell’Albo nazionale
                                        all’indirizzo <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti. <br /><br />

                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione</small> <br /><b>Oggetto: Elenco ammessi prove scritte
                                        </b>
                                    </h4>
                                    <p>E’ pubblicato l’elenco dei candidati ammessi alle prove scritte del Concorso pubblico, per esami, per l'ammissione di quattrocentoquarantotto borsisti al corso-concorso selettivo di formazione - edizione 2021, per il conseguimento dell'abilitazione richiesta ai fini dell'iscrizione di trecentoquarantacinque segretari comunali nella fascia iniziale dell'Albo nazionale dei Segretari comunali e provinciali.<br>
                                        Ai sensi dell’articolo 6, comma 10 del predetto bando, sono ammessi alle prove scritte i candidati che dopo la prova preselettiva, risultano collocati entro primi milletrentacinque (1035) posti oltre gli ex aequo. I candidati classificatisi dalla posizione 1036 alla posizione 1055, hanno conseguito il punteggio ex aequo di “49.25”, soglia minima utile per il superamento della prova preselettiva. L’elenco degli ammessi agli scritti è altresì integrato con i candidati con invalidità uguale o superiore all’80% che ai sensi dell’articolo 3 comma 10 del bando non sono tenuti a sostenere la predetta prova.<br>
                                        Hanno partecipato un numero complessivo di 5.282 candidati, in otto sedi decentrate e su due turni.<br>
                                        Accedendo alla piattaforma “concorsismart” del Formez, accessibile all’indirizzo: <a target="_blank" href="https://formez.concorsismart.it"> formez.concorsismart.it</a> , utilizzando le proprie credenziali SPID, i partecipanti alla prova possono prendere visione del proprio elaborato e del relativo punteggio conseguito.<br>
                                        Il diario relativo allo svolgimento delle prove scritte, con precisazione della sede, della data e dell’ora di convocazione sarà reso noto nella Gazzetta Ufficiale della Repubblica Italiana – 4° serie speciale – Concorsi ed esami n. 73 del 13 settembre 2022, nonché mediante pubblicazione sul sito internet dell’Albo nazionale all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a> e sulla presente piattaforma.<br>

                                        La presente pubblicazione, <b>con valore di notifica a tutti gli effetti</b> , è effettuata anche sul sito internet dell’Albo nazionale all’indirizzo <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>.<br>

                                        <br /><br />

                                        <span style="text-align:left">
                                            <a class="btn btn-info btn-sm" @click="downloadAllegato('ammessiScritti')">Scarica PDF Ammessi alle prove scritte</a>
                                        </span>
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify;  margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione</small> <br /><b>Oggetto: Lettera di partecipazione prova preselettiva - COA 2021
                                        </b>
                                    </h4>
                                    <p>
                                        È stata inviata ai candidati, all’indirizzo pec indicato dagli stessi, la lettera di convocazione per sostenere la prova preselettiva che si terrà il 20 luglio 2022, contemporaneamente su 8 sedi: Segrate (Milano), Rimini, Roma, Napoli, Foggia, Catania, Siracusa e Sassari.
                                        La lettera di partecipazione è inviata dall’indirizzo: <b><i>noreply@concorsismart.it</i></b> e reca informazioni utili per la convocazione nonché un QRCODE indispensabile per l’accesso alla prova.
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify; margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione</small> <br /><b>Oggetto: Piani operativi per la gestione e lo svolgimento della prova
                                            preselettiva - COA 2021
                                        </b>
                                    </h4>
                                    <p>
                                        Si pubblicano di seguito, per ciascuna sede di concorso, i piani operativi
                                        per la gestione e lo svolgimento della prova preselettiva del corso-concorso
                                        "COA 2021". I piani operativi sono adottati ai sensi del punto 9 del
                                        Protocollo per lo svolgimento dei concorsi pubblici del 25/05/2022

                                        <br />
                                        <br />
                                        <ul>
                                            <li><a href="#" @click.prevent="downloadAllegato('Milano')">Piano operativo della sede di Milano</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Rimini')">Piano operativo della sede di Rimini</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Roma')">Piano operativo della sede di Roma</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Napoli')">Piano operativo della sede di Napoli</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Foggia')">Piano operativo della sede di Foggia</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Sassari')">Piano operativo della sede di Sassari</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Sassari')">Piano operativo della sede di Catania</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Siracusa')">Piano operativo della sede di Siracusa</a></li>
                                            <li><a href="#" @click.prevent="downloadAllegato('Protocollo')">Protocollo per lo svolgimento dei concorsi pubblici </a></li>
                                        </ul>
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify; margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 01/07/2022</small> <br /><b>Oggetto: Banca Dati Quesiti prova preselettiva - COA 2021
                                        </b>
                                    </h4>
                                    <p>
                                        Si pubblica la banca dati dei quesiti che saranno utilizzati per elaborare i
                                        questionari per la prova preselettiva, di cui all’articolo 6 del bando di
                                        concorso denominato “COA 2021”. Tale pubblicazione, come previsto dal
                                        predetto articolo, è effettuata anche sul sito internet dell’Albo nazionale
                                        all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti. <br /><br />
                                        <b><u>
                                                La risposta esatta per ogni singolo quesito è sempre quella
                                                riportata alla lettera A</u></b>

                                        <br />
                                        <span style="text-align:left  margin-top:30px;">
                                            <a class="btn btn-info btn-sm" @click="downloadBancadati">Scarica PDF Banca dati</a>
                                        </span>
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify; margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 24/06/2022</small> <br /><b>Oggetto: Conferma diario prove preselettive - COA 2021
                                        </b>
                                    </h4>
                                    <p>
                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale
                                        “Concorsi ed esami” n. 50 del 24 giugno 2022, è pubblicato l’avviso di
                                        conferma del diario e delle modalità di svolgimento della prova
                                        preselettiva, pubblicati con l'avviso nella Gazzetta Ufficiale della
                                        Repubblica italiana - 4ª Serie speciale «Concorsi ed esami» - n. 38 del 13
                                        maggio 2022. Tale pubblicazione è effettuata anche sul sito internet
                                        dell’Albo nazionale all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti. <br /><br />
                                    </p>
                                    <span style="text-align:left">
                                        Link:
                                        <a target="_blank" href="https://www.gazzettaufficiale.it/atto/concorsi/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2022-06-24&atto.codiceRedazionale=22E08240">https://www.gazzettaufficiale.it/atto/concorsi/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2022-06-24&atto.codiceRedazionale=22E08240</a>
                                    </span>
                                </li>
                                <hr>
                                <li style="text-align:justify; margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 13/05/2022</small> <br /><b>Oggetto: Diario e modalità di svolgimento della prova preselettiva.
                                        </b>
                                    </h4>
                                    <p>
                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale
                                        “Concorsi ed esami” n. 38 del 13 maggio 2022 è pubblicato l’avviso della
                                        sede, del giorno e dell’ora di svolgimento della prova di preselezione,
                                        nonché delle informazioni circa le modalità di svolgimento della prova
                                        medesima, di cui all’articolo 6 del bando di concorso denominato “COA 2021”.
                                        Tale pubblicazione, come previsto dal predetto articolo, è effettuata anche
                                        sul sito internet dell’Albo nazionale all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti. <br /><br />
                                    </p>
                                    <span style="text-align:left">
                                        Link:
                                        <a target="_blank" href="https://www.gazzettaufficiale.it/atto/concorsi/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2022-05-13&atto.codiceRedazionale=22E06203">https://www.gazzettaufficiale.it/atto/concorsi/caricaDettaglioAtto/originario?atto.dataPubblicazioneGazzetta=2022-05-13&atto.codiceRedazionale=22E06203</a>
                                    </span>
                                </li>
                                <hr>
                                <li style="text-align:justify; margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 15/04/2022</small> <br /><b>Oggetto: Rinvio data prova di preselezione
                                        </b>
                                    </h4>
                                    <p>
                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale
                                        “Concorsi ed esami” n. 30 del 15 aprile 2022 è pubblicato l’avviso di rinvio
                                        alla Gazzetta Ufficiale, 4° serie speciale “Concorsi ed esami” del 13 maggio
                                        2022, della pubblicazione della sede, del giorno e dell’ora di svolgimento
                                        della prova di preselezione, nonché delle informazioni circa le modalità di
                                        svolgimento della prova medesima, di cui all’articolo 6 del bando di
                                        concorso denominato “COA 2021”. Tale pubblicazione, come previsto dal
                                        predetto articolo, è effettuata anche sul sito internet dell’Albo nazionale
                                        all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti.
                                    </p>
                                </li>
                                <hr>
                                <li style="text-align:justify; margin-top:30px;">
                                    <h4>
                                        <small>Comunicazione del 25/03/2022</small> <br /><b>Oggetto: Rinvio data prova di preselezione
                                        </b>
                                    </h4>
                                    <p>
                                        Nella Gazzetta Ufficiale della Repubblica Italiana, 4° serie speciale
                                        “Concorsi ed esami” n. 24 del 25 marzo 2022 è pubblicato l’avviso di rinvio
                                        alla Gazzetta Ufficiale, 4° serie speciale “Concorsi ed esami” del 15 aprile
                                        2022, della pubblicazione della sede, del giorno e dell’ora di svolgimento
                                        della prova di preselezione, nonché delle informazioni circa le modalità di
                                        svolgimento della prova medesima, di cui all’articolo 6 del bando di
                                        concorso denominato “COA 2021”. Tale pubblicazione, come previsto dal
                                        predetto articolo, è effettuata anche sul sito internet dell’Albo nazionale
                                        all’indirizzo
                                        <a target="_blank" href="https://albosegretari.interno.gov.it">https://albosegretari.interno.gov.it</a>, con valore di notifica a tutti gli effetti.
                                    </p>
                                </li>
                            </ul>
                        </card>
                    </b-tab>
                    <b-tab title="FAQ">
                        <card class="row">
                            <div class="m-3 p-3">
                                <h4>
                                    Per quesiti di <b>carattere tecnico-informatico</b> riguardanti la compilazione
                                    e la presentazione della domanda sono disponibili i seguenti contatti:
                                    <ul style="list-style-type: circle">
                                        <li>
                                            <b><i>coa2021@scanshare.it</i></b>
                                        </li>
                                        <li>
                                            0984/402764 (Da Lunedì al Venerdì i giorni dalle 9:30 – 13:30 / 14:30 –
                                            18.30)
                                        </li>
                                    </ul>
                                    <br /><i></i> Per quesiti di
                                    <b>carattere giuridico-amministrativo</b> riguardanti la compilazione e l’invio
                                    della domanda di ammissione al concorso è attiva la casella di posta elettronica
                                    <b><i>concorsocoa2021@interno.it. </i>Tale modalità di contatto ha carattere
                                        esclusivo.</b><br /><br />

                                    <ul style="list-style-type: circle">
                                        <li>
                                            <b>
                                                Per lo svolgimento delle prove scritte posso introdurre nella sala di concorso testi di
                                                legge e/o codici giuridici non commentati? </b><br />
                                            No. La Commissione esaminatrice, sulla base della tipologia di prove e di quanto disposto
                                            dall’art.7 del bando di concorso (che prevede esclusivamente dei divieti, tra cui quello di
                                            introdurre e usare nella sede d’esame codici commentati, e non contiene invece una
                                            previsione espressa di ammissione di testi di legge non commentati) - ha stabilito, tra
                                            l’altro, che ai candidati non verrà consentita l'introduzione di codici giuridici, anche non
                                            commentati e di testi normativi. <br />Quanto sopra, peraltro, coerentemente a quanto
                                            stabilito dall’art.13 del d.P.R. n. 487/94 (regolamento recante norme sull'accesso agli
                                            impieghi nelle pubbliche amministrazioni e le modalità di svolgimento dei concorsi, dei
                                            concorsi unici e delle altre forme di assunzione nei pubblici impieghi) che dispone: “(I
                                            candidati) Possono consultare soltanto i testi di legge non commentati ed autorizzati dalla
                                            commissione, se previsti dal bando di concorso….” (comma 3).<br />
                                            In ogni caso, la Commissione esaminatrice ha precisato che, qualora i quesiti formulati
                                            dovessero richiedere la consultazione di specifica documentazione, la stessa verrà messa a
                                            disposizione dei candidati a corredo del singolo quesito.
                                        </li>
                                        <li>
                                            <b>
                                                In quale modalità si svolgeranno le due prove scritte previste dall’ articolo 7 del
                                                bando di concorso?</b><br />
                                            Le due prove scritte si svolgeranno in presenza, secondo il diario pubblicato sulla Gazzetta
                                            Ufficiale 4° Serie Speciale - Concorsi ed Esami n. 82 del 14-10-2022, mediante
                                            strumentazione informatica e piattaforme digitali. Nello specifico, i candidati avranno a
                                            disposizione una postazione informatica dotata di tablet, supporto tablet da tavolo e
                                            tastiera.
                                        </li>
                                        <li>
                                            <b>
                                                Sono un candidato con cognome composto da due parole in cui è
                                                presente uno spazio (ad es. LO BIANCO – LI ROSI). Come faccio ad
                                                individuare la sessione di prova preselettiva di attribuzione,
                                                secondo il riparto stabilito nel diario pubblicato? </b><br />
                                            I candidati nel cui cognome è presente uno spazio (es. LO DUCA – LI
                                            VOLSI), per individuare la sessione di prova preselettiva loro
                                            assegnata, devono fare riferimento all’ordine alfabetico delle lettere
                                            che compongono la prima parola del cognome, tenendo conto dello spazio
                                            (ad esempio, LO DUCA precede LODDO e LI VOLSI precede LICCIARDO).
                                        </li>
                                        <li>
                                            <b> Alla prova preselettiva posso portare codici e/o testi di legge? </b><br />
                                            Durante la prova preselettiva i candidati non possono avvalersi de
                                            codici, raccolte normative, vocabolari, testi, appunti di qualsiasi
                                            natura e di telefoni cellulari ed altri dispositivi mobili idonei alla
                                            memorizzazione i alla trasmissione di dati i allo svolgimento di
                                            calcoli, né potranno comunicare tra loro. Articolo 6, comma 9 del Bando
                                            di concorso.
                                        </li>
                                        <li>
                                            <b> Come verifico se sono iscritto al concorso? </b><br />
                                            L’iscrizione al concorso è certificata dalla ricevuta che il sistema
                                            informatico ha rilasciato al termine della procedura di iscrizione, dopo
                                            l’invio della domanda.
                                        </li>
                                        <li>
                                            <b>Non trovo più la ricevuta della domanda di partecipazione che avevo
                                                stampato in sede di invio. Come faccio a stamparla di nuovo? </b><br />La ricevuta della domanda di partecipazione, che, ai sensi
                                            dell’articolo. 3, comma 5, del bando di concorso, dovrà essere
                                            consegnata il giorno stabilito per la prova preselettiva, può essere
                                            scaricata nuovamente dalla presente piattaforma, (Home - Area
                                            riservata), mediante l’utilizzo delle proprie credenziali del Sistema
                                            Pubblico di Identità Digitale (SPID).<br />
                                            <span style="text-decoration: underline;">Il giorno della prova preselettiva non sarà consentito presentare
                                                la ricevuta della domanda scaricata digitalmente sul proprio
                                                smartphone o altro dispositivo elettronico.</span>
                                        </li>
                                        <li>
                                            <b>
                                                Se la mia residenza o il mio domicilio fossero variati rispetto a
                                                quanto indicato in fase di invio della domanda, quali sono le
                                                modalità per comunicare il nuovo recapito ai fini dell’attribuzione
                                                della sede dove sarò chiamato/a a svolgere la prova preselettiva?</b><br />
                                            <span style="text-decoration: underline;">
                                                Ai fini dell’attribuzione della sede dove sarò chiamato/a a svolgere
                                                la prova preselettiva vale esclusivamente la residenza o il
                                                domicilio, se diverso dalla residenza, indicati nella domanda.</span>
                                            Eventuali successive variazioni di residenza o domicilio
                                            <b>non potranno</b> essere prese in considerazione. Articolo 6, comma 1
                                            del Bando.
                                        </li>
                                        <li>
                                            <b>
                                                Se i miei recapiti (indirizzo, mail ordinaria e/o pec) fossero
                                                variati rispetto a quanto indicato in fase di invio della domanda,
                                                quali sono le modalità per comunicare i nuovi? </b><br />
                                            Eventuali variazioni dei suddetti recapiti dovranno essere comunicati
                                            dal candidato, esclusivamente tramite la presente piattaforma (Area
                                            riservata), mediante l’utilizzo delle proprie credenziali del Sistema
                                            Pubblico di Identità Digitale (SPID). Articolo 3, comma 13 del Bando.
                                        </li>
                                        <li>
                                            <b>Posso presentare alle prove preselettive un documento di
                                                riconoscimento diverso da quello dichiarato in sede id invio della
                                                domanda di partecipazione? </b><br />
                                            Sì, purché sia un documento tra quelli previsti dall’articolo 35 del
                                            d.P.R. n. 445/2000 in corso di validità.
                                        </li>
                                        <li>
                                            <b>Ho un’invalidità certificata uguale o superiore all’80% e ho già
                                                caricato sulla piattaforma la documentazione prevista. Come faccio a
                                                sapere se sono stato esonerato dal partecipare alla prova
                                                preselettiva? </b><br />Non sarò tenuto a sostenere la prova preselettiva e accederò
                                            direttamente alle prove scritte, se avrò correttamente presentato nelle
                                            modalità e nei termini (temporali) previsti dal Bando, la documentazione
                                            comprovante la patologia e il grado di invalidità uguale o superiore
                                            all’80%. A tal fine il candidato riceverà, in tempo utile, comunicazione
                                            termite pec dell’Amministrazione.
                                        </li>
                                        <li>
                                            <b>
                                                Sono un candidato diversamente abile (o con diagnosi di disturbi
                                                specifici di apprendimento - DSA) e ho la necessità, per poter
                                                svolgere la prova preselettiva, di un particolare ausilio e/o di
                                                tempi aggiuntivi. Come faccio a sapere se questi mi saranno forniti? </b><br />La commissione di concorso, acquisita la documentazione,
                                            procederà al suo esame e, prima della prova preselettiva, comunicherà ai
                                            candidati interessati, tramite pec dell’Amministrazione, l’accoglimento,
                                            ovvero il rigetto, della richiesta del beneficio
                                        </li>

                                        <li>
                                            <b> Cosa devo fare per compilare la domanda on-line?</b><br />
                                            La domanda deve essere prodotta in via telematica (a pena di
                                            irricevibilità), utilizzando per l’identificazione la propria utenza
                                            SPID (Sistema Pubblico di Identità Digitale), accedendo all’apposita
                                            piattaforma digitale raggiungibile all’indirizzo internet
                                            <i>https://app.scanshareservice.it/albo-nazionale-segretari/coa2021.</i>
                                        </li>
                                        <li>
                                            <b> Cos’è SPID?</b><br />

                                            L’identità digitale SPID è rilasciata dai gestori di identità digitale
                                            (Identity Provider) accreditati presso l’ente preposto. <br />Per
                                            ottenere l’identità digitale SPID l’utente deve farne richiesta ad uno
                                            di tali gestori. Il gestore prescelto, dopo aver verificato l’identità
                                            del richiedente, emette l’identità digitale e rilascia le relative
                                            credenziali.<br />
                                            Le stesse sono utilizzabili per l’accesso a tutti i siti della Pubblica
                                            Amministrazione che hanno recepito l’accesso SPID.<br />
                                            Tutte le informazioni necessarie per richiedere le credenziali SPID sono
                                            disponibili sulla rete internet all’indirizzo
                                            <a target="_blank" href="https://www.spid.gov.it/richiedi-spid">https://www.spid.gov.it/richiedi-spid.</a>
                                        </li>

                                        <li>
                                            <b>
                                                Per la partecipazione al corso-concorso è necessario essere in
                                                possesso di un indirizzo PEC? </b><br />

                                            Sì, per la partecipazione al corso-concorso il candidato deve essere in
                                            possesso di un indirizzo di posta elettronica certificata (PEC) a lui
                                            intestato.
                                        </li>

                                        <li>
                                            <b>
                                                Ho il domicilio in un luogo diverso da quello di residenza. Posso
                                                indicarlo nella domanda di partecipazione? </b><br />

                                            Sì. Il luogo di domicilio, se diverso da quello di residenza, può essere
                                            indicato nell’apposito campo del modulo di domanda. Nella compilazione
                                            del campo relativo al luogo di residenza, nonché di quello
                                            dell’eventuale domicilio, <b>si raccomanda la massima attenzione</b>, in
                                            particolare nel selezionare, nell’apposito menù a tendina, la provincia
                                            interessata.
                                        </li>
                                        <li>
                                            <b>
                                                Cosa devo fare se mi accorgo di avere inoltrato una domanda in cui
                                                vi sono degli errori? </b><br />

                                            Si deve compilare e inoltrare una nuova domanda entro i termini. Ai fini
                                            della partecipazione al concorso, si terrà conto unicamente della
                                            domanda inviata cronologicamente per ultima entro i termini di
                                            presentazione.
                                        </li>
                                        <li>
                                            <b>
                                                Posso modificare le dichiarazioni rese nella domanda di
                                                partecipazione? </b><br />

                                            È possibile farlo fino al momento dell’invio della domanda. Dopo
                                            l’invio, invece, per modificare le dichiarazioni rese nella precedente
                                            domanda occorre procedere all’invio di una nuova domanda. Solo
                                            quest’ultima sarà quella presa in considerazione.
                                        </li>
                                        <li>
                                            <b>
                                                Cosa succede se invio più volte il format on-line della stessa
                                                domanda?</b><br />

                                            In questo caso, si tiene conto unicamente dell’ultima domanda inviata
                                            nei termini.
                                        </li>
                                        <li>
                                            <b>
                                                A che data devono essere posseduti i requisiti di ammissione al
                                                concorso?</b><br />

                                            I requisiti devono essere posseduti alla data di scadenza del termine
                                            utile per la presentazione delle domande.
                                        </li>
                                        <li>
                                            <b>
                                                Posso partecipare al concorso se sono in possesso della sola laurea
                                                triennale?</b><br />
                                            No.
                                        </li>
                                        <li>
                                            <b>
                                                Posso partecipare al concorso se ho conseguito una laurea diversa da
                                                quelle indicate nel bando e prevista nel modulo di domanda di
                                                partecipazione?</b><br />

                                            Non è possibile partecipare al concorso se non si è in possesso dei
                                            titoli di laurea indicati nel bando ovvero ad essi equipollenti o
                                            equiparati.<br />
                                            Tuttavia, qualora si sia in possesso di eventuale titolo equipollente si
                                            potrà procedere all’indicazione dello stesso nell’apposito menu a
                                            tendina selezionando “laurea equipollente conseguita in Italia” e,
                                            quindi, a compilare il successivo campo rubricato “provvedimento di
                                            equipollenza/equivalenza”, indicando il titolo di studio conseguito e
                                            gli estremi del provvedimento di equipollenza.
                                        </li>
                                        <li>
                                            <b>
                                                Sono in possesso di un titolo di studio previsto dal bando ma
                                                conseguito all'estero, è valido per partecipare al
                                                corso-concorso?</b><br />

                                            Per le modalità di riconoscimento dei titoli conseguiti all’estero si
                                            rinvia alle esaurienti informazioni riportate nel sito istituzionale del
                                            MIUR al seguente link:
                                            <a target="_blank" href="https://www.miur.gov.it/titoli-accademici-esteri">https://www.miur.gov.it/titoli-accademici-esteri.</a>
                                        </li>
                                        <li>
                                            <b>
                                                Ho un titolo di studio conseguito all’estero: cosa devo indicare
                                                nella domanda di partecipazione?</b><br />

                                            Se il titolo di studio è stato conseguito all’estero, il candidato deve
                                            selezionare “laurea equipollente conseguita all’estero” oppure “laurea
                                            equivalente conseguita all’estero” e quindi indicare nel successivo
                                            campo rubricato “provvedimento di equipollenza/equivalenza” la laurea
                                            conseguita e gli estremi del provvedimento con il quale il titolo stesso
                                            è stato riconosciuto equipollente o equivalente al corrispondente titolo
                                            italiano. Qualora il candidato non sia ancora in possesso della
                                            dichiarazione di equipollenza/equivalenza dovrà indicare nella domanda
                                            la data di presentazione della richiesta alla competente autorità.
                                        </li>
                                        <li>
                                            <b>
                                                Chi può avvalersi della riserva di cui all’art. 1, comma 2, del
                                                bando?</b><br />

                                            Possono avvalersi della riserva i dipendenti delle amministrazioni
                                            pubbliche di cui all’articolo 1, comma 2, del decreto legislativo 30
                                            marzo 2001, n. 165, in possesso di uno dei titoli di studio previsti per
                                            l’accesso alla carriera di segretario comunale e che abbiano almeno
                                            cinque anni di anzianità di effettivo servizio in posizioni funzionali
                                            per accedere alle quali è richiesto il possesso dei medesimi titoli di
                                            studio.
                                        </li>
                                        <li>
                                            <b>
                                                Ho un titolo di studio conseguito all’estero: cosa devo indicare
                                                nella domanda di partecipazione?</b><br />

                                            Se il titolo di studio è stato conseguito all’estero, il candidato deve
                                            selezionare “laurea equipollente conseguita all’estero” oppure “laurea
                                            equivalente conseguita all’estero” e quindi indicare nel successivo
                                            campo rubricato “provvedimento di equipollenza/equivalenza” la laurea
                                            conseguita e gli estremi del provvedimento con il quale il titolo stesso
                                            è stato riconosciuto equipollente o equivalente al corrispondente titolo
                                            italiano. Qualora il candidato non sia ancora in possesso della
                                            dichiarazione di equipollenza/equivalenza dovrà indicare nella domanda
                                            la data di presentazione della richiesta alla competente autorità.
                                        </li>
                                        <li>
                                            <b>
                                                Chi può avvalersi della riserva di cui all’art. 1, comma 2, del
                                                bando?</b><br />

                                            Possono avvalersi della riserva i dipendenti delle amministrazioni
                                            pubbliche di cui all’articolo 1, comma 2, del decreto legislativo 30
                                            marzo 2001, n. 165, in possesso di uno dei titoli di studio previsti per
                                            l’accesso alla carriera di segretario comunale e che abbiano almeno
                                            cinque anni di anzianità di effettivo servizio in posizioni funzionali
                                            per accedere alle quali è richiesto il possesso dei medesimi titoli di
                                            studio.
                                        </li>
                                        <li>
                                            <b>
                                                È possibile partecipare al corso-concorso se la laurea verrà
                                                conseguita successivamente alla data di pubblicazione del bando?</b><br />

                                            Sì, purché conseguita entro la data di scadenza del termine utile per la
                                            presentazione delle domande.
                                        </li>
                                        <li>
                                            <b>
                                                Sono portatore di handicap (o con diagnosi di disturbi specifici di
                                                apprendimento - DSA) e ho necessità di ausili e/o di tempi
                                                aggiuntivi per lo svolgimento delle prove concorsuali, a chi devo
                                                fare la richiesta?</b><br />

                                            Innanzitutto, nell’apposito spazio della domanda on-line, si dovrà fare
                                            esplicita richiesta dell’ausilio necessario e/o di tempi aggiuntivi
                                            necessari per l’espletamento delle prove di esame in relazione alla
                                            disabilità (o al disturbo specifico). Entro venti giorni dalla scadenza
                                            del termine utile di presentazione della domanda di ammissione, il
                                            candidato dovrà caricare in formato elettronico – esclusivamente
                                            nell’area riservata della piattaforma – tutta la documentazione di
                                            supporto alla dichiarazione resa sulla disabilità (o disturbo
                                            specifico). A tal fine saranno accettati file in formato pdf.<br />
                                            L’assegnazione di ausili e/o tempi aggiuntivi è comunque concessa a
                                            insindacabile giudizio della Commissione esaminatrice sulla scorta della
                                            documentazione esibita e sull’esame obiettivo di ogni specifico caso.
                                        </li>
                                        <li>
                                            <b>
                                                Ho diritto ad essere esonerato dal sostenere la prova preselettiva
                                                ai sensi dell’art. 20, comma 2-bis, della legge 104/1992, cosa devo
                                                fare? </b><br />

                                            In primo luogo, occorre dichiarare nella domanda on-line di volersi
                                            avvalere del beneficio.<br />
                                            Entro venti giorni dalla scadenza del termine utile di presentazione
                                            della domanda di ammissione, il candidato dovrà caricare in formato
                                            elettronico – esclusivamente nell’area riservata della piattaforma – la
                                            documentazione comprovante la patologia da cui è affetto e il grado di
                                            invalidità, nonché l’esplicita autorizzazione al trattamento dei dati
                                            sensibili. A tal fine saranno accettati file in formato pdf.
                                        </li>
                                        <li>
                                            <b>
                                                Cosa si intende per “figli a carico” quale titolo di preferenza a
                                                parità di punteggio? </b><br />

                                            Si considerano fiscalmente a carico i figli (compresi i figli adottivi,
                                            affidati o affiliati) – indipendentemente dal superamento di determinati
                                            limiti di età e dal fatto che siano o meno dediti agli studi o al
                                            tirocinio gratuito – che rientrano nel nucleo familiare e nell’anno
                                            precedente hanno posseduto un reddito complessivo, al lordo degli oneri
                                            deducibili, uguale o inferiore a € 2.840,51.<br />
                                            A partire da 1° gennaio 2018, in forza di quanto stabilito dalla Legge
                                            di bilancio 2018, per i figli di età inferiore a 24 anni il suddetto
                                            limite è stato innalzato a € 4.000,00, rimanendo invariato il limite di
                                            € 2.840,51 per i figli di età superiore. <br />Il reddito al quale si fa
                                            riferimento è quello del figlio e non del genitore.<br />
                                            Il figlio è da considerarsi a carico ai fini del possesso del relativo
                                            titolo di preferenza a prescindere dalla circostanza che, dal punto di
                                            vista fiscale, lo stesso sia a carico di uno dei due genitori.
                                        </li>
                                        <li>
                                            <b>
                                                Ho diritto ad essere esonerato dal sostenere la prova preselettiva
                                                ai sensi dell’art. 20, comma 2-bis, della legge 104/1992, cosa devo
                                                fare? </b><br />

                                            In primo luogo, occorre dichiarare nella domanda on-line di volersi
                                            avvalere del beneficio. Entro venti giorni dalla scadenza del termine
                                            utile di presentazione della domanda di ammissione, il candidato dovrà
                                            caricare in formato elettronico – esclusivamente nell’area riservata
                                            della piattaforma – la documentazione comprovante la patologia da cui è
                                            affetto e il grado di invalidità, nonché l’esplicita autorizzazione al
                                            trattamento dei dati sensibili. A tal fine saranno accettati file in
                                            formato pdf.
                                        </li>
                                        <li>
                                            <b>
                                                È possibile presentare la domanda on-line prima di avere effettuato
                                                il bonifico di € 10,00 per i diritti di segreteria?</b><br />

                                            No. Nella domanda occorre indicare obbligatoriamente gli elementi
                                            identificativi del bonifico che, pertanto, deve essere effettuato prima.
                                        </li>
                                        <li>
                                            <b>
                                                Come posso versare i 10,00 € relativi ai diritti di segreteria se la
                                                mia banca non accetta il versamento on-line di somme verso la
                                                Tesoreria dello Stato?</b><br />

                                            È possibile effettuare il versamento presentandosi fisicamente presso
                                            gli sportelli dell’istituto di credito, ovvero presso un qualsiasi
                                            ufficio postale. <br />Si raccomanda comunque, una volta effettuato il
                                            pagamento on-line, di verificare che questo sia andato a buon fine.
                                        </li>
                                        <li>
                                            <b>
                                                Nel campo riservato alla causale del versamento del bonifico non c’è
                                                spazio sufficiente per tutti i caratteri che dovrei inserire in base
                                                a quanto richiesto dall’art. 3, comma 15, del bando. Cosa devo
                                                fare?</b><br />

                                            In tal caso è possibile indicare esclusivamente il proprio codice
                                            fiscale seguito da “COA 2021”.
                                        </li>
                                        <li>
                                            <b>
                                                Cosa succede alla scadenza del termine per l’invio on-line della
                                                domanda?</b><br />

                                            Si potrà accedere alla piattaforma esclusivamente per visualizzare e/o
                                            stampare la domanda già inoltrata.
                                        </li>
                                        <li>
                                            <b> Da quando è possibile inviare la domanda?</b><br />

                                            Dalle ore 00:00:01 del 10 novembre 2021, giorno successivo alla
                                            pubblicazione del bando.
                                        </li>
                                        <li>
                                            <b> Fino a quando è possibile inviare la domanda?</b><br />

                                            Fino alle ore 23:59:59 del 9 dicembre 2021.
                                        </li>
                                        <li>
                                            <b> Devo conservare la stampa della domanda?</b><br />

                                            Sì. Ai sensi dell’art. 3, comma 5, del bando di concorso,
                                            <i>“Dopo aver presentato la domanda, il candidato effettuerà la stampa
                                                della ricevuta di avvenuta iscrizione al concorso che dovrà essere
                                                consegnata il giorno stabilito per la prova preselettiva”.</i>
                                        </li>
                                        <li>
                                            <b>
                                                Cosa succede se invio una domanda cartacea ma non invio la domanda
                                                on-line dalla procedura web?</b><br />

                                            La domanda non sarà presa in considerazione. L’iscrizione al
                                            corso-concorso si effettua esclusivamente mediante la procedura on-line.
                                        </li>
                                        <li>
                                            <b>
                                                Vorrei chiarimenti in merito alla dichiarazione di “lodevole
                                                servizio”.</b><br />

                                            La dichiarazione di lodevole servizio prevista è un titolo di preferenza
                                            e consiste in una dichiarazione che definisce lodevole la qualità del
                                            lavoro svolto dal dipendente pubblico. Ai fini del corso-concorso la
                                            dichiarazione può essere rilasciata dalla pubblica amministrazione
                                            presso la quale il candidato ha svolto o sta svolgendo il proprio
                                            servizio.
                                        </li>
                                        <li>
                                            <b>
                                                La ricevuta di bonifico rilasciata dalla mia banca riporta, anziché
                                                il codice CRO, solo il codice TRN. È possibile indicare tale codice
                                                nel form di compilazione della domanda di partecipazione al
                                                concorso?</b><br />
                                            Sì, è possibile indicare il codice TRN, o comunque altro codice che
                                            identifichi in maniera univoca il bonifico.
                                        </li>
                                        <li>
                                            <b>
                                                Posso effettuare il versamento della quota prevista dal bando anche
                                                da un conto non intestato a me? </b><br />
                                            Si, indicando comunque nella causale il cognome nome/codice
                                            fiscale/COA2021 del candidato.
                                        </li>
                                        <li>
                                            <b>
                                                Il mio titolo di studio può ritenersi equipollente o equiparato a
                                                quelli richiesti dal bando? </b><br />
                                            Se il titolo di studio in possesso del candidato non è quello
                                            espressamente previsto dal bando di concorso, è onere del candidato
                                            verificarne l’equipollenza e/o l’equiparazione in base alla normativa di
                                            riferimento reperibile sul sito del Ministero dell’istruzione
                                            dell’università e della ricerca.
                                        </li>
                                        <li>
                                            <b>
                                                Sono in possesso di una laurea conseguita in vigenza del vecchio
                                                ordinamento. Quale voce devo selezionare nel menu a tendina? </b><br />
                                            Le lauree del vecchio ordinamento che danno titolo a partecipare al
                                            corso-concorso sono quelle in Giurisprudenza, Economia e Commercio e
                                            Scienze Politiche, presenti quali prime tre voci nel menu a tendina.
                                            Nell’ipotesi di un titolo di studio diverso da questi, conseguito con il
                                            vecchio ordinamento ed equipollente alle suddette tre lauree, è
                                            necessario selezionare nel menu a tendina la voce “Laurea equipollente
                                            conseguita in Italia”, oppure “Laurea equipollente conseguita
                                            all’estero”.
                                        </li>
                                        <li>
                                            <b>
                                                Sono un dipendente pubblico di una pubblica amministrazione e ho
                                                maturato i 5 anni di servizio richiesti dal bando ai fini della
                                                riserva dei posti in categoria C comparto Funzioni Locali, come
                                                istruttore amministrativo. Ho i requisiti per avere diritto alla
                                                riserva? </b><br />
                                            No. Il bando prevede che il trenta per cento dei posti sia riservato ai
                                            dipendenti delle amministrazioni pubbliche di cui all’articolo 1, comma
                                            2, del decreto legislativo 30 marzo 2001, n. 165, in possesso dei titoli
                                            di studio previsti per l’accesso alla carriera dei Segretari comunali e
                                            provinciali e
                                            <u>
                                                con un’anzianità di servizio di almeno cinque anni in posizioni
                                                funzionali per l’accesso alle quali è previsto il possesso dei
                                                medesimi titoli di studio.</u>
                                        </li>
                                        <li>
                                            <b>
                                                Cosa si intende, ai fini della riserva prevista dal bando di
                                                concorso, per dipendente di pubbliche Amministrazioni di cui
                                                all'articolo 1, comma 2, del decreto legislativo 30 marzo 2001,
                                                n.165? </b><br />
                                            Si intendono i dipendenti di ruolo, con contratto a tempo indeterminato,
                                            di tutte le Amministrazioni dello stato, ivi compresi gli istituti e
                                            scuole di ogni ordine e grado e le istituzioni educative, le aziende ed
                                            Amministrazioni dello Stato ad ordinamento autonomo, le Regioni, le
                                            Province, i Comuni, le Comunità Montane, e loro consorzi e associazioni,
                                            le istituzioni universitarie, gli Istituti autonomi case popolari, le
                                            Camere di commercio, industria, artigianato e agricoltura e loro
                                            associazioni, tutti gli enti pubblici non economici nazionali, regionali
                                            e locali, le Amministrazioni, le aziende e gli enti del Servizio
                                            sanitario nazionale, l'Agenzia per la rappresentanza negoziale delle
                                            Pubbliche Amministrazioni (ARAN) e le Agenzie di cui al decreto
                                            legislativo 30 luglio 1999, n. 300 nonché, fino alla revisione organica
                                            della disciplina di settore, il CONI.
                                        </li>

                                        <li>
                                            <b>
                                                Ai fini della riserva di posti prevista dal bando di concorso, il
                                                requisito dell'anzianità di servizio effettivo di almeno cinque anni
                                                può essere soddisfatto cumulando contratti a tempo determinato anche
                                                non continuativi? </b><br />
                                            Sì, è possibile, purché alla data di scadenza del termine per la
                                            presentazione della domanda il candidato risulti dipendente di una delle
                                            amministrazioni pubbliche di cui all’articolo 1, comma 2, del decreto
                                            legislativo 30 marzo 2001, n. 165, in possesso dei titoli di studio
                                            previsti per l’accesso alla carriera dei Segretari comunali e
                                            provinciali e con un’anzianità di servizio di almeno cinque anni in
                                            posizioni funzionali per l’accesso alle quali è previsto il possesso dei
                                            medesimi titoli di studio”.
                                        </li>

                                    </ul>
                                </h4>
                            </div>
                        </card>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <div v-else>
            <b>Questo servizio non è al momento attivo.</b>
        </div>
    </card>
    <card v-else-if="app.code == 'coa6'">
        <div v-if="app.public || user.type == 'admin'">
            <b>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
                {{ app.sessione.toUpperCase() }}</b>
            <h4 class="card-title">{{ app.title }}</h4>

            <div v-if="app.access">
                <div v-if="!isLoggedIn">
                    <p class="card-text"><b>Per partecipare è necessario accedere</b></p>
                    <base-button @click="signin" class="btn btn-primary">Accedi</base-button>
                </div>
                <div v-else>
                    <card>
                        <b-tabs content-class="mt-3">
                            <b-tab title="Info del Corso" active>
                                <div>
                                    <h4>
                                        <strong>Si ricorda che è assolutamente vietato effettuare registrazioni,
                                            diffondere e/o visualizzare pubblicamente le videolezioni ed i
                                            seminari.</strong><br />
                                    </h4>
                                    <h4>Lasciare aperta questa scheda durante tutta la durata del corso.<br /></h4>
                                </div>
                                <h4>Procedura di identificazione e accesso all'aula virtuale.</h4>
                                <li>
                                    <b>
                                        Premendo il pulsante sottostante si accederà alla stanza di identificazione
                                        e registrazione. Attendi il tuo turno per essere identificato
                                    </b>
                                </li>

                                <li>
                                    <b>Una volta identificato l'assistente di aula ti invierà tramite chat di Zoom
                                        il link di accesso alla stanza del corso</b>
                                </li>

                                <li>
                                    <b>Attendi nella sala d'attesa della stanza del corso che l'organizzatore ti
                                        faccia entrare</b>
                                </li>
                                <li>
                                    <b>Il tuo microfono per motivi organizzativi sarà disattivato, nel caso in cui
                                        si intenda fare domande e/o interventi potrai segnalarlo tramite chat ad un
                                        assistente di aula</b>
                                </li>

                                <li>
                                    <b>In caso di necessità di allontamento dalla postazione dovrai avvisare
                                        tramite chat un assistente di aula</b>
                                </li>

                                <li>
                                    <b>Al termine della lezione non lasciare l'aula prima di rispondere ad un
                                        breve questionario di valutazione della lezione</b>
                                </li>
                                <br />
                                <div>
                                    <h5>
                                        Per assistenza puoi usare la chat di zoom per comunicare con un assistente
                                        di aula. In alternativa scrivi a:
                                        <b>coa6-sessione-ordinaria@scanshare.it</b>
                                    </h5>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <a :href="app.linkZoom" target="_blank" :class="{

btn: true,

'btn-primary': true,

disabled: lessonToday == false ? true : false

}">Entra nella stanza di identificazione e registrazione</a>
                                    </div>
                                </div>
                                {{ lessonToday == false ? 'Il corso non è attivo' : 'Corso attivo' }}
                            </b-tab>
                            <b-tab title="Registro">
                                <div>
                                    <h5 v-if="!this.app.lastModule">
                                        <strong>
                                            Gli attestati dell'ultimo modulo non sono al momento
                                            disponibili.</strong>
                                    </h5>
                                    <h5 v-else>
                                        <strong>
                                            Sarà possibile scaricare l'attestato di partecipazione dal giorno
                                            successivo a quello cui l'attestato fa riferimento</strong>
                                    </h5>

                                    <base-input class="mb-4 col-md-6" label="Seleziona il giorno di corso per scaricare il relativo attestato di partecipazione">
                                        <select v-model="selectedDay" class="form-control" id="exampleFormControlSelect1">
                                            <option>Seleziona giorno</option>
                                            <option v-for="presenza in presenze" v-bind:key="presenza.data">{{

presenza.data

}}</option>
                                        </select>
                                    </base-input>

                                    <base-button :class="{

'col-md-4': true,

'mt-4': true,

btn: true,

'btn-secondary': true,

disabled: selectedValidDay

}" @click="scarica">Scarica attestato</base-button>
                                </div>

                                <div class="row">
                                    <p class="col-md-3" style="wi">
                                        ORE TOTALI DI CORSO: <b>{{ oreTotaliCorso }}</b>
                                    </p>
                                    <p class="col-md-3">
                                        ORE TOTALI DI PRESENZA:<b>{{ orePresenzaTotali }}</b>
                                    </p>
                                    <p class="col-md-3">
                                        ORE TOTALI DI ASSENZA:<b>{{ oreAssenzaTotali }}</b>
                                    </p>
                                </div>

                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Modulo</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Durata</th>
                                            <th scope="col">Presenza</th>
                                            <th scope="col">Assenza</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(lezione, index) in presenze" :key="index">
                                            <th scope="row">{{ lezione.modulo }}</th>
                                            <td>{{ lezione.data }}</td>
                                            <td>0{{ lezione.duration }}:00</td>
                                            <td>{{ presenzaTable(lezione.data) }}</td>
                                            <td>{{ assenzaTable(lezione.data) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                            </b-tab>
                        </b-tabs>
                    </card>
                </div>
            </div>
            <div v-else>
                <b> Al momento non è possibile accedere</b>
            </div>
        </div>
        <div v-else>
            <b>Questo servizio non è al momento attivo.</b>
        </div>
    </card>
    <card v-else-if="app.code == 'coa6-sa'">
        <div v-if="app.public || user.type == 'admin'">
            <b>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
                {{ app.sessione.toUpperCase() }}</b>
            <h4 class="card-title">{{ app.title }}</h4>

            <div v-if="app.access">
                <div v-if="!isLoggedIn">
                    <p class="card-text"><b>Per partecipare è necessario accedere</b></p>
                    <base-button @click="signin" class="btn btn-primary">Accedi</base-button>
                </div>
                <div v-else>
                    <card>
                        <b-tabs content-class="mt-3">
                            <b-tab title="Info del Corso" active>
                                <div>
                                    <h4>
                                        <strong>Si ricorda che è assolutamente vietato effettuare registrazioni,
                                            diffondere e/o visualizzare pubblicamente le videolezioni ed i
                                            seminari.</strong><br />
                                    </h4>
                                    <h4>Lasciare aperta questa scheda durante tutta la durata del corso.<br /></h4>
                                </div>
                                <h4>Procedura di identificazione e accesso all'aula virtuale.</h4>
                                <li>
                                    <b>
                                        Premendo il pulsante sottostante si accederà alla stanza di identificazione
                                        e registrazione. Attendi il tuo turno per essere identificato
                                    </b>
                                </li>

                                <li>
                                    <b>Una volta identificato l'assistente di aula ti invierà tramite chat di Zoom
                                        il link di accesso alla stanza del corso</b>
                                </li>

                                <li>
                                    <b>Attendi nella sala d'attesa della stanza del corso che l'organizzatore ti
                                        faccia entrare</b>
                                </li>
                                <li>
                                    <b>Il tuo microfono per motivi organizzativi sarà disattivato, nel caso in cui
                                        si intenda fare domande e/o interventi potrai segnalarlo tramite chat ad un
                                        assistente di aula</b>
                                </li>

                                <li>
                                    <b>In caso di necessità di allontamento dalla postazione dovrai avvisare
                                        tramite chat un assistente di aula</b>
                                </li>

                                <li>
                                    <b>Al termine della lezione non lasciare l'aula prima di rispondere ad un
                                        breve questionario di valutazione della lezione</b>
                                </li>
                                <br />
                                <div>
                                    <h5>
                                        Per assistenza puoi usare la chat di zoom per comunicare con un assistente
                                        di aula. In alternativa scrivi a:
                                        <b>coa6-sessione-ordinaria@scanshare.it</b>
                                    </h5>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <a :href="app.linkZoom" target="_blank" :class="{

                        btn: true,

                        'btn-primary': true,

                        disabled: lessonToday == false ? true : false

                        }">Entra nella stanza di identificazione e registrazione</a>
                                    </div>
                                </div>
                                {{ lessonToday == false ? 'Il corso non è attivo' : 'Corso attivo' }}
                            </b-tab>
                            <b-tab title="Registro">
                                <div>
                                    <h5 v-if="!this.app.lastModule">
                                        <strong>
                                            Gli attestati dell'ultimo modulo non sono al momento
                                            disponibili.</strong>
                                    </h5>
                                    <h5 v-else>
                                        <strong>
                                            Sarà possibile scaricare l'attestato di partecipazione dal giorno
                                            successivo a quello cui l'attestato fa riferimento</strong>
                                    </h5>

                                    <base-input class="mb-4 col-md-6" label="Seleziona il giorno di corso per scaricare il relativo attestato di partecipazione">
                                        <select v-model="selectedDay" class="form-control" id="exampleFormControlSelect1">
                                            <option>Seleziona giorno</option>
                                            <option v-for="presenza in presenze" v-bind:key="presenza.data">{{

presenza.data

}}</option>
                                        </select>
                                    </base-input>

                                    <base-button :class="{

'col-md-4': true,

'mt-4': true,

btn: true,

'btn-secondary': true,

disabled: selectedValidDay

}" @click="scarica">Scarica attestato</base-button>
                                </div>

                                <div class="row">
                                    <p class="col-md-3" style="wi">
                                        ORE TOTALI DI CORSO: <b>{{ oreTotaliCorso }}</b>
                                    </p>
                                    <p class="col-md-3">
                                        ORE TOTALI DI PRESENZA:<b>{{ orePresenzaTotali }}</b>
                                    </p>
                                    <p class="col-md-3">
                                        ORE TOTALI DI ASSENZA:<b>{{ oreAssenzaTotali }}</b>
                                    </p>
                                </div>

                                <table class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Modulo</th>
                                            <th scope="col">Data</th>
                                            <th scope="col">Durata</th>
                                            <th scope="col">Presenza</th>
                                            <th scope="col">Assenza</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(lezione, index) in presenze" :key="index">
                                            <th scope="row">{{ lezione.modulo }}</th>
                                            <td>{{ lezione.data }}</td>
                                            <td>0{{ lezione.duration }}:00</td>
                                            <td>{{ presenzaTable(lezione.data) }}</td>
                                            <td>{{ assenzaTable(lezione.data) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                            </b-tab>
                        </b-tabs>
                    </card>
                </div>
            </div>
            <div v-else>
                <b> Al momento non è possibile accedere</b>
            </div>
        </div>
        <div v-else>
            <b>Questo servizio non è al momento attivo.</b>
        </div>
    </card>
</div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import BaseButton from '../components/BaseButton.vue';
import logo from './../assets/img/albo-nazionale-segretari.svg';
import logoAttestato from './../assets/img/intestazione-a4.png';
import jsPDF from 'jspdf';
import { BaseAlert } from '@/components';
import FileUpload from '@/components/FileUpload.vue';

Date.prototype.addDays = function (days) {
	let date = new Date(this.valueOf());
	date.setDate(date.getDate() + days);
	return date;
};

export default {
	components: {
		BaseAlert,
		FormWizard,
		TabContent,
		BaseButton,
		FileUpload,
	},

	data() {
		return {
			preview: false,
			expand: false,
			formOptions: {
				validationErrorClass: 'has-danger',
				validationSuccessClass: 'has-success',
				validateAfterChanged: true,
			},
			activeTab: 0,
			pageTabs: ['comunicazioni', 'faq', 'area personale'],
			blockUpdateDataForm: true,
			updateDataForm: {
				pec: '',
				repeatPec: '',
				statoResidenza: '',
				provinciaResidenza: '',
				comuneResidenza: '',
				residenza: '',
				statoDomicilio: '',
				provinciaDomicilio: '',
				comuneDomicilio: '',
				domicilio: '',
				province: [],
				states: [],
			},
			logo,
			logoAttestato,
			entered: false,
			selectedDay: 'Seleziona giorno',
			file: '',
		};
	},
	methods: {
		formatDate(date) {
			let day = date.getDate();
			let month = date.getMonth();
			let year = date.getFullYear();
			return day + '/' + month + '/' + year;
		},

		titoliDipreferenzaCaricabili() {
			let today = new Date();
			let validDate = new Date('09-05-2023');
			validDate.setHours(0, 0, 0, 0);
			today.setHours(0, 0, 0, 0);
			return today >= validDate;
		},
		tab(name) {
			const idTab = this.pageTabs.findIndex((el) => el == name);
			this.activeTab = idTab;
		},
		toggleTab(value) {
			switch (value) {
				case 'area_personale':
					this.activeTab = 0;
					break;
				case 'comunicazioni':
					this.activeTab = 1;
					break;
				case 'faq':
					this.activeTab = 2;
					break;
				default:
					this.activeTab = 0;
			}
		},
		downloadBancadati: async function () {
			await this.$store.dispatch('downloadBancadati');
		},
		downloadRicevuta: async function () {
			await this.$store.dispatch('downloadRicevuta');
		},
		downloadRicevutaAccettazione: async function () {
			await this.$store.dispatch('downloadRicevutaAccettazione');
		},
		downloadAllegato: async function (allegato) {
			await this.$store.dispatch('downloadAllegato', allegato);
		},

		downloadAllegatoComunicazione: async function (allegato) {
			await this.$store.dispatch('downloadAllegatoComunicazione', allegato);
		},
		toggleUpdateDataForm() {
			if (this.blockUpdateDataForm) {
				let updateDataForm = document.getElementsByClassName('updateDataForm');
				updateDataForm.forEach((form, index) => {
					updateDataForm[index].removeAttribute('style');
				});
			} else {
				let updateDataForm = document.getElementsByClassName('updateDataForm');
				updateDataForm.forEach((form, index) => {
					updateDataForm[index].style.pointerEvents = 'none';
					updateDataForm[index].style.backgroundColor = '#E3E3E3';
				});
			}
			this.blockUpdateDataForm = !this.blockUpdateDataForm;
		},
		updateData() {
			let form = this.updateDataForm;

			let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if (form.pec != form.repeatPec) {
				alert('Ripetere pec correttamente');
				return;
			}
			if (form.email != form.repeatEmail) {
				alert('Ripetere email correttamente');
				return;
			}
			if (!form.pec.match(regex)) {
				alert('Pec non valida');
				return;
			}
			if (!form.email.match(regex)) {
				alert('Email non valida');
				return;
			}
			if (form.statoResidenza.length < 2 || form.statoDomicilio.length < 2) {
				alert('Compilare stato correttamente');
				return;
			}
			if (form.residenza.length < 7 || form.domicilio.length < 7) {
				alert('Compilare indirizzo correttamente');
				return;
			}
			if (form.provinciaResidenza.length < 2 || form.provinciaDomicilio.length < 2) {
				alert('Compilare provincia correttamente');
				return;
			}

			if (form.comuneResidenza.length < 2 || form.comuneDomicilio.length < 2) {
				alert('Compilare comune correttamente');
				return;
			}

			this.$store.dispatch('updateDataForm', form);
		},
		toggleForm(app) {
			if (app.code == 'coa2021') {
				let listaElettorale = app.form.fields.find((element) => element.model == 'listaElettorale');
				let listaElettoraleClasses = listaElettorale.styleClasses;

				let ragioniListaElettorale = app.form.fields.find(
					(element) => element.model == 'ragioniListaElettorale'
				);
				let ragioniListaElettoraleClasses = listaElettorale.styleClasses;

				let condannePenali = app.form.fields.find((element) => element.model == 'condannePenali');
				let condannePenaliClasses = condannePenali.styleClasses;

				let provvedimento = app.form.fields.find((element) => element.model == 'provvedimento');
				let provvedimentoClasses = provvedimento.styleClasses;

				let nFigli = app.form.fields.find((element) => element.model == 'nFigli');
				let nFigliClasses = nFigli.styleClasses;

				let indirizzoDomicilio = app.form.fields.find((element) => element.model == 'indirizzoDomicilio');
				let comuneDomicilio = app.form.fields.find((element) => element.model == 'comuneDomicilio');
				let provinciaDomicilio = app.form.fields.find((element) => element.model == 'provinciaDomicilio');
				let indirizzoDomicilioClasses = indirizzoDomicilio.styleClasses;
				let provinciaDomicilioClasses = comuneDomicilio.styleClasses;
				let comuneDomicilioClasses = provinciaDomicilio.styleClasses;

				let enteRiserva = app.form.fields.find((element) => element.model == 'enteRiserva');
				let posizioneRiserva = app.form.fields.find((element) => element.model == 'posizioneRiserva');
				let anniRiserva = app.form.fields.find((element) => element.model == 'anniRiserva');
				let enteRiservaClasses = enteRiserva.styleClasses;
				let posizioneRiservaClasses = posizioneRiserva.styleClasses;
				let anniRiservaClasses = anniRiserva.styleClasses;
				let ausilioTa = app.form.fields.find((element) => element.model == 'ausilioTa');
				let ausilioTaClasses = ausilioTa.styleClasses;
				let statoDomicilio = app.form.fields.find((element) => element.model == 'statoDomicilio');
				let statoDomicilioClasses = statoDomicilio.styleClasses;
				//TOGGLE
				if (
					app.model.iscrizioneListeElettorali == 'Non dichiaro' &&
					!listaElettorale.styleClasses.includes('hidden')
				) {
					listaElettorale.styleClasses += ' hidden';
					ragioniListaElettorale.styleClasses = ragioniListaElettoraleClasses.replace('hidden', '');
				}
				if (
					app.model.iscrizioneListeElettorali == 'Dichiaro' &&
					listaElettorale.styleClasses.includes('hidden')
				) {
					listaElettorale.styleClasses = listaElettoraleClasses.replace('hidden', '');
					ragioniListaElettorale.styleClasses += ' hidden';
				}

				if (app.model.checkCondannePenali == 'Non dichiaro' && condannePenali.styleClasses.includes('hidden')) {
					condannePenali.styleClasses = condannePenaliClasses.replace('hidden', '');
				}
				if (app.model.checkCondannePenali == 'Dichiaro' && !condannePenali.styleClasses.includes('hidden')) {
					condannePenali.styleClasses += ' hidden';
				}

				if (
					(this.app.model.titoloDiStudio == 'Laurea equipollente conseguita in Italia' ||
						this.app.model.titoloDiStudio == "Laurea equivalente conseguita all'estero" ||
						this.app.model.titoloDiStudio == "Laurea equipollente conseguita all'estero") &&
					provvedimento.styleClasses.includes('hidden')
				) {
					provvedimento.styleClasses = provvedimentoClasses.replace('hidden', '');
				}
				if (
					!(this.app.model.titoloDiStudio == 'Laurea equipollente conseguita in Italia') &&
					!(this.app.model.titoloDiStudio == "Laurea equivalente conseguita all'estero") &&
					!(this.app.model.titoloDiStudio == "Laurea equipollente conseguita all'estero") &&
					!provvedimento.styleClasses.includes('hidden')
				) {
					provvedimento.styleClasses += ' hidden';
				}

				if (this.app.model.titoliPreferenza.join().indexOf('figli a') > -1) {
					nFigli.styleClasses = nFigliClasses.replace('hidden', '');
				}
				if (
					!(this.app.model.titoliPreferenza.join().indexOf('figli a') > -1) &&
					!nFigli.styleClasses.includes('hidden')
				) {
					nFigli.styleClasses += ' hidden';
				}
				if (this.app.model.checkDomicilio == 'Dichiaro' && statoDomicilio.styleClasses.includes('hidden')) {
					statoDomicilio.styleClasses = statoDomicilioClasses.replace('hidden', '');
				}
				if (this.app.model.checkDomicilio == 'Dichiaro' && indirizzoDomicilio.styleClasses.includes('hidden')) {
					indirizzoDomicilio.styleClasses = indirizzoDomicilioClasses.replace('hidden', '');
				}
				if (this.app.model.checkDomicilio == 'Dichiaro' && provinciaDomicilio.styleClasses.includes('hidden')) {
					provinciaDomicilio.styleClasses = provinciaDomicilioClasses.replace('hidden', '');
				}
				if (this.app.model.checkDomicilio == 'Dichiaro' && comuneDomicilio.styleClasses.includes('hidden')) {
					comuneDomicilio.styleClasses = comuneDomicilioClasses.replace('hidden', '');
				}
				if (
					this.app.model.checkDomicilio == 'Non dichiaro' &&
					!indirizzoDomicilio.styleClasses.includes('hidden')
				) {
					indirizzoDomicilio.styleClasses += ' hidden';
				}
				if (
					this.app.model.checkDomicilio == 'Non dichiaro' &&
					!statoDomicilio.styleClasses.includes('hidden')
				) {
					statoDomicilio.styleClasses += ' hidden';
				}
				if (
					this.app.model.checkDomicilio == 'Non dichiaro' &&
					!provinciaDomicilio.styleClasses.includes('hidden')
				) {
					provinciaDomicilio.styleClasses += ' hidden';
				}
				if (
					this.app.model.checkDomicilio == 'Non dichiaro' &&
					!comuneDomicilio.styleClasses.includes('hidden')
				) {
					comuneDomicilio.styleClasses += ' hidden';
				}

				if (this.app.model.riserva == 'Dichiaro' && enteRiserva.styleClasses.includes('hidden')) {
					enteRiserva.styleClasses = enteRiservaClasses.replace('hidden', '');
				}
				if (this.app.model.riserva == 'Non dichiaro' && !enteRiserva.styleClasses.includes('hidden')) {
					enteRiserva.styleClasses += ' hidden';
				}
				if (this.app.model.riserva == 'Dichiaro' && posizioneRiserva.styleClasses.includes('hidden')) {
					posizioneRiserva.styleClasses = posizioneRiservaClasses.replace('hidden', '');
				}
				if (this.app.model.riserva == 'Non dichiaro' && !posizioneRiserva.styleClasses.includes('hidden')) {
					posizioneRiserva.styleClasses += ' hidden';
				}
				if (this.app.model.riserva == 'Dichiaro' && anniRiserva.styleClasses.includes('hidden')) {
					anniRiserva.styleClasses = anniRiservaClasses.replace('hidden', '');
				}
				if (this.app.model.riserva == 'Non dichiaro' && !anniRiserva.styleClasses.includes('hidden')) {
					anniRiserva.styleClasses += ' hidden';
				}
				if (this.app.model.disabilita == 'Dichiaro' && ausilioTa.styleClasses.includes('hidden')) {
					ausilioTa.styleClasses = ausilioTaClasses.replace('hidden', '');
				}
				if (this.app.model.disabilita == 'Non dichiaro' && !ausilioTa.styleClasses.includes('hidden')) {
					ausilioTa.styleClasses += ' hidden';
				}
			}
		},
		handleValidation() {},
		submitFile() {
			let formData = new FormData();
			formData.append('file', this.file);
			for (var [key, value] of formData.entries()) {
			}
			this.$store.dispatch('uploadFile', formData);
		},
		handleFileUpload(event) {
			this.file = event.target.files[0];
		},
		signin() {
			if (this.app.code == 'coa202') {
				window.open('https://spid.scanshareservice.it/cas/login', '_blank').focus();
			} else {
				this.$router.push({
					name: 'signin',
					params: {
						service: this.service.slug,
						app: this.app.code,
					},
				});
			}
		},

		esci() {},
		scarica() {
			this.$store.dispatch('scaricaAttestato', this.selectedDay);
		},
		scaricaDomanda() {
			this.$store.dispatch('scaricaDomanda', this.app);
		},

		openForm() {
			this.$refs.form.close();
		},
		invia() {},

		login() {
			this.$router.push({
				path: 'signin',
			});
		},
		async onComplete() {
			if (this.app.model.iscrizioneListeElettorali == 'Dichiaro') {
				this.app.model.ragioniListaElettorale = '';
			}
			if (this.app.model.checkDomicilio == 'Non dichiaro') {
				this.app.model.indirizzoDomicilio = '';
				this.app.model.statoDomicilio = '';
				this.app.model.comuneDomicilio = '';
				this.app.model.provinciaDomicilio = '';
			}
			if (this.app.model.iscrizioneListeElettorali == 'Non dichiaro') {
				this.app.model.listaElettorale = '';
			}
			if (this.app.model.checkCondannePenali == 'Dichiaro') {
				this.app.model.condannePenali = '';
			}
			if (this.app.model.riserva == 'Non Dichiaro') {
				this.app.model.anniRiserva = '';
				this.app.model.enteRiserva = '';
				this.app.model.posizioneRiserva = '';
				this.app.model.posizioneRiserva = '';
			}
			if (!(this.app.model.titoliPreferenza.join().indexOf('figli a') > -1)) {
				this.app.model.nFigli = 0;
			}
			if (
				!(this.app.model.titoloDiStudio == 'Laurea equipollente conseguita in Italia') &&
				!(this.app.model.titoloDiStudio == "Laurea equivalente conseguita all'estero") &&
				!(this.app.model.titoloDiStudio == "Laurea equipollente conseguita all'estero")
			) {
				this.app.model.provvedimento = '';
			}
			if (this.app.model.riserva == 'Non dichiaro') {
				this.app.model.enteRiserva = '';
				this.app.model.posizioneRiserva = '';
				this.app.model.anniRiserva = '';
			}
			if (this.app.model.disabilita == 'Non dichiaro') {
				this.app.model.ausilioTa = '';
			}
			await this.$store.dispatch('savecoa2021', this.app);

			this.expand = false;
			this.preview = false;
			this.app.status = this.$store.getters.app.status;
			this.app.model = this.$store.getters.app.model;
		},
		validateFirstTab: function () {
			const valid = this.$refs.firstTabForm.validate() ? true : false;
			if (valid) {
				return this.$refs.firstTabForm.validate();
			} else {
				alert('Domanda di candidatura incompleta. Verificare la correttezza dei campi inseriti.');
			}
		},

		async inviaDomanda() {
			await this.$store.dispatch('inviaDomanda', this.app);
			this.app.status = this.$store.getters.app.status;
			this.app.model = this.$store.getters.app.model;
			this.expand = false;
			this.preview = false;
		},

		validateSecondTab: function () {
			return this.$refs.secondTabForm.validate();
		},

		prettyJSON: function (json) {
			if (json) {
				json = JSON.stringify(json, undefined, 4);
				json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
				return json.replace(
					/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
					function (match) {
						var cls = 'number';
						if (/^"/.test(match)) {
							if (/:$/.test(match)) {
								cls = 'key';
							} else {
								cls = 'string';
							}
						} else if (/true|false/.test(match)) {
							cls = 'boolean';
						} else if (/null/.test(match)) {
							cls = 'null';
						}
						return '<span class="' + cls + '">' + match + '</span>';
					}
				);
			}
		},
		presenzaTable(data) {
			const presenzaUtente = this.presenze.find((presenza) => {
				return presenza.data == data;
			});
			let infoSessione = {};
			infoSessione = this.app.lezioni.find((sessione) => sessione.data == data);

			let presenza = 0;
			let assenza = 0;
			if (presenzaUtente) {
				const minuti = presenzaUtente.minuti;
				const assenzaOre = minuti / 60;
				if (Number.isInteger(assenzaOre)) {
					presenza = infoSessione.duration - assenzaOre;
					assenza = assenzaOre;
					presenza = presenza + ':00';
					assenza = assenza + ':00';
				} else {
					presenza = infoSessione.duration - (Math.floor(assenzaOre) + 1);
					let presenzaMinuti =
						60 - (minuti % 60) > 9 ? ':' + (60 - (minuti % 60)) : ':0' + (60 - (minuti % 60));

					presenza = presenza + presenzaMinuti;
					let assenzaMinuti = minuti % 60 > 9 ? ':' + (minuti % 60) : ':0' + (minuti % 60);
					assenza = Math.floor(assenzaOre) + assenzaMinuti;
				}
			} else {
				presenza = '00:00';
			}

			return presenza;
		},
		assenzaTable(data) {
			const presenzaUtente = this.presenze.find((presenza) => {
				return presenza.data == data;
			});
			let infoSessione = {};
			infoSessione = this.app.lezioni.find((sessione) => sessione.data == data);
			let presenza = 0;
			let assenza = 0;
			if (presenzaUtente) {
				const minuti = presenzaUtente.minuti;
				const assenzaOre = minuti / 60;

				if (Number.isInteger(assenzaOre)) {
					presenza = infoSessione.duration - assenzaOre;
					assenza = assenzaOre;
					presenza = presenza + ':00';
					assenza = assenza + ':00';
				} else {
					presenza = infoSessione.duration - (Math.floor(assenzaOre) + 1);
					const presenzaMinuti = 60 - (minuti % 60);
					presenza = presenza + ':' + presenzaMinuti;
					let assenzaMinuti = minuti % 60 > 9 ? ':' + (minuti % 60) : ':0' + (minuti % 60);
					assenza = Math.floor(assenzaOre) + assenzaMinuti;
				}
			} else {
				presenza = '00:00';
			}

			return assenza;
		},
	},
	watch: {
		app: {
			handler(app) {
				this.toggleForm(app);
			},
			deep: true,
		},
	},
	computed: {
		comunications() {
			return this.$store.getters.comunications;
		},
		isOpen() {
			let today = new Date(Date.now());
			let closeZ = new Date(Date.parse('2021-12-09T23:00:00.000Z'));
			let condition = today < closeZ;
			return condition;
		},
		app() {
			if (this.$router.currentRoute.params.app) {
				const app = this.$store.getters.service.apps.find(
					(app) => app.code == this.$router.currentRoute.params.app
				);
				let tempApp = app;
				if (this.$store.getters.userApp.code) {
					tempApp.status = this.$store.getters.userApp.status;
					tempApp.model = this.$store.getters.userApp.model;
					this.$store.commit('app', tempApp);
					return tempApp;
				} else {
					this.$store.commit('app', tempApp);
					return tempApp;
				}
			} else {
				return this.$store.getters.userApp ? this.$store.getters.userApp : this.$store.getters.app;
			}
		},
		oreTotaliCorso() {
			let oreTotali = 0;
			this.presenze.forEach((presenza) => {
				oreTotali = oreTotali + parseInt(presenza.duration);
			});

			return oreTotali;
		},
		orePresenzaTotali() {
			let presenza = this.oreTotaliCorso;
			let assenza = 0;

			this.presenze.forEach((presenza) => {
				assenza = assenza + parseInt(presenza.minuti);
			});
			const assenzaOre = assenza / 60;
			if (Number.isInteger(assenzaOre)) {
				presenza = presenza - assenzaOre;
				assenza = assenzaOre;
				presenza = presenza + ':00';
				assenza = assenza + ':00';
			} else {
				presenza = presenza - (Math.floor(assenzaOre) + 1);
				const presenzaMinuti = 60 - (assenza % 60);
				presenza = presenza + ':' + presenzaMinuti;
				assenza = Math.floor(assenzaOre) + ':' + (assenza % 60);
			}

			return presenza;
		},
		oreAssenzaTotali() {
			let presenza = this.oreTotaliCorso;
			let assenza = 0;

			this.presenze.forEach((presenza) => {
				assenza = assenza + presenza.minuti;
			});
			const assenzaOre = assenza / 60;
			if (Number.isInteger(assenzaOre)) {
				presenza = presenza - assenzaOre;
				assenza = assenzaOre;
				presenza = presenza + ':00';
				assenza = assenza + ':00';
			} else {
				presenza = presenza - (Math.floor(assenzaOre) + 1);
				const presenzaMinuti = 60 - (assenza % 60);
				presenza = presenza + ':' + presenzaMinuti;
				assenza = Math.floor(assenzaOre) + ':' + (assenza % 60);
			}

			return assenza;
		},
		lessonToday() {
			let today = new Date();
			let dd = String(today.getDate()).padStart(2, '0');
			let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
			let yyyy = today.getFullYear();
			today = dd + '/' + mm + '/' + yyyy;
			//today = '29/09/2021';

			let dataLezione = this.app.lezioni.find((lezione) => {
				return lezione.data == today;
			});

			return dataLezione ? true : false;
		},
		joinPreferenze() {
			return this.app.model.titoliPreferenza.join(' - ');
		},
		righePresenza() {
			let maxModulo = 1;

			let righe = this.presenze.map((presenza) => {
				return this.app.lezioni.find((infoLezione) => {
					if (infoLezione.modulo > maxModulo) {
						maxModulo = infoLezione.modulo;
					}
					return infoLezione.data == presenza.data;
				});
			});

			let finalRighe = righe.filter((riga) => {
				return riga.dataEn < new Date(nowDateString).getTime();
			});
			return finalRighe;
		},
		presenze() {
			const presenze = JSON.parse(this.user.data);
			const nowDd = new Date().getDate();
			const nowMm = new Date().getMonth();
			const nowYyyy = new Date().getFullYear();
			const nowDateString = `${nowDd}/${nowMm + 1}/${nowYyyy}`;

			const certificati = presenze.filter((certificato) => certificato.data != nowDateString);
			certificati.forEach((riga) => {
				riga.dataEn = riga.data.split('/')[1] + '/' + riga.data.split('/')[0] + '/' + riga.data.split('/')[2];
				riga.dataEn = new Date(riga.dataEn).getTime();
			});

			const nowDateStringEn = `${nowMm + 1}/${nowDd}/${nowYyyy}`;

			let finalRighe = certificati.filter((riga) => {
				return riga.dataEn < new Date(nowDateStringEn).getTime();
			});

			let maxModulo = 1;
			this.app.lezioni.forEach((lezione) => {
				if (lezione.modulo > maxModulo) {
					maxModulo = lezione.modulo;
				}
			});

			let righe = finalRighe.filter((presenza) => {
				return this.app.lezioni.find((lezione) => {
					if (lezione.modulo > maxModulo) {
						maxModulo = lezione.modulo;
					}
					return lezione.data == presenza.data;
				});
			});

			let righe2 = righe.map((riga) => {
				let lezione = this.app.lezioni.find((lezione) => {
					return lezione.data == riga.data;
				});
				if (lezione) {
					riga.duration = lezione.duration;
					riga.modulo = lezione.modulo;
				}
				return riga;
			});

			let righeFinali = [];
			if (!this.app.lastModule) {
				righeFinali = righe2.filter((riga) => {
					return riga.modulo < maxModulo;
				});
			} else {
				righeFinali = righe2;
			}

			return righeFinali;
		},

		labelButtonForm() {
			return (this.expand = false ? 'Apri' : 'Chiudi');
		},
		services() {
			return this.$store.getters.services;
		},
		requiredUpload() {
			return this.app.model.disabilita == 'Dichiaro' || this.app.model.disabilita80 == 'Dichiaro' ? true : false;
		},

		uploaded() {
			return this.$store.getters.userApp.uploadFile;
		},
		serviceAttributes() {
			return Object.keys(this.services[0]);
		},
		user() {
			return this.$store.getters.user;
		},
		userData() {
			return () => {
				this.user.token ? true : false;
			};
		},
		isLoggedIn() {
			return this.user ? true : false;
		},
		appCode() {
			return this.$router.currentRoute.params.app;
		},
		service() {
			return this.$store.getters.service;
		},
		stanzaRegistrazione() {
			return this.$store.getters.app.linkZoom;
		},
		alert() {
			return this.$store.getters.app.linkZoom != '' ? 'Corso Attivo' : 'Al momento il corso non è attivo';
		},

		selectedValidDay() {
			return this.selectedDay == 'Seleziona giorno' ? true : false;
		},
	},

	created() {
		if (this.app.code == 'coa2021') {
			this.updateDataForm.email = this.$store.getters.updateDataForm.email;
			this.updateDataForm.repeatEmail = this.$store.getters.updateDataForm.repeatEmail;
			this.updateDataForm.pec = this.$store.getters.updateDataForm.pec;
			this.updateDataForm.repeatPec = this.$store.getters.updateDataForm.repeatPec;
			this.updateDataForm.statoResidenza = this.$store.getters.updateDataForm.statoResidenza;
			this.updateDataForm.statoDomicilio = this.$store.getters.updateDataForm.statoDomicilio;
			this.updateDataForm.provinciaResidenza = this.$store.getters.updateDataForm.provinciaResidenza;
			this.updateDataForm.provinciaDomicilio = this.$store.getters.updateDataForm.provinciaDomicilio;
			this.updateDataForm.residenza = this.$store.getters.updateDataForm.residenza;
			this.updateDataForm.domicilio = this.$store.getters.updateDataForm.domicilio;
			this.updateDataForm.comuneResidenza = this.$store.getters.updateDataForm.comuneResidenza;
			this.updateDataForm.comuneDomicilio = this.$store.getters.updateDataForm.comuneDomicilio;
			const province = this.app.form.fields.find((el) => el.model == 'provinciaResidenza').values;
			const states = this.app.form.fields.find((el) => el.model == 'statoResidenza').values;
			this.updateDataForm.province = province;
			this.updateDataForm.states = states;
			console.log(this.user);
		}

		this.toggleForm(this.app);
		if (this.user && this.user.type != 'admin') {
			if (this.user.app != this.app.code) {
				this.$router.replace({
					name: 'app',
					params: {
						app: this.user.app,
						service: this.service.slug,
					},
				});
			}
		}
	},
};
</script>

<style>
ul {
	list-style-type: none;
	padding: 0;
	/* Remove padding */
	margin: 0;
	/* Remove margins */
}

li {
	/* Remove bullets */
	padding: 3px;
	/* Remove padding */
	margin: 0;
	/* Remove margins */
	margin-left: 8px;
	margin-bottom: 8px;
}

.logo {
	padding-left: 5px;
	margin: 0 auto;
	display: block;
}

.logoContainer {
	display: flex;
	align-items: center;
}

.page {
	width: 21cm;
	min-height: 29.7cm;
	padding: 2cm;
	margin: 1cm auto;
	border: 1px #d3d3d3 solid;
	border-radius: 5px;
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
