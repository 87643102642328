<template>
	<div class="row">
		<p v-for="(value, name, index) in user" :key="index">
			{{ name }}: <b>{{ value }}</b>
		</p>
		<!-- <div class="col-md-12">
			<edit-profile-form :model="user"> </edit-profile-form>
		</div>
				<div class="col-md-4">
			<user-card :user="user"></user-card>
		</div> -->
	</div>
</template>
<script>
import EditProfileForm from './Profile/EditProfileForm';
import UserCard from './Profile/UserCard';
export default {
	components: {
		EditProfileForm,
		UserCard
	},
	data() {
		return {};
	},
	computed: {
		user() {
			return this.$store.getters.user;
		}
	},
	created() {
		console.log(this.user);
	}
};
</script>
<style></style>
