<template>
	<div>
		<!-- 		<vue-simple-spinner class="spinnerCenter" size="70" line-size="5" message="Caricamento..."></vue-simple-spinner>
 -->
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<card v-if="app.code == 'coa2021'">
			<h4 class="card-title">{{ app.title }}</h4>
			<div class="m-3 p-3">
				<h4>
					Per quesiti di <b>carattere tecnico-informatico</b> riguardanti la compilazione e la presentazione
					della domanda sono disponibili i seguenti contatti:
					<ul style="list-style-type: circle">
						<li>
							<b><i>coa2021@scanshare.it</i></b>
						</li>
						<li>
							0984/402764 (Da Lunedì al Venerdì i giorni dalle 9:30 – 13:30 / 14:30 – 18.30)
						</li>
					</ul>
					<br /><i></i> Per quesiti di <b>carattere giuridico-amministrativo</b> riguardanti la compilazione e
					l’invio della domanda di ammissione al concorso è attiva la casella di posta elettronica
					<b><i>concorsocoa2021@interno.it. </i>Tale modalità di contatto ha carattere esclusivo.</b
					><br /><br />

					<ul style="list-style-type: circle">
						<li>
							<b>
								Per lo svolgimento delle prove scritte posso introdurre nella sala di concorso testi di
								legge e/o codici giuridici non commentati? </b
							><br />
							No. La Commissione esaminatrice, sulla base della tipologia di prove e di quanto disposto
							dall’art.7 del bando di concorso (che prevede esclusivamente dei divieti, tra cui quello di
							introdurre e usare nella sede d’esame codici commentati, e non contiene invece una
							previsione espressa di ammissione di testi di legge non commentati) - ha stabilito, tra
							l’altro, che ai candidati non verrà consentita l'introduzione di codici giuridici, anche non
							commentati e di testi normativi. <br />Quanto sopra, peraltro, coerentemente a quanto
							stabilito dall’art.13 del d.P.R. n. 487/94 (regolamento recante norme sull'accesso agli
							impieghi nelle pubbliche amministrazioni e le modalità di svolgimento dei concorsi, dei
							concorsi unici e delle altre forme di assunzione nei pubblici impieghi) che dispone: “(I
							candidati) Possono consultare soltanto i testi di legge non commentati ed autorizzati dalla
							commissione, se previsti dal bando di concorso….” (comma 3).<br />
							In ogni caso, la Commissione esaminatrice ha precisato che, qualora i quesiti formulati
							dovessero richiedere la consultazione di specifica documentazione, la stessa verrà messa a
							disposizione dei candidati a corredo del singolo quesito.
						</li>
						<li>
							<b>
								In quale modalità si svolgeranno le due prove scritte previste dall’ articolo 7 del
								bando di concorso?</b
							><br />
							Le due prove scritte si svolgeranno in presenza, secondo il diario pubblicato sulla Gazzetta
							Ufficiale 4° Serie Speciale - Concorsi ed Esami n. 82 del 14-10-2022, mediante
							strumentazione informatica e piattaforme digitali. Nello specifico, i candidati avranno a
							disposizione una postazione informatica dotata di tablet, supporto tablet da tavolo e
							tastiera.
						</li>
						<li>
							<b>
								Sono un candidato con cognome composto da due parole in cui è presente uno spazio (ad
								es. LO BIANCO – LI ROSI). Come faccio ad individuare la sessione di prova preselettiva
								di attribuzione, secondo il riparto stabilito nel diario pubblicato? </b
							><br />
							I candidati nel cui cognome è presente uno spazio (es. LO DUCA – LI VOLSI), per individuare
							la sessione di prova preselettiva loro assegnata, devono fare riferimento all’ordine
							alfabetico delle lettere che compongono la prima parola del cognome, tenendo conto dello
							spazio (ad esempio, LO DUCA precede LODDO e LI VOLSI precede LICCIARDO).
						</li>
						<li>
							<b> Alla prova preselettiva posso portare codici e/o testi di legge? </b><br />
							Durante la prova preselettiva i candidati non possono avvalersi de codici, raccolte
							normative, vocabolari, testi, appunti di qualsiasi natura e di telefoni cellulari ed altri
							dispositivi mobili idonei alla memorizzazione i alla trasmissione di dati i allo svolgimento
							di calcoli, né potranno comunicare tra loro. Articolo 6, comma 9 del Bando di concorso.
						</li>
						<li>
							<b> Come verifico se sono iscritto al concorso? </b><br />
							L’iscrizione al concorso è certificata dalla ricevuta che il sistema informatico ha
							rilasciato al termine della procedura di iscrizione, dopo l’invio della domanda.
						</li>
						<li>
							<b
								>Non trovo più la ricevuta della domanda di partecipazione che avevo stampato in sede di
								invio. Come faccio a stamparla di nuovo? </b
							><br />La ricevuta della domanda di partecipazione, che, ai sensi dell’articolo. 3, comma 5,
							del bando di concorso, dovrà essere consegnata il giorno stabilito per la prova
							preselettiva, può essere scaricata nuovamente dalla presente piattaforma, (Home - Area
							riservata), mediante l’utilizzo delle proprie credenziali del Sistema Pubblico di Identità
							Digitale (SPID).<br />
							<span style="text-decoration: underline;"
								>Il giorno della prova preselettiva non sarà consentito presentare la ricevuta della
								domanda scaricata digitalmente sul proprio smartphone o altro dispositivo
								elettronico.</span
							>
						</li>
						<li>
							<b>
								Se la mia residenza o il mio domicilio fossero variati rispetto a quanto indicato in
								fase di invio della domanda, quali sono le modalità per comunicare il nuovo recapito ai
								fini dell’attribuzione della sede dove sarò chiamato/a a svolgere la prova
								preselettiva?</b
							><br />
							<span style="text-decoration: underline;">
								Ai fini dell’attribuzione della sede dove sarò chiamato/a a svolgere la prova
								preselettiva vale esclusivamente la residenza o il domicilio, se diverso dalla
								residenza, indicati nella domanda.</span
							>
							Eventuali successive variazioni di residenza o domicilio
							<b>non potranno</b> essere prese in considerazione. Articolo 6, comma 1 del Bando.
						</li>
						<li>
							<b>
								Se i miei recapiti (indirizzo, mail ordinaria e/o pec) fossero variati rispetto a quanto
								indicato in fase di invio della domanda, quali sono le modalità per comunicare i nuovi? </b
							><br />
							Eventuali variazioni dei suddetti recapiti dovranno essere comunicati dal candidato,
							esclusivamente tramite la presente piattaforma (Area personale), mediante l’utilizzo delle
							proprie credenziali del Sistema Pubblico di Identità Digitale (SPID). Articolo 3, comma 13
							del Bando.
						</li>
						<li>
							<b
								>Posso presentare alle prove preselettive un documento di riconoscimento diverso da
								quello dichiarato in sede id invio della domanda di partecipazione? </b
							><br />
							Sì, purché sia un documento tra quelli previsti dall’articolo 35 del d.P.R. n. 445/2000 in
							corso di validità.
						</li>
						<li>
							<b
								>Ho un’invalidità certificata uguale o superiore all’80% e ho già caricato sulla
								piattaforma la documentazione prevista. Come faccio a sapere se sono stato esonerato dal
								partecipare alla prova preselettiva? </b
							><br />Non sarò tenuto a sostenere la prova preselettiva e accederò direttamente alle prove
							scritte, se avrò correttamente presentato nelle modalità e nei termini (temporali) previsti
							dal Bando, la documentazione comprovante la patologia e il grado di invalidità uguale o
							superiore all’80%. A tal fine il candidato riceverà, in tempo utile, comunicazione termite
							pec dell’Amministrazione.
						</li>
						<li>
							<b>
								Sono un candidato diversamente abile (o con diagnosi di disturbi specifici di
								apprendimento - DSA) e ho la necessità, per poter svolgere la prova preselettiva, di un
								particolare ausilio e/o di tempi aggiuntivi. Come faccio a sapere se questi mi saranno
								forniti? </b
							><br />La commissione di concorso, acquisita la documentazione, procederà al suo esame e,
							prima della prova preselettiva, comunicherà ai candidati interessati, tramite pec
							dell’Amministrazione, l’accoglimento, ovvero il rigetto, della richiesta del beneficio
						</li>

						<li>
							<b> Cosa devo fare per compilare la domanda on-line?</b><br />
							La domanda deve essere prodotta in via telematica (a pena di irricevibilità), utilizzando
							per l’identificazione la propria utenza SPID (Sistema Pubblico di Identità Digitale),
							accedendo all’apposita piattaforma digitale raggiungibile all’indirizzo internet
							<i>https://app.scanshareservice.it/albo-nazionale-segretari/coa2021.</i>
						</li>
						<li>
							<b> Cos’è SPID?</b><br />

							L’identità digitale SPID è rilasciata dai gestori di identità digitale (Identity Provider)
							accreditati presso l’ente preposto. <br />Per ottenere l’identità digitale SPID l’utente
							deve farne richiesta ad uno di tali gestori. Il gestore prescelto, dopo aver verificato
							l’identità del richiedente, emette l’identità digitale e rilascia le relative
							credenziali.<br />
							Le stesse sono utilizzabili per l’accesso a tutti i siti della Pubblica Amministrazione che
							hanno recepito l’accesso SPID.<br />
							Tutte le informazioni necessarie per richiedere le credenziali SPID sono disponibili sulla
							rete internet all’indirizzo
							<a target="_blank" href="https://www.spid.gov.it/richiedi-spid"
								>https://www.spid.gov.it/richiedi-spid.</a
							>
						</li>

						<li>
							<b>
								Per la partecipazione al corso-concorso è necessario essere in possesso di un indirizzo
								PEC? </b
							><br />

							Sì, per la partecipazione al corso-concorso il candidato deve essere in possesso di un
							indirizzo di posta elettronica certificata (PEC) a lui intestato.
						</li>

						<li>
							<b>
								Ho il domicilio in un luogo diverso da quello di residenza. Posso indicarlo nella
								domanda di partecipazione? </b
							><br />

							Sì. Il luogo di domicilio, se diverso da quello di residenza, può essere indicato
							nell’apposito campo del modulo di domanda. Nella compilazione del campo relativo al luogo di
							residenza, nonché di quello dell’eventuale domicilio,
							<b>si raccomanda la massima attenzione</b>, in particolare nel selezionare, nell’apposito
							menù a tendina, la provincia interessata.
						</li>
						<li>
							<b>
								Cosa devo fare se mi accorgo di avere inoltrato una domanda in cui vi sono degli errori? </b
							><br />

							Si deve compilare e inoltrare una nuova domanda entro i termini. Ai fini della
							partecipazione al concorso, si terrà conto unicamente della domanda inviata cronologicamente
							per ultima entro i termini di presentazione.
						</li>
						<li>
							<b> Posso modificare le dichiarazioni rese nella domanda di partecipazione? </b><br />

							È possibile farlo fino al momento dell’invio della domanda. Dopo l’invio, invece, per
							modificare le dichiarazioni rese nella precedente domanda occorre procedere all’invio di una
							nuova domanda. Solo quest’ultima sarà quella presa in considerazione.
						</li>
						<li>
							<b> Cosa succede se invio più volte il format on-line della stessa domanda?</b><br />

							In questo caso, si tiene conto unicamente dell’ultima domanda inviata nei termini.
						</li>
						<li>
							<b> A che data devono essere posseduti i requisiti di ammissione al concorso?</b><br />

							I requisiti devono essere posseduti alla data di scadenza del termine utile per la
							presentazione delle domande.
						</li>
						<li>
							<b> Posso partecipare al concorso se sono in possesso della sola laurea triennale?</b><br />
							No.
						</li>
						<li>
							<b>
								Posso partecipare al concorso se ho conseguito una laurea diversa da quelle indicate nel
								bando e prevista nel modulo di domanda di partecipazione?</b
							><br />

							Non è possibile partecipare al concorso se non si è in possesso dei titoli di laurea
							indicati nel bando ovvero ad essi equipollenti o equiparati.<br />
							Tuttavia, qualora si sia in possesso di eventuale titolo equipollente si potrà procedere
							all’indicazione dello stesso nell’apposito menu a tendina selezionando “laurea equipollente
							conseguita in Italia” e, quindi, a compilare il successivo campo rubricato “provvedimento di
							equipollenza/equivalenza”, indicando il titolo di studio conseguito e gli estremi del
							provvedimento di equipollenza.
						</li>
						<li>
							<b>
								Sono in possesso di un titolo di studio previsto dal bando ma conseguito all'estero, è
								valido per partecipare al corso-concorso?</b
							><br />

							Per le modalità di riconoscimento dei titoli conseguiti all’estero si rinvia alle esaurienti
							informazioni riportate nel sito istituzionale del MIUR al seguente link:
							<a target="_blank" href="https://www.miur.gov.it/titoli-accademici-esteri"
								>https://www.miur.gov.it/titoli-accademici-esteri.</a
							>
						</li>
						<li>
							<b>
								Ho un titolo di studio conseguito all’estero: cosa devo indicare nella domanda di
								partecipazione?</b
							><br />

							Se il titolo di studio è stato conseguito all’estero, il candidato deve selezionare “laurea
							equipollente conseguita all’estero” oppure “laurea equivalente conseguita all’estero” e
							quindi indicare nel successivo campo rubricato “provvedimento di equipollenza/equivalenza”
							la laurea conseguita e gli estremi del provvedimento con il quale il titolo stesso è stato
							riconosciuto equipollente o equivalente al corrispondente titolo italiano. Qualora il
							candidato non sia ancora in possesso della dichiarazione di equipollenza/equivalenza dovrà
							indicare nella domanda la data di presentazione della richiesta alla competente autorità.
						</li>
						<li>
							<b> Chi può avvalersi della riserva di cui all’art. 1, comma 2, del bando?</b><br />

							Possono avvalersi della riserva i dipendenti delle amministrazioni pubbliche di cui
							all’articolo 1, comma 2, del decreto legislativo 30 marzo 2001, n. 165, in possesso di uno
							dei titoli di studio previsti per l’accesso alla carriera di segretario comunale e che
							abbiano almeno cinque anni di anzianità di effettivo servizio in posizioni funzionali per
							accedere alle quali è richiesto il possesso dei medesimi titoli di studio.
						</li>
						<li>
							<b>
								Ho un titolo di studio conseguito all’estero: cosa devo indicare nella domanda di
								partecipazione?</b
							><br />

							Se il titolo di studio è stato conseguito all’estero, il candidato deve selezionare “laurea
							equipollente conseguita all’estero” oppure “laurea equivalente conseguita all’estero” e
							quindi indicare nel successivo campo rubricato “provvedimento di equipollenza/equivalenza”
							la laurea conseguita e gli estremi del provvedimento con il quale il titolo stesso è stato
							riconosciuto equipollente o equivalente al corrispondente titolo italiano. Qualora il
							candidato non sia ancora in possesso della dichiarazione di equipollenza/equivalenza dovrà
							indicare nella domanda la data di presentazione della richiesta alla competente autorità.
						</li>
						<li>
							<b> Chi può avvalersi della riserva di cui all’art. 1, comma 2, del bando?</b><br />

							Possono avvalersi della riserva i dipendenti delle amministrazioni pubbliche di cui
							all’articolo 1, comma 2, del decreto legislativo 30 marzo 2001, n. 165, in possesso di uno
							dei titoli di studio previsti per l’accesso alla carriera di segretario comunale e che
							abbiano almeno cinque anni di anzianità di effettivo servizio in posizioni funzionali per
							accedere alle quali è richiesto il possesso dei medesimi titoli di studio.
						</li>
						<li>
							<b>
								È possibile partecipare al corso-concorso se la laurea verrà conseguita successivamente
								alla data di pubblicazione del bando?</b
							><br />

							Sì, purché conseguita entro la data di scadenza del termine utile per la presentazione delle
							domande.
						</li>
						<li>
							<b>
								Sono portatore di handicap (o con diagnosi di disturbi specifici di apprendimento - DSA)
								e ho necessità di ausili e/o di tempi aggiuntivi per lo svolgimento delle prove
								concorsuali, a chi devo fare la richiesta?</b
							><br />

							Innanzitutto, nell’apposito spazio della domanda on-line, si dovrà fare esplicita richiesta
							dell’ausilio necessario e/o di tempi aggiuntivi necessari per l’espletamento delle prove di
							esame in relazione alla disabilità (o al disturbo specifico). Entro venti giorni dalla
							scadenza del termine utile di presentazione della domanda di ammissione, il candidato dovrà
							caricare in formato elettronico – esclusivamente nell’area riservata della piattaforma –
							tutta la documentazione di supporto alla dichiarazione resa sulla disabilità (o disturbo
							specifico). A tal fine saranno accettati file in formato pdf.<br />
							L’assegnazione di ausili e/o tempi aggiuntivi è comunque concessa a insindacabile giudizio
							della Commissione esaminatrice sulla scorta della documentazione esibita e sull’esame
							obiettivo di ogni specifico caso.
						</li>
						<li>
							<b>
								Ho diritto ad essere esonerato dal sostenere la prova preselettiva ai sensi dell’art.
								20, comma 2-bis, della legge 104/1992, cosa devo fare? </b
							><br />

							In primo luogo, occorre dichiarare nella domanda on-line di volersi avvalere del
							beneficio.<br />
							Entro venti giorni dalla scadenza del termine utile di presentazione della domanda di
							ammissione, il candidato dovrà caricare in formato elettronico – esclusivamente nell’area
							riservata della piattaforma – la documentazione comprovante la patologia da cui è affetto e
							il grado di invalidità, nonché l’esplicita autorizzazione al trattamento dei dati sensibili.
							A tal fine saranno accettati file in formato pdf.
						</li>
						<li>
							<b>
								Cosa si intende per “figli a carico” quale titolo di preferenza a parità di punteggio? </b
							><br />

							Si considerano fiscalmente a carico i figli (compresi i figli adottivi, affidati o
							affiliati) – indipendentemente dal superamento di determinati limiti di età e dal fatto che
							siano o meno dediti agli studi o al tirocinio gratuito – che rientrano nel nucleo familiare
							e nell’anno precedente hanno posseduto un reddito complessivo, al lordo degli oneri
							deducibili, uguale o inferiore a € 2.840,51.<br />
							A partire da 1° gennaio 2018, in forza di quanto stabilito dalla Legge di bilancio 2018, per
							i figli di età inferiore a 24 anni il suddetto limite è stato innalzato a € 4.000,00,
							rimanendo invariato il limite di € 2.840,51 per i figli di età superiore. <br />Il reddito
							al quale si fa riferimento è quello del figlio e non del genitore.<br />
							Il figlio è da considerarsi a carico ai fini del possesso del relativo titolo di preferenza
							a prescindere dalla circostanza che, dal punto di vista fiscale, lo stesso sia a carico di
							uno dei due genitori.
						</li>
						<li>
							<b>
								Ho diritto ad essere esonerato dal sostenere la prova preselettiva ai sensi dell’art.
								20, comma 2-bis, della legge 104/1992, cosa devo fare? </b
							><br />

							In primo luogo, occorre dichiarare nella domanda on-line di volersi avvalere del beneficio.
							Entro venti giorni dalla scadenza del termine utile di presentazione della domanda di
							ammissione, il candidato dovrà caricare in formato elettronico – esclusivamente nell’area
							riservata della piattaforma – la documentazione comprovante la patologia da cui è affetto e
							il grado di invalidità, nonché l’esplicita autorizzazione al trattamento dei dati sensibili.
							A tal fine saranno accettati file in formato pdf.
						</li>
						<li>
							<b>
								È possibile presentare la domanda on-line prima di avere effettuato il bonifico di €
								10,00 per i diritti di segreteria?</b
							><br />

							No. Nella domanda occorre indicare obbligatoriamente gli elementi identificativi del
							bonifico che, pertanto, deve essere effettuato prima.
						</li>
						<li>
							<b>
								Come posso versare i 10,00 € relativi ai diritti di segreteria se la mia banca non
								accetta il versamento on-line di somme verso la Tesoreria dello Stato?</b
							><br />

							È possibile effettuare il versamento presentandosi fisicamente presso gli sportelli
							dell’istituto di credito, ovvero presso un qualsiasi ufficio postale. <br />Si raccomanda
							comunque, una volta effettuato il pagamento on-line, di verificare che questo sia andato a
							buon fine.
						</li>
						<li>
							<b>
								Nel campo riservato alla causale del versamento del bonifico non c’è spazio sufficiente
								per tutti i caratteri che dovrei inserire in base a quanto richiesto dall’art. 3, comma
								15, del bando. Cosa devo fare?</b
							><br />

							In tal caso è possibile indicare esclusivamente il proprio codice fiscale seguito da “COA
							2021”.
						</li>
						<li>
							<b> Cosa succede alla scadenza del termine per l’invio on-line della domanda?</b><br />

							Si potrà accedere alla piattaforma esclusivamente per visualizzare e/o stampare la domanda
							già inoltrata.
						</li>
						<li>
							<b> Da quando è possibile inviare la domanda?</b><br />

							Dalle ore 00:00:01 del 10 novembre 2021, giorno successivo alla pubblicazione del bando.
						</li>
						<li>
							<b> Fino a quando è possibile inviare la domanda?</b><br />

							Fino alle ore 23:59:59 del 9 dicembre 2021.
						</li>
						<li>
							<b> Devo conservare la stampa della domanda?</b><br />

							Sì. Ai sensi dell’art. 3, comma 5, del bando di concorso,
							<i
								>“Dopo aver presentato la domanda, il candidato effettuerà la stampa della ricevuta di
								avvenuta iscrizione al concorso che dovrà essere consegnata il giorno stabilito per la
								prova preselettiva”.</i
							>
						</li>
						<li>
							<b>
								Cosa succede se invio una domanda cartacea ma non invio la domanda on-line dalla
								procedura web?</b
							><br />

							La domanda non sarà presa in considerazione. L’iscrizione al corso-concorso si effettua
							esclusivamente mediante la procedura on-line.
						</li>
						<li>
							<b> Vorrei chiarimenti in merito alla dichiarazione di “lodevole servizio”.</b><br />

							La dichiarazione di lodevole servizio prevista è un titolo di preferenza e consiste in una
							dichiarazione che definisce lodevole la qualità del lavoro svolto dal dipendente pubblico.
							Ai fini del corso-concorso la dichiarazione può essere rilasciata dalla pubblica
							amministrazione presso la quale il candidato ha svolto o sta svolgendo il proprio servizio.
						</li>
						<li>
							<b>
								La ricevuta di bonifico rilasciata dalla mia banca riporta, anziché il codice CRO, solo
								il codice TRN. È possibile indicare tale codice nel form di compilazione della domanda
								di partecipazione al concorso?</b
							><br />
							Sì, è possibile indicare il codice TRN, o comunque altro codice che identifichi in maniera
							univoca il bonifico.
						</li>
						<li>
							<b>
								Posso effettuare il versamento della quota prevista dal bando anche da un conto non
								intestato a me? </b
							><br />
							Si, indicando comunque nella causale il cognome nome/codice fiscale/COA2021 del candidato.
						</li>
						<li>
							<b>
								Il mio titolo di studio può ritenersi equipollente o equiparato a quelli richiesti dal
								bando? </b
							><br />
							Se il titolo di studio in possesso del candidato non è quello espressamente previsto dal
							bando di concorso, è onere del candidato verificarne l’equipollenza e/o l’equiparazione in
							base alla normativa di riferimento reperibile sul sito del Ministero dell’istruzione
							dell’università e della ricerca.
						</li>
						<li>
							<b>
								Sono in possesso di una laurea conseguita in vigenza del vecchio ordinamento. Quale voce
								devo selezionare nel menu a tendina? </b
							><br />
							Le lauree del vecchio ordinamento che danno titolo a partecipare al corso-concorso sono
							quelle in Giurisprudenza, Economia e Commercio e Scienze Politiche, presenti quali prime tre
							voci nel menu a tendina. Nell’ipotesi di un titolo di studio diverso da questi, conseguito
							con il vecchio ordinamento ed equipollente alle suddette tre lauree, è necessario
							selezionare nel menu a tendina la voce “Laurea equipollente conseguita in Italia”, oppure
							“Laurea equipollente conseguita all’estero”.
						</li>
						<li>
							<b>
								Sono un dipendente pubblico di una pubblica amministrazione e ho maturato i 5 anni di
								servizio richiesti dal bando ai fini della riserva dei posti in categoria C comparto
								Funzioni Locali, come istruttore amministrativo. Ho i requisiti per avere diritto alla
								riserva? </b
							><br />
							No. Il bando prevede che il trenta per cento dei posti sia riservato ai dipendenti delle
							amministrazioni pubbliche di cui all’articolo 1, comma 2, del decreto legislativo 30 marzo
							2001, n. 165, in possesso dei titoli di studio previsti per l’accesso alla carriera dei
							Segretari comunali e provinciali e
							<u>
								con un’anzianità di servizio di almeno cinque anni in posizioni funzionali per l’accesso
								alle quali è previsto il possesso dei medesimi titoli di studio.</u
							>
						</li>
						<li>
							<b>
								Cosa si intende, ai fini della riserva prevista dal bando di concorso, per dipendente di
								pubbliche Amministrazioni di cui all'articolo 1, comma 2, del decreto legislativo 30
								marzo 2001, n.165? </b
							><br />
							Si intendono i dipendenti di ruolo, con contratto a tempo indeterminato, di tutte le
							Amministrazioni dello stato, ivi compresi gli istituti e scuole di ogni ordine e grado e le
							istituzioni educative, le aziende ed Amministrazioni dello Stato ad ordinamento autonomo, le
							Regioni, le Province, i Comuni, le Comunità Montane, e loro consorzi e associazioni, le
							istituzioni universitarie, gli Istituti autonomi case popolari, le Camere di commercio,
							industria, artigianato e agricoltura e loro associazioni, tutti gli enti pubblici non
							economici nazionali, regionali e locali, le Amministrazioni, le aziende e gli enti del
							Servizio sanitario nazionale, l'Agenzia per la rappresentanza negoziale delle Pubbliche
							Amministrazioni (ARAN) e le Agenzie di cui al decreto legislativo 30 luglio 1999, n. 300
							nonché, fino alla revisione organica della disciplina di settore, il CONI.
						</li>

						<li>
							<b>
								Ai fini della riserva di posti prevista dal bando di concorso, il requisito
								dell'anzianità di servizio effettivo di almeno cinque anni può essere soddisfatto
								cumulando contratti a tempo determinato anche non continuativi? </b
							><br />
							Sì, è possibile, purché alla data di scadenza del termine per la presentazione della domanda
							il candidato risulti dipendente di una delle amministrazioni pubbliche di cui all’articolo
							1, comma 2, del decreto legislativo 30 marzo 2001, n. 165, in possesso dei titoli di studio
							previsti per l’accesso alla carriera dei Segretari comunali e provinciali e con un’anzianità
							di servizio di almeno cinque anni in posizioni funzionali per l’accesso alle quali è
							previsto il possesso dei medesimi titoli di studio”.
						</li>
					</ul>
				</h4>
			</div>
		</card>
	</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner';
import { BaseAlert } from '@/components';
import logo from './../assets/img/albo-nazionale-segretari.svg';

/* import validator from 'validator'; */
export default {
	components: {
		BaseAlert,
		VueSimpleSpinner
	},
	data() {
		return {
			token: null,
			type: ['primary', 'info', 'success', 'warning', 'danger'],
			notifications: {
				topCenter: false
			},
			logo
		};
	},
	computed: {
		validEmail() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.setForm.email);
		},
		validInput() {
			return this.validEmail && this.setForm.repeatEmail == this.setForm.email;
		}
	},
	methods: {
		async setPassword() {
			const auth = this.setForm;
			auth.token = this.token;
			auth.app = this.app.code;
			auth.token = await this.$store.dispatch('recoveryPassword', auth);
		}
	},

	async created() {
		this.app = this.$store.getters.app;
		this.token = this.$route.query.token;

		this.app = this.$store.getters.app;
	}
};
</script>
<style></style>
