<template>
	<div class="row">
		<div class="card col-12">
			<card type="tasks" :header-classes="{ 'text-right': false }">
				<template slot="header">
					<tab :title="''" >
						<base-dropdown
							menu-on-right=""
							tag="div"
							title-classes="btn btn-link btn-icon"
							aria-label="Settings menu"
							:class="{ 'float-left': false }"
						>
							<i
								slot="title"
								class="tim-icons icon-settings-gear-63"
							></i>
							<a class="dropdown-item" href="#pablo">{{
								$t('dashboard.dropdown.action')
							}}</a>
							<a class="dropdown-item" href="#pablo">{{
								$t('dashboard.dropdown.anotherAction')
							}}</a>
							<a class="dropdown-item" href="#pablo">{{
								$t('dashboard.dropdown.somethingElse')
							}}</a>
						</base-dropdown>	</tab
					>
					<tabs class="title d-inline" transitionName="fade">
						<tab :title="'Servizi'" class="title d-inline">
							<service-table></service-table>
						</tab>
						<tab :title="'Aggiungi'">
							<service-form :model="model"> </service-form>
						</tab>
					</tabs>
				</template>
			</card>
		</div>
	</div>
</template>
<script>
import ServiceTable from './admin/ServiceTable.vue';
import ServiceForm from './admin/ServiceForm';

export default {
	components: { ServiceForm, ServiceTable },
	data() {
		return {
			model: {
				ente: '',
				email: '',
				referente: '',
				note: '',
				slug: ''
			}
		};
	}
};
</script>
<style></style>
