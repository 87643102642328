<template>
	<div>
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<app-card v-for="app in apps" :key="app.slug + app.code" :app="app" class="card"></app-card>
	</div>
</template>
<script>
import AppCard from './../layout/app/AppCard.vue';
import logo from './../assets/img/albo-nazionale-segretari.svg';

export default {
	components: { AppCard },
	data() {
		return { logo };
	},
	computed: {
		apps() {
			return this.$store.getters.service.apps;
		}
	},
	created() {}
};
</script>
<style>
.logo {
	padding-left: 5px;
	margin: 0 auto;
	display: block;
}
.logoContainer {
	display: flex;
	align-items: center;
}
</style>
