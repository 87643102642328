<template>
	<div>
		<!-- 		<vue-simple-spinner class="spinnerCenter" size="70" line-size="5" message="Caricamento..."></vue-simple-spinner>
 -->
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<card>
			<template>
				<b>Ministero dell'Interno</b>
				<h4 class="card-title">Accesso admin</h4>

				<div class="row card-body">
					<b
						><h5 class="card-subtitle">
							<a style="cursor: pointer;" @click="recuperoPassword()"
								><b style="text-decoration:underline">Configura o recupera password di accesso</b></a
							>
						</h5>
					</b>
				</div>

				<div class="row">
					<base-input
						@change="validate()"
						:class="{ 'col-md-4': true }"
						type="email"
						name="email"
						v-model="signinForm.username"
						placeholder="Email"
					>
					</base-input>
				</div>

				<div class="row">
					<base-input
						type="password"
						name="password"
						v-model="signinForm.password"
						placeholder="password"
						:class="{
							'col-md-4': true,
							'has-danger': false
						}"
					>
					</base-input>
				</div>

				<br />

				<base-button :class="{ disabled: !validInput }" native-type="submit" type="primary" @click="signin()"
					>Accedi</base-button
				>
			</template>
		</card>
	</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner';
import { BaseAlert } from '@/components';
import logo from './../assets/img/albo-nazionale-segretari.svg';

export default {
	components: {
		BaseAlert,
		VueSimpleSpinner
	},
	data() {
		return {
			token: null,
			type: ['primary', 'info', 'success', 'warning', 'danger'],
			notifications: {
				topCenter: false
			},
			logo,
			signinForm: {
				username: '',
				password: '',
				privacy: false,
				accessMethod: ''
			}
		};
	},
	computed: {
		validEmail() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.signinForm.username);
		},
		validInput() {
			return this.validEmail ? this.signinForm.password != '' : false;
		},
		service() {
			return this.$store.getters.service;
		}
	},
	methods: {
		async signin() {
			if (this.validEmail && this.validInput) {
				const auth = this.signinForm;
				auth.accessMethod = this.app.accessMethod;
				auth.appCode = this.app.code;
				await this.$store.dispatch('adminSignin', auth);
			}
		},
		recuperoPassword() {
			this.$router.push({ name: 'recoveryPassword', params: { app: this.app.code } });
		},
		validate() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.signinForm.username);
		}
	},

	async created() {
		this.app = this.$store.getters.app;
		if (this.$store.getters.user) {
			this.$router.replace({ name: 'app', params: { app: this.app.code } });
		}
		this.token = this.$route.query.code;
		this.app = this.$store.getters.app;

		if (this.token) {
			this.$dialog
				.confirm('Conferma per accedere')
				.then(async () => {
					await this.$store.dispatch('sendRequestToken', this.token);
				})
				.catch(e => {
					console.log(e);
				});

			/* this.$router.replace('home'); */
		}
	}
};
</script>
<style>
ul {
	/* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}
li {
	/* Remove bullets */
	padding: 3px; /* Remove padding */
	margin: 0; /* Remove margins */
}
.logo {
	padding-left: 5px;
	margin: 0 auto;
	display: block;
}
.logoContainer {
	display: flex;
	align-items: center;
}
.page {
	width: 21cm;
	min-height: 29.7cm;
	padding: 2cm;
	margin: 1cm auto;
	border: 1px #d3d3d3 solid;
	border-radius: 5px;
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
