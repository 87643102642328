<template>
	<div>
		<!-- 		<vue-simple-spinner class="spinnerCenter" size="70" line-size="5" message="Caricamento..."></vue-simple-spinner>
 -->
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<card v-if="token">
			<b
				>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
				{{ app.sessione.toUpperCase() }}</b
			>
			<h4 class="card-title">{{ app.title }}</h4>
			<b><h4 class="card-title">Configura Password</h4></b>
			<b
				><h5 class="card-subtitle">
					La password deve essere lunga almeno 8 caratteri e contenere: Un carattere maiuscolo, un carattere
					minuscolo, un carattere numerico e un carattere speciale
				</h5>
			</b>
			<div class="card-body">
				<small slot="helperText" class="form-text" :style="{ color: validPassword ? 'blue' : 'red' }">{{
					validPassword ? 'La password è sicura' : 'La password non è sicura'
				}}</small>
				<div class="row">
					<base-input
						:class="{ 'col-md-4': true, 'has-danger': !validPassword }"
						type="password"
						name="password"
						v-model="setForm.password"
						placeholder="Password"
					>
					</base-input>
				</div>
				<small
					slot="helperText"
					class="form-text"
					:style="{ color: setForm.password == setForm.repeatPassword ? 'blue' : 'red' }"
					>{{
						setForm.password == setForm.repeatPassword
							? 'Le password coincidono'
							: 'Le password non coincidono'
					}}</small
				>
				<div class="row">
					<base-input
						type="password"
						name="repeatPassword"
						v-model="setForm.repeatPassword"
						placeholder="Ripeti password"
						:class="{ 'col-md-4': true, 'has-danger': !setForm.password == setForm.repeatPassword }"
					>
					</base-input>
				</div>

				<base-button
					:class="{ disabled: !validInput }"
					native-type="submit"
					type="primary"
					@click="setPassword()"
					>Salva</base-button
				>
			</div>
		</card>
	</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner';
import { BaseAlert } from '@/components';
import logo from './../assets/img/albo-nazionale-segretari.svg';

/* import validator from 'validator'; */
export default {
	components: {
		BaseAlert,
		VueSimpleSpinner
	},
	data() {
		return {
			token: null,
			type: ['primary', 'info', 'success', 'warning', 'danger'],
			notifications: {
				topCenter: false
			},
			logo,
			setForm: {
				password: '',
				repeatPassword: ''
			}
		};
	},
	computed: {
		validPassword() {
			let rulesPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
			return rulesPassword.test(this.setForm.password);
		},
		validInput() {
			return this.validPassword && this.setForm.repeatPassword == this.setForm.password;
		}
	},
	methods: {
		async setPassword() {
			const auth = this.setForm;
			auth.token = this.token;
			auth.app = this.app.code;
			auth.token = await this.$store.dispatch('setPassword', auth);
		}
	},

	async created() {
		this.app = this.$store.getters.app;
		this.token = this.$route.query.token;
		if (!this.token) {
			this.$router.replace({ name: 'app', params: { app: this.app.code } });
		}
		this.app = this.$store.getters.app;
		if (this.$store.getters.user) {
			this.$router.replace({ name: 'app', params: { app: this.app.code } });
		}
	}
};
</script>
<style></style>
