<template>
	<div>
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<b-tabs content-class="card" class="card" v-if="app.code != 'coa2021'">
			<!-- 	<base-button @click="aggiornaApp" size="sm" type="info">Aggiorna App</base-button> -->
			<b-tab title="Gestisci App" class="col-md-12">
				<form>
					<br />
					<div class="form-row">
						<base-input
							:class="{ 'col-md-11': true }"
							v-model="app.linkZoom"
							label="Link Zoom"
							placeholder="Link Zoom"
						/>
						<div class="col-md-2">
							<label>Pubblico</label>&emsp;
							<toggle-button color="#419FC7" v-model="app.public" :labels="true" id="pubblico" />
						</div>
						<div class="col-md-2">
							<label>Accesso</label>&emsp;
							<toggle-button color="#419FC7" v-model="app.access" :labels="true" id="accesso" />
						</div>
						<div class="col-md-4">
							<label>Consenti download attestati ultimo modulo</label>&emsp;
							<toggle-button color="#419FC7" v-model="app.lastModule" :labels="true" id="lastModule" />
						</div>
					</div>
					<br />

					<p>Sono presenti {{ app.lezioni.length }} lezioni</p>
					<base-button @click="openLesson = !openLesson" type="primary" size="sm"
						>Apri/Chiudi Lezioni</base-button
					><base-button @click="addLesson = !addLesson" type="primary" size="sm"
						>Aggiungi lezione</base-button
					>
					<base-button type="primary" size="sm" @click="aggiornaApp">Aggiorna app</base-button>
					<div v-if="addLesson">
						<div class="form-row">
							<div class="col-md-1">
								<p class="mt-4">Lezione {{ app.lezioni.length + 1 }}</p>
							</div>
							<div class="col-md-3">
								<base-input
									label="Data"
									v-model="draftLesson.data"
									placeholder="data lezione es: 15/10/2021"
								/>
							</div>
							<div class="col-md-3">
								<base-input
									label="Modulo"
									v-model="draftLesson.modulo"
									placeholder="numero modulo es:1"
								/>
							</div>
							<div class="col-md-3">
								<base-input
									label="Durata"
									v-model="draftLesson.duration"
									placeholder="durata in ore es: 6"
								/>
							</div>
						</div>
					</div>
					<div v-if="openLesson">
						<div class="form-row" v-for="(lezione, index) in app.lezioni" :key="index">
							<div class="col-md-1">
								<p class="mt-4">Lezione {{ app.lezioni.length - index }}</p>
							</div>

							<div class="col-md-3">
								<base-input
									label="Data"
									v-model="app.lezioni[app.lezioni.length - (index + 1)].data"
									placeholder="data lezione es: 15/10/2021"
									disabled
								/>
							</div>
							<div class="col-md-3">
								<base-input
									label="Modulo"
									v-model="app.lezioni[app.lezioni.length - (index + 1)].modulo"
									placeholder="numero modulo es:1"
									disabled
								/>
							</div>
							<div class="col-md-3">
								<base-input
									label="Durata"
									v-model="app.lezioni[app.lezioni.length - (index + 1)].duration"
									placeholder="durata in ore es: 6"
									disabled
								/>
							</div>
						</div>
					</div>
				</form>
			</b-tab>
			<b-tab title="Utenti">
				<br />
				<div class="row">
					<div class="col-md-4 col-sm-4 col-6 ml-4">
						<base-input label="Seleziona Lezione">
							<select
								v-model="selectedDay"
								@change="changeSelectedDay"
								class="form-control"
								id="exampleFormControlSelect1"
							>
								<option>Seleziona tutti</option>
								<option v-for="presenza in presenze" v-bind:key="presenza.data">
									{{ presenza.data }}
								</option>
							</select>
						</base-input>
					</div>

					<div class="col-md-2 col-sm-2 col-6 mt-4">
						<base-button @click="aggiornaUtenti" size="sm" type="primary">Aggiorna Elenco</base-button>
					</div>
					<div class="col-md-2 col-sm-2 col-6 mt-4">
						<!-- 						<base-button
							@click="presenzeDashboard.opened = !presenzeDashboard.opened"
							size="sm"
							type="primary"
							>Invia Email</base-button
						> -->
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<ol v-if="openedUser" style="height: 250px">
							<b>
								{{ openedUser.nome }}
								{{ openedUser.nome }}
							</b>
							<div style="height: 225px; overflow: auto">
								<li v-for="(presenza, index) in openedUser.presenze" :key="index">
									<p>{{ presenza.data }} - {{ presenza.minuti }} minuti</p>
									<base-button @click="eliminaPresenza(presenza)" size="sm" type="info"
										>Elimina</base-button
									>
								</li>
							</div>
						</ol>
					</div>
				</div>
				<card type="tasks" :header-classes="{ 'text-right': false }">
					<template slot="header">
						<div v-if="presenzeDashboard.opened">
							<base-input v-model="email.subject" type="text" class="col-md-12" placeholder="Oggetto">
							</base-input>
							<base-input label="Email" class="col-md-12">
								<textarea v-model="email.body" class="form-control" rows="1"></textarea>
							</base-input>
						</div>
						1Utenti Selezionati: {{ filteredUsers.length }}
						<div class="col-md-12 col-12">
							<vtable
								@on-row-dblclick="onRowDoubleClick"
								@on-selected-rows-change="selectionChanged"
								:columns="columns"
								:rows="filteredUsers"
								:line-numbers="true"
								:row-style-class="'rowClass'"
								:select-options="{
									selectOnCheckboxOnly: true,
									enabled: true,
									selectionInfoClass: 'custom-class',
									selectionText: 'rows selected',
									clearSelectionText: 'clear',
									disableSelectInfo: false, // disable the select info panel on top
									selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
								}"
								:search-options="{ enabled: true }"
							>
								<div slot="selected-row-actions">
									<!-- <base-button @click="togglePresenzeDashboard" size="sm" type="info"
										>Open Dialog</base-button
									> -->

									<base-button @click="settaPresenza" size="sm" type="info"
										>Setta presenza</base-button
									>
									<base-button @click="updateRegistro" size="sm" type="info"
										>Modifica registro</base-button
									>&emsp;
									<!-- <base-button @click="invia" size="sm" type="info">Invia Email</base-button>&emsp;
									<base-button @click="sign" size="sm" type="info">Invia reset Email</base-button> -->
								</div>
								&emsp; >
								<div slot="selected-row-actions"></div>
								&emsp; >

								<template slot="table-row" slot-scope="props">
									<span v-if="props.column.field == 'lastAccess'">
										<span>{{ convertDate(props.row.lastAccess) }}</span> </span
									><span v-else-if="props.column.field == 'nome'">
										<span>{{ props.row.cognome }} {{ props.row.nome }}</span> </span
									><span v-else-if="props.column.field == 'minuti'">
										<span>{{ findMinutes(props.row.data) }} </span>
									</span>
									<span v-else>
										{{ props.formattedRow[props.column.field] }}
									</span>
								</template>
							</vtable>
						</div>
					</template>
				</card>
			</b-tab>
		</b-tabs>
		<b-tabs content-class="card" class="card" v-if="app.code == 'coa2021'">
			<!-- 	<base-button @click="aggiornaApp" size="sm" type="info">Aggiorna App</base-button> -->

			<b-tab title="Gestisci App" class="col-md-12">
				<form>
					<base-button type="primary" size="sm" @click="aggiornaApp">Aggiorna app</base-button>
				</form>
			</b-tab>
			<b-tab title="Utenti">
				<card type="tasks" :header-classes="{ 'text-right': false }">
					<template slot="header">
						<div class="col-md-12">
							<vtable
								:columns="columnsCoa2021"
								:rows="users"
								:line-numbers="true"
								:pagination-options="{ enabled: true, perPage: 7 }"
								compactMode
								@on-row-dblclick="fetchInfoType"
							>
								<template slot="table-row" slot-scope="props">
									<span v-if="props.column.field == 'status' && props.row.disabilita">
										{{ props.formattedRow[props.column.field] }}
										<a @click="downloadAttachment(props.row.spid)" style="cursor: pointer"
											>Scarica Allegato
										</a>
									</span>
									<span v-if="props.column.field == 'domanda'">
										<a @click="downloadDomanda(props.row.spid)" style="cursor: pointer"
											>Scarica Domanda
										</a>
									</span>
									<span
										v-if="
											props.column.field == 'time_upload_titoli' &&
											props.formattedRow[props.column.field] != '-'
										"
									>
										{{ new Date(props.formattedRow[props.column.field]).toLocaleString() }}
										<a @click="downloadTitoli(props.row.spid)" style="cursor: pointer">
											<br />
											Scarica Titoli
										</a>
									</span>
									<span
										v-if="
											props.column.field == 'time_upload_acceptance' &&
											props.formattedRow[props.column.field] != '-'
										"
									>
										{{ new Date(props.formattedRow[props.column.field]).toLocaleString() }}
										<a @click="downloadAccettazione(props.row.spid)" style="cursor: pointer">
											<br />
											Scarica Partecipazione
										</a>
									</span>
									<span v-else> {{ props.formattedRow[props.column.field] }} </span>
								</template>
							</vtable>
						</div>
					</template>
				</card>
			</b-tab>
			<b-tab active title="Comunicazioni" class="col-md-12">
				<card type="tasks" :header-classes="{ 'text-right': false }">
					<template slot="header">
						<div class="panel">
							<base-input
								class="col-md-12 mb-3"
								v-model="comunication.subject"
								label="Oggetto comunicazione"
								type="text"
							></base-input>

							<base-input label="Testo comunicazione" class="col-md-12 mb-3">
								<textarea class="form-control" v-model="comunication.text" rows="5"></textarea>
							</base-input>
							<br />
							<label><b> Allegato</b></label>
							<form @submit.prevent="onSubmit" enctype="multipart/form-data">
								<input
									class="form-control my-2"
									type="file"
									ref="file"
									@change="onSelect"
									accept="application/pdf"
								/>
							</form>
							<div class="row">
								<base-button class="block m-3" @click="createComunication" size="sm" type="primary"
									>Crea Comunicazione
								</base-button>
							</div>
						</div>
						<div class="col-md-12 col-12"></div>
					</template>
				</card>
				<ul v-if="comunications.length">
					<h4 style="margin-left: 10px">Comunicazioni</h4>
					<li v-for="comunication in comunications" v-model="comunication.id">
						<h4>
							<small> {{ formatDate(new Date(comunication.createdAt)) }}</small> <br />
							<b>{{ comunication.oggetto }} </b>
						</h4>
						<p v-html="comunication.testo.replace('\n', '<br />')"></p>

						<span style="text-align: left">
							<a
								class="btn btn-info btn-sm"
								@click="downloadAllegatoComunicazione(comunication.id)"
								style="color: white"
								>Scarica Allegato</a
							>
						</span>
						<hr />
						<br />
					</li>
				</ul>
			</b-tab>
			<b-tab title="Invio Email" class="col-md-12">
				<card type="tasks" :header-classes="{ 'text-right': false }">
					<template slot="header">
						<base-button
							class="col-md-11 block m-3"
							@click="presenzeDashboard.opened = !presenzeDashboard.opened"
							size="sm"
							type="primary"
							>Invia Email
						</base-button>
						<div v-if="presenzeDashboard.opened">
							<base-input
								v-model="email.subject"
								type="text"
								class="col-md-12"
								placeholder="Oggetto"
							></base-input>
							<base-input label="Email" class="col-md-12">
								<textarea v-model="email.body" class="form-control" rows="1"></textarea>
							</base-input>
						</div>
						Utenti Selezionati: {{ filteredUsers.length }}
						<div class="col-md-12 col-12">
							<vtable
								@on-row-dblclick="onRowDoubleClick"
								@on-selected-rows-change="selectionChanged"
								:columns="columnsCoa2021"
								:rows="filteredUsers"
								:line-numbers="true"
								:select-options="{
									selectOnCheckboxOnly: true,
									enabled: true,
									selectionInfoClass: 'custom-class',
									selectionText: 'rows selected',
									clearSelectionText: 'clear',
									disableSelectInfo: false, // disable the select info panel on top
									selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
								}"
							>
								<div slot="selectmethodsed-row-actions">
									<base-button @click="settaPresenza" size="sm" type="info"
										>Setta presenza</base-button
									>
									<base-button @click="invia" size="sm" type="info">Invia Email</base-button>&emsp;
									<base-button @click="sign" size="sm" type="info">Invia reset Email</base-button>
								</div>
								&emsp; >
								<div slot="selected-row-actions"></div>
								&emsp; >

								<template slot="table-row" slot-scope="props">
									<span v-if="props.column.field == 'lastAccess'">
										<span>{{ convertDate(props.row.lastAccess) }}</span> </span
									><span v-else-if="props.column.field == 'nome'">
										<span>{{ props.row.cognome }} {{ props.row.nome }}</span> </span
									><span v-else-if="props.column.field == 'minuti'">
										<span>{{ findMinutes(props.row.data) }} </span>
									</span>
									<span v-else>
										{{ props.formattedRow[props.column.field] }}
									</span>
								</template>
							</vtable>
						</div>
					</template>
				</card>
			</b-tab>
		</b-tabs>
	</div>
</template>
<script>
import axios from 'axios';
import UserForm from './admin/UserForm';
import ServiceForm from './admin/ServiceForm';
import logo from './../assets/img/albo-nazionale-segretari.svg';

export default {
	components: {
		UserForm,
		ServiceForm,
	},
	data() {
		return {
			email: { subject: '', body: '' },
			selectedUsers: [],
			filteredUsers: [],
			sentEmail: [],
			comunication: { subject: '', text: '', hasFile: false },
			logo,
			selectedDay: '',
			appIsChanged: false,
			disabledform: true,
			openLesson: false,
			addLesson: false,
			draftLesson: { data: '', modulo: '', duration: '' },
			presenzeDashboard: {
				opened: false,
			},
			openedUser: null,
			file: '',
			columns: [
				{ label: 'Id', field: 'id', hidden: true },
				{ label: 'Permessi', field: 'type', hidden: true },
				{ label: 'Servizio', field: 'serviceId', hidden: true },

				{
					label: 'Nome',
					field: 'nome',
				},

				{
					label: 'Email',
					field: 'email',
				},
				{
					label: 'Ultimo accesso',
					field: 'lastAccess',
				},
				{
					label: 'minuti',
					field: 'minuti',
				},
			],
			columnsCoa2021: [
				{ label: 'Id', field: 'id', hidden: true },
				{ label: 'User id', field: 'user_id', hidden: true },
				{ label: 'Allegato', field: 'disabilita', hidden: true },
				{
					label: 'Stato',
					field: 'status',
					width: '120px',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						filterDropdownItems: ['Salvata', 'Inviata'], // dropdown (with selected values) instead of text input
						trigger: 'enter', //only trigger on enter not on keyup
					},
				},
				{
					label: 'Codice SPID',
					field: 'spid',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'SPID', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						filterDropdownItems: [], // dropdown (with selected values) instead of text input
						trigger: 'enter', //only trigger on enter not on keyup
						filterFn: this.filterFn, //custom filter function that
					},
				},
				{
					label: 'Cognome',
					field: 'cognome',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Cognome', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						filterDropdownItems: [], // dropdown (with selected values) instead of text input
						trigger: 'enter', //only trigger on enter not on keyup
						filterFn: this.filterFn, //custom filter function that
					},
				},
				{
					label: 'Nome',
					field: 'nome',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Nome', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						filterDropdownItems: [], // dropdown (with selected values) instead of text input
						trigger: 'enter', //only trigger on enter not on keyup
					},
				},
				{
					label: 'Codice Fiscale',
					field: 'codiceFiscale',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Codice Fiscale', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						filterDropdownItems: [], // dropdown (with selected values) instead of text input
						trigger: 'enter', //only trigger on enter not on keyup
					},
				},
				/* {
					label: 'Pec',
					field: 'pec',
					filterOptions: {
						styleClass: 'class1', // class to be added to the parent th element
						enabled: true, // enable filter for this column
						placeholder: 'Pec', // placeholder for filter input
						filterValue: '', // initial populated value for this filter
						filterDropdownItems: [], // dropdown (with selected values) instead of text input
						trigger: 'enter', //only trigger on enter not on keyup
						filterFn: this.filterFn //custom filter function that
					}
				}, */
				{
					label: '',
					field: 'domanda',
				},
				{
					label: 'Titoli di preferenza',
					field: 'time_upload_titoli',
				},
				{
					label: 'Particepazione',
					field: 'time_upload_acceptance',
				},
			],
		};
	},

	methods: {
		onSelect() {
			const file = this.$refs.file.files[0];
			this.file = file;
		},

		async createComunication() {
			if (this.comunication.subject == '') {
				alert('Oggetto comunicazione obbligatorio');
				return;
			}

			this.comunication.file = this.file;
			let idComunication = await this.$store.dispatch('storeComunication', this.comunication);
			if (this.file) {
				const formData = new FormData();
				formData.append('file', this.file);
				formData.append('idComunication', idComunication);
				let that = this;
				axios
					.post('/uploadComunicationAttachment', formData)
					.then((res) => {
						this.$store.dispatch('refreshPage');
					})
					.catch(function () {});
				let code = await this.$store.dispatch('uploadAllegatoComunicazione', formData);

				that.$router.go();
				this.$router.go();
				location.reload();
			}
		},
		filterFn: function (data, filterString) {
			return data.toUpperCase().includes(filterString.toUpperCase());
		},
		downloadDomanda: async function (spid) {
			await this.$store.dispatch('downloadDomandaManager', spid);
		},
		generateDomanda: function (filename, text) {
			let element = document.createElement('a');
			element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
			element.setAttribute('download', filename);
			element.style.display = 'none';
			document.body.appendChild(element);
			element.click();
			document.body.removeChild(element);
		},

		async downloadAttachment(spidCode) {
			await this.$store.dispatch('downloadAttachment', spidCode);
		},

		async downloadTitoli(spidCode) {
			await this.$store.dispatch('downloadTitoli', spidCode);
		},
		async downloadAccettazione(spidCode) {
			await this.$store.dispatch('downloadAccettazione', spidCode);
		},

		async fetchInfoType(params) {
			let options = {
				html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
				loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
				reverse: false, // switch the button positions (left to right, and vise versa)
				okText: 'Chiudi',
				cancelText: 'Close',
				animation: 'zoom', // Available: "zoom", "bounce", "fade"
				type: 'basic', // coming soon: 'soft', 'hard'
				verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
				verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
				clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
				backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
				customClass: '', // Custom class to be injected into the parent node for the current dialog instance
			};

			await this.$store.dispatch('getType', {
				id: params.row.id,
				user_id: params.row.user_id,
			});
			const type = this.$store.getters.shownType;
			let stringData = '';
			if (type.model != null) {
				const updatedData = JSON.parse(type.model);
				const form = updatedData.form;
				const keys = Object.keys(form);
				keys.forEach((value) => {
					console.log(value);
					if (value == 'repeatPec' || value == 'repeatEmail') {
						return;
					}
					if (form[value].length) {
						stringData = stringData + value + ': <b>' + form[value] + '</b> &emsp;';
					}
				});
				let codice = 'A7' + params.row.spid.substr(params.row.spid.length - 5);
				this.$dialog
					.alert(
						{
							title: `Domanda di partecipazione`,
							body: `<div class="type-container">Dati Aggiornati<br>${stringData} <hr><br>Stato Domanda: <b>${type.status}</b> &emsp; Data invio: <b>${type.invio}</b> &emsp; Codice Domanda: <b>${codice}</b><br><br>DATI ANAGRAFICI<br>${type.anag}<hr><br>
          DICHIARAZIONI<br> ${type.text}</div>`,
						},
						options
					)
					.then(async (dialog) => {})
					.catch(() => {});
			} else {
				let codice = 'A7' + params.row.spid.substr(params.row.spid.length - 5);
				this.$dialog
					.alert(
						{
							title: `Domanda di partecipazione`,
							body: `<div class="type-container">Stato Domanda: <b>${type.status}</b> &emsp; Data invio: <b>${type.invio}</b> &emsp; Codice Domanda: <b>${codice}</b><br><br>DATI ANAGRAFICI<br>${type.anag}<hr><br>
          DICHIARAZIONI<br> ${type.text}</div>`,
						},
						options
					)
					.then(async (dialog) => {})
					.catch(() => {});
			}
		},
		async aggiornaApp() {
			let data = {};
			data.app = this.app;
			data.draftLesson = this.draftLesson;
			let status = await this.$store.dispatch('aggiornaApp', data);
			this.addLesson = false;
		},
		changeSelectedDay() {
			if (!(this.selectedDay == 'Seleziona tutti')) {
				let selectedUsers = [];
				this.users.forEach((user) => {
					if (user.presenze) {
						let presenza = user.presenze.find((presenza) => presenza.data == this.selectedDay);
						if (presenza) {
							selectedUsers.push(user);
						}
					}
				});
				this.filteredUsers = selectedUsers;
			} else {
				this.filteredUsers = this.users;
			}
		},
		async aggiornaUtenti() {
			await this.$store.dispatch('getAllUsers', this.$route.params.app);

			if (!(this.selectedDay == 'Seleziona tutti')) {
				let selectedUsers = [];
				this.users.forEach((user) => {
					if (user.presenze) {
						let presenza = user.presenze.find((presenza) => presenza.data == this.selectedDay);
						if (presenza) {
							selectedUsers.push(user);
						}
					}
				});
				this.filteredUsers = selectedUsers;
			} else {
				this.filteredUsers = this.users;
			}
		},
		findMinutes(data) {
			if (!(this.selectedDay == 'Seleziona tutti')) {
				let lezioni = JSON.parse(data);
				if (data) {
					const lezione = lezioni.find((lezione) => lezione.data == this.selectedDay);
					if (lezioni) {
						if (lezione.minuti) {
							return lezione.minuti;
						} else {
							return '';
						}
					} else {
						return '';
					}
				}
			} else {
				return '';
			}
		},
		appIsEqual(newValue, oldValue) {
			let newApp = JSON.parse(JSON.stringify(newValue));
			let oldApp = JSON.parse(JSON.stringify(oldValue));
			let newProps = Object.getOwnPropertyNames(newApp);
			let oldProps = Object.getOwnPropertyNames(oldApp);

			if (newProps.length != oldProps.length) {
				this.appIsChanged = true;
			}

			for (let i = 0; i < newProps.length; i++) {
				let propName = newProps[i];
				if (Array.isArray(newApp[propName])) {
					for (let j = 0; j < newApp[propName].length; j++) {
						if (typeof newApp[propName][j] === 'object') {
							let object = JSON.parse(JSON.stringify(newApp[propName][j]));
							let objectProps = Object.getOwnPropertyNames(object);
							for (let k = 0; k < objectProps.length; k++) {
								let propObjectName = objectProps[k];

								if (newApp[propName][j][propObjectName] != newApp[propName][j][propObjectName]) {
									this.appIsChanged = true;
								}
							}
						} else {
						}
					}
				} else {
					if (newApp[propName] != oldApp[propName]) {
						this.appIsChanged = true;
					}
				}
			}
		},
		onRowDoubleClick(params) {
			this.$dialog
				.prompt(
					{
						title: 'Aggiungi minuti di assenza a questo Corsista',
						body: 'Assicurati di inserire il numero corretto di minuti',
					},
					{
						promptHelp: 'Inserire solo numeri e premi "[+:okText]"',
					}
				)
				.then(async (dialog) => {
					const data = {};
					data.minutes = dialog.data;
					data.row = params.row;
					data.date = this.selectedDay;
					await this.$store.dispatch('addMinutes', data);
					await this.$store.dispatch('getAllUsers', this.$route.params.app);
					this.changeSelectedDay();
					// Triggered when proceed button is clicked
					// Show an alert with the user's input as the message
				})
				.catch(() => {
					// Triggered when dialog is dismissed by user
				});

			// params.row - row object
			// params.pageIndex - index of this row on the current page.
			// params.selected - if selection is enabled this argument
			// indicates selected or not
			// params.event - click event
		},
		togglePresenzeDashboard() {
			return (this.presenzeDashboard.opened = !this.presenzeDashboard.opened);
		},
		formatDate(date) {
			let day = date.getDate();
			let month = date.getMonth();
			let year = date.getFullYear();
			return day + '/' + month + '/' + year;
		},
		settaPresenza() {
			this.$dialog
				.prompt(
					{
						title: 'Inserire data del corso',
						body: 'La data deve essere nel formato dd/mm/yyyy',
					},
					{
						promptHelp: 'dd/mm/yyyy "[+:okText]"',
					}
				)
				.then(async (dialog) => {
					const emails = this.selectedUsers.map((element) => element.email);
					const data = {};
					data.data = dialog.data;
					data.users = emails;
					const logs = await this.$store.dispatch('settaPresenza', data);

					/* await this.$store.dispatch('getAllUsers', this.$route.params.app); */
				})
				.catch(() => {});
		},
		updateRegistro() {
			if (this.selectedUsers.length == 1) {
				this.openedUser = this.selectedUsers[0];
			} else {
				alert('Selezionare solo un utente');
			}

			console.log('OK');
		},
		eliminaPresenza(presenza) {
			console.log(presenza);
		},
		invia() {
			if (confirm(`Sei sicuro di voler inviare ${this.selectedUsers.length} email`)) {
				const sendData = {};
				sendData.users = this.selectedUsers;
				sendData.users.forEach((user) => {
					user.app = this.$route.params.app;
					user.message = this.email;
					this.$store.dispatch('sendEmail', user);
				});
				this.email.subject = '';
				this.email.body = '';
			} else {
			}
		},
		convertDate(date) {
			const numberDate = parseInt(date);
			return new Date(parseInt(date)).toLocaleString('it-IT');
		},
		selectionChanged(params) {
			this.selectedUsers = params.selectedRows;
			if (!this.selectedUsers.length) {
				this.openedUser = null;
			}
		},
		sign() {
			if (confirm(`Sei sicuro di voler inviare ${this.selectedUsers.length} email`)) {
				const sendData = {};
				sendData.users = this.selectedUsers;
				sendData.app = this.$route.params.app;

				this.$store
					.dispatch('signUser', sendData)
					.then((res) => {})
					.catch((e) => {
						console.log(e);
					});
			} else {
			}
		},
	},

	computed: {
		service() {
			return this.$store.getters.service;
		},
		comunications() {
			return this.$store.getters.comunications;
		},
		users() {
			return this.$store.getters.users;
		},
		app() {
			return this.$store.getters.app;
		},
		lezioni() {
			console.log(this.app.lezioni);
		},
		presenze() {
			const certificati = this.app.lezioni;

			certificati.forEach((riga) => {
				riga.dataEn = riga.data.split('/')[1] + '/' + riga.data.split('/')[0] + '/' + riga.data.split('/')[2];
				riga.dataEn = new Date(riga.dataEn).getTime();
			});

			return certificati;
		},
	},
	async created() {
		await this.$store.dispatch('getAllUsers', this.$route.params.app);
	},
};
</script>
<style>
.val-input {
	width: 800px !important;
	height: 1200px;
}
.dg-main-content {
	max-width: 800px !important;
}
.type-container {
	width: 800px;
	height: 600px;
	overflow: auto;
}
.dg-container {
	height: 203% !important;
}
hr {
	margin-bottom: 0 !important;
}
</style>
