<template>
	<card v-if="app.public">
		<b>
			{{ app.label.toUpperCase() }}

			{{
				app.code == 'coa6'
					? 'CO.A6 SESSIONE ' + app.sessione.toUpperCase()
					: app.code == 'coa6-sa'
					? 'CO.A6-SA SESSIONE ' + app.sessione.toUpperCase()
					: 'COA2021'
			}}
			{{ app.sessione ? '' : '' + app.sessione.toUpperCase() }}
		</b>
		<h4 class="card-title">{{ app.title }}</h4>

		<p class="card-text">
			<b>
				Accesso:
				{{
					app.access
						? app.accessMethod + ' - Accesso consentito'
						: app.accessMethod + ' - Al momento non è possibile accedere'
				}}</b
			>
		</p>
		<button @click="openApp" class="btn btn-primary">Vai alla pagina del {{ app.label }}</button>
		<!-- 	<template v-slot:header v-if="publico">
			<b>{{ code }} </b> {{ openTime }} -
			{{ closeTime }}

			<h4 class="card-title">
				{{ title }}
				<div v-if="!isLoggedIn"></div>
				<div v-else><p>Login effettuato</p></div>
			</h4>

			<base-button @click="openApp" class="btn btn-primary">Apri</base-button>
		</template> -->
	</card>
</template>
<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import BaseButton from '../../components/BaseButton.vue';

export default {
	components: {
		FormWizard,
		TabContent,
		BaseButton
	},
	props: ['app'],
	data() {
		return {};
	},
	methods: {
		openApp() {
			//			this.$router.push({ name: this.app.code, params: { app: this.app } });
			this.$router.push({ name: 'app', params: { app: this.app.code } });
		}
	},
	computed: {
		code() {
			return this.app.code.toUpperCase();
		},
		intestazione() {
			let code = '';
			if (this.app.code == 'coa6') {
				code = 'CO.A6';
			}
			return code.toUpperCase();
		},
		codeLowerCase() {
			return this.app.code;
		},
		title() {
			return this.app.title;
		},
		openTime() {
			return this.app.openTime;
		},
		closeTime() {
			return this.app.closeTime;
		},
		publico() {
			return this.app.public;
		},

		isLoggedIn() {
			return this.user ? true : false;
		}
	},
	async created() {}
};
</script>
<style scoped>
ul {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}
</style>
