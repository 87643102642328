<template>
	<div>
		<!-- 		<vue-simple-spinner class="spinnerCenter" size="70" line-size="5" message="Caricamento..."></vue-simple-spinner>
 -->
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<card>
			<b
				>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
				{{ app.sessione.toUpperCase() }}</b
			>
			<h4 class="card-title">{{ app.title }}</h4>
			<div>
				<div class="row card-body">
					<b><h4 class="card-title">Recupera Password</h4></b>

					<b
						><h5 class="card-subtitle">
							Se è il primo accesso o se devi recuperare la password inserisci la tua email
						</h5>
					</b>
				</div>
			</div>

			<div class="form-text" :style="{ color: validEmail ? 'blue' : 'red' }">
				{{ validEmail ? 'Formato email corretto' : '' }}
			</div>
			<div class="row">
				<base-input
					:class="{ 'col-md-4': true, 'has-danger': !validEmail }"
					type="email"
					name="email"
					v-model="setForm.email"
					placeholder="Email"
				>
				</base-input>
			</div>
			<small class="form-text" :style="{ color: setForm.email == setForm.repeatEmail ? 'blue' : 'red' }">{{
				setForm.email == setForm.repeatEmail ? 'I campi coincidono' : 'I campi non coincidono'
			}}</small>
			<div class="row">
				<base-input
					type="email"
					name="repeatEmail"
					v-model="setForm.repeatEmail"
					placeholder="Ripeti Email"
					:class="{ 'col-md-4': true, 'has-danger': !setForm.email == setForm.repeatEmail }"
				>
				</base-input>
			</div>

			<base-button :class="{ disabled: !validInput }" native-type="submit" type="primary" @click="setPassword()"
				>Invia Email</base-button
			>
		</card>
	</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner';
import { BaseAlert } from '@/components';
import logo from './../assets/img/albo-nazionale-segretari.svg';

/* import validator from 'validator'; */
export default {
	components: {
		BaseAlert,
		VueSimpleSpinner
	},
	data() {
		return {
			token: null,
			type: ['primary', 'info', 'success', 'warning', 'danger'],
			notifications: {
				topCenter: false
			},
			logo,
			setForm: {
				email: '',
				repeatEmail: ''
			}
		};
	},
	computed: {
		validEmail() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.setForm.email);
		},
		validInput() {
			return this.validEmail && this.setForm.repeatEmail == this.setForm.email;
		}
	},
	methods: {
		async setPassword() {
			const auth = this.setForm;
			auth.token = this.token;
			auth.app = this.app.code;
			auth.token = await this.$store.dispatch('recoveryPassword', auth);
		}
	},

	async created() {
		this.app = this.$store.getters.app;
		this.token = this.$route.query.token;

		this.app = this.$store.getters.app;
		if (this.$store.getters.user) {
			this.$router.replace({ name: 'app', params: { app: this.app.code } });
		}
	}
};
</script>
<style></style>
