<template>
	<div>
		<form @submit.prevent="onSubmit" enctype="multipart/form-data">
			<input class="form-control my-2" type="file" ref="file" @change="onSelect" accept="application/pdf" />
			<div class="fields">
				<button class="btn btn-default">Carica</button>
			</div>
		</form>
	</div>
</template>
<script>
import axios from 'axios';
export default {
	name: 'FileUpload',
	props: ['userId', 'url'],
	data() {
		return { file: '', message: '' };
	},
	methods: {
		onSelect() {
			const file = this.$refs.file.files[0];
			this.file = file;
		},

		onSubmit() {
			const formData = new FormData();
			formData.append('userToken', this.userId);
			formData.append('file', this.file);
			axios
				.post(this.url, formData)
				.then((res) => {
					if (res.status == 200) {
						this.$router.go();
					}
				})
				.catch(function () {});
		},
	},
	created() {},
};
</script>
