<template>
	<div>
		<!-- 		<vue-simple-spinner class="spinnerCenter" size="70" line-size="5" message="Caricamento..."></vue-simple-spinner>
 -->
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<card v-if="app.code == 'coa6'">
			<b
				>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
				{{ app.sessione.toUpperCase() }}</b
			>
			<h4 class="card-title">{{ app.title }}</h4>
			<div class="card-body">
				<h4>
					INFORMATIVA AGLI INTERESSATI IN MATERIA DI PROTEZIONE DEI DATI PERSONALI EX ART. 13-14 REGOLAMENTO
					UE N. 679/2016 “REGOLAMENTO GENERALE SULLA PROTEZIONE DEI DATI”
				</h4>
				<p>
					Ai sensi degli art. 13-14 del Reg. UE/679/2016, di seguito viene riportata l’informativa riservata
					ai candidati partecipanti al “Sesto corso-concorso selettivo di formazione per il conseguimento
					dell’abilitazione richiesta ai fini dell’iscrizione nella fascia iniziale dell’Albo denominato “Co.A
					6”, che utilizzano gli strumenti ed i servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale.
				</p>
				<p>
					A tal fine, si fa presente che:
				</p>

				1. TITOLARE DEL TRATTAMENTO

				<p>
					In relazione all’utilizzo degli strumenti e dei servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale sono trattati dati
					relativi a persone identificate o identificabili. Il Titolare del trattamento dei dati personali è
					il Ministero dell’Interno, con sede in Piazza del Viminale n. 1, IT – 00184, Roma (numero centralino
					+39 06.4651).
				</p>
				<br />
				2. RESPONSABILE DEL TRATTAMENTO
				<p>
					In relazione all’utilizzo degli strumenti e dei servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale sono trattati dati
					relativi a persone identificate o identificabili. <br />Il Responsabile del trattamento dei dati
					personali è la società Scanshare S.r.l., con sede legale in C. da Cutura, 7 – 87036 Rende (CS) nella
					persona di Simona Zicarelli.
				</p>
				<br />
				3. RESPONSABILE DELLA PROTEZIONE DEI DATI
				<p>
					Il responsabile della protezione dei dati è la Sig.ra Irene Tropea con sede in C. da Cutura, 7 –
					87036 Rende (CS).
				</p>
				<br />
				4. FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO
				<p>
					I dati personali oggetto del trattamento sono: <br />a) dati necessari ai fini dell’identificazione
					degli utenti in occasione dell’accesso ai servizi delle piattaforme utilizzate per l’erogazione dei
					servizi (documento di identità, dati anagrafici e di contatto, indirizzo IP del proprio
					dispositivo);<br />
					b) dati relativi allo svolgimento della formazione a distanza (necessari alla verifica dei requisiti
					hardware e software dei discenti); <br />c) dati degli utenti acquisiti per le finalità di
					monitoraggio delle presenze e assistenza tecnica nell’ambito delle attività didattico-formative a
					distanza in modalità sincrona e della verifica finale;<br />
					d) altri dati comunicati o comunque resi disponibili dall’utente durante lo svolgimento delle
					lezioni da remoto. <br /><br />Le registrazioni video/audio delle attività didattico-formative a
					distanza in modalità sincrona riguardano i relatori. <br />Tali dati vengono trattati esclusivamente
					per il perseguimento delle finalità connesse allo svolgimento delle attività didattico-formative a
					distanza in modalità sincrona e della verifica finale, ex art. 6, comma 1, lett. e) del GDPR.<br />
					La base giuridica del trattamento è quindi l’esecuzione di compiti di interesse pubblico o comunque
					connessi all'esercizio di pubblici poteri di cui è investito il titolare del trattamento, ovvero lo
					svolgimento delle attività didattico-formative a distanza in modalità sincrona e della verifica
					finale. Data l’impossibilità di individuare modalità alternative, il conferimento dei dati è
					requisito necessario per poter accedere ai servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale; il mancato conferimento
					dei dati comporta l’impossibilità di accedere ai predetti servizi.
				</p>
				<br />
				5. MODALITÀTRATTAMENTO E CONSERVAZIONE DEI DATI
				<p>
					La raccolta dei dati avviene nel rispetto dei principi di pertinenza, completezza e non eccedenza in
					relazione ai fini per i quali sono trattati. I dati personali conferiti sono trattati in osservanza
					dei principi di liceità, correttezza e trasparenza, previsti dall’art. 5 del GDPR, anche con
					l’ausilio di strumenti informatici e telematici atti a memorizzare e gestire i dati stessi e,
					comunque, in modo tale da garantirne la sicurezza e tutelare la massima riservatezza
					dell’interessato. Tali dati sono conservati per il tempo strettamente necessario a conseguire le
					finalità per le quali gli stessi sono raccolti per la durata dei servizi affidati.
				</p>
				<br />
				6. COMUNICAZIONE DEI DATI A TERZI

				<p>
					I dati trattati non sono oggetto di trasferimento a paesi esteri o a organizzazioni internazionali.
					Il trasferimento dei dati personali può avvenire qualora risulti necessario per la gestione dei
					servizi affidati. Per il trattamento delle informazioni e dei dati che saranno eventualmente
					comunicati a questi soggetti, saranno richiesti gli equivalenti livelli di protezione adottati per
					il trattamento dei dati personali dei propri dipendenti. In ogni caso saranno comunicati i soli dati
					necessari al perseguimento degli scopi previsti e saranno applicati gli strumenti normativi previsti
					dal Capo V del GDPR.
				</p>
				<br />
				7. DIRITTI DELL’INTERESSATO
				<p>
					In ogni momento l’interessato potrà esercitare i suoi diritti nei confronti del Titolare del
					trattamento, ai sensi del Regolamento UE 679/2016. Ai sensi degli artt. da 15 a 22 del GDPR,
					l’interessato può esercitare, qualora ne ricorrano i presupposti, il diritto di chiedere al titolare
					del trattamento l'accesso ai dati personali che lo riguardano, la rettifica o la cancellazione degli
					stessi, la limitazione del trattamento, rivolgendosi al Ministero dell’Interno, con sede in Piazza
					del Viminale n. 1, IT – 00184, Roma (numero centralino +39 06.4651).
				</p>
				<br />
				8. DIRITTO DI PROPORRE RECLAMO A UN’AUTORITÀ DI CONTROLLO
				<p>
					L’interessato ha il diritto di proporre reclamo presso un’Autorità di controllo secondo quanto
					stabilito all'art. 77. del GDPR.
				</p>
			</div>
		</card>
		<card v-if="app.code == 'coa6-sa'">
			<b
				>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
				{{ app.sessione.toUpperCase() }}</b
			>
			<h4 class="card-title">{{ app.title }}</h4>
			<div class="card-body mx-4">
				<h4>
					INFORMATIVA AGLI INTERESSATI IN MATERIA DI PROTEZIONE DEI DATI PERSONALI EX ART. 13-14 REGOLAMENTO
					UE N. 679/2016 “REGOLAMENTO GENERALE SULLA PROTEZIONE DEI DATI”
				</h4>
				<p>
					Ai sensi degli art. 13-14 del Reg. UE/679/2016, di seguito viene riportata l’informativa riservata
					ai candidati partecipanti al “Sesto corso-concorso selettivo di formazione per il conseguimento
					dell’abilitazione richiesta ai fini dell’iscrizione nella fascia iniziale dell’Albo denominato “Co.A
					6”, che utilizzano gli strumenti ed i servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale.
				</p>
				<p>
					A tal fine, si fa presente che:
				</p>

				1. TITOLARE DEL TRATTAMENTO

				<p>
					In relazione all’utilizzo degli strumenti e dei servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale sono trattati dati
					relativi a persone identificate o identificabili. Il Titolare del trattamento dei dati personali è
					il Ministero dell’Interno, con sede in Piazza del Viminale n. 1, IT – 00184, Roma (numero centralino
					+39 06.4651).
				</p>
				<br />
				2. RESPONSABILE DEL TRATTAMENTO
				<p>
					In relazione all’utilizzo degli strumenti e dei servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale sono trattati dati
					relativi a persone identificate o identificabili. <br />Il Responsabile del trattamento dei dati
					personali è la società Scanshare S.r.l., con sede legale in C. da Cutura, 7 – 87036 Rende (CS) nella
					persona di Simona Zicarelli.
				</p>
				<br />
				3. RESPONSABILE DELLA PROTEZIONE DEI DATI
				<p>
					Il responsabile della protezione dei dati è la Sig.ra Irene Tropea con sede in C. da Cutura, 7 –
					87036 Rende (CS).
				</p>
				<br />
				4. FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO
				<p>
					I dati personali oggetto del trattamento sono: <br />a) dati necessari ai fini dell’identificazione
					degli utenti in occasione dell’accesso ai servizi delle piattaforme utilizzate per l’erogazione dei
					servizi (documento di identità, dati anagrafici e di contatto, indirizzo IP del proprio
					dispositivo);<br />
					b) dati relativi allo svolgimento della formazione a distanza (necessari alla verifica dei requisiti
					hardware e software dei discenti); <br />c) dati degli utenti acquisiti per le finalità di
					monitoraggio delle presenze e assistenza tecnica nell’ambito delle attività didattico-formative a
					distanza in modalità sincrona e della verifica finale;<br />
					d) altri dati comunicati o comunque resi disponibili dall’utente durante lo svolgimento delle
					lezioni da remoto. <br /><br />Le registrazioni video/audio delle attività didattico-formative a
					distanza in modalità sincrona riguardano i relatori. <br />Tali dati vengono trattati esclusivamente
					per il perseguimento delle finalità connesse allo svolgimento delle attività didattico-formative a
					distanza in modalità sincrona e della verifica finale, ex art. 6, comma 1, lett. e) del GDPR.<br />
					La base giuridica del trattamento è quindi l’esecuzione di compiti di interesse pubblico o comunque
					connessi all'esercizio di pubblici poteri di cui è investito il titolare del trattamento, ovvero lo
					svolgimento delle attività didattico-formative a distanza in modalità sincrona e della verifica
					finale. Data l’impossibilità di individuare modalità alternative, il conferimento dei dati è
					requisito necessario per poter accedere ai servizi telematici per lo svolgimento delle attività
					didattico-formative a distanza in modalità sincrona e della verifica finale; il mancato conferimento
					dei dati comporta l’impossibilità di accedere ai predetti servizi.
				</p>
				<br />
				5. MODALITÀTRATTAMENTO E CONSERVAZIONE DEI DATI
				<p>
					La raccolta dei dati avviene nel rispetto dei principi di pertinenza, completezza e non eccedenza in
					relazione ai fini per i quali sono trattati. I dati personali conferiti sono trattati in osservanza
					dei principi di liceità, correttezza e trasparenza, previsti dall’art. 5 del GDPR, anche con
					l’ausilio di strumenti informatici e telematici atti a memorizzare e gestire i dati stessi e,
					comunque, in modo tale da garantirne la sicurezza e tutelare la massima riservatezza
					dell’interessato. Tali dati sono conservati per il tempo strettamente necessario a conseguire le
					finalità per le quali gli stessi sono raccolti per la durata dei servizi affidati.
				</p>
				<br />
				6. COMUNICAZIONE DEI DATI A TERZI

				<p>
					I dati trattati non sono oggetto di trasferimento a paesi esteri o a organizzazioni internazionali.
					Il trasferimento dei dati personali può avvenire qualora risulti necessario per la gestione dei
					servizi affidati. Per il trattamento delle informazioni e dei dati che saranno eventualmente
					comunicati a questi soggetti, saranno richiesti gli equivalenti livelli di protezione adottati per
					il trattamento dei dati personali dei propri dipendenti. In ogni caso saranno comunicati i soli dati
					necessari al perseguimento degli scopi previsti e saranno applicati gli strumenti normativi previsti
					dal Capo V del GDPR.
				</p>
				<br />
				7. DIRITTI DELL’INTERESSATO
				<p>
					In ogni momento l’interessato potrà esercitare i suoi diritti nei confronti del Titolare del
					trattamento, ai sensi del Regolamento UE 679/2016. Ai sensi degli artt. da 15 a 22 del GDPR,
					l’interessato può esercitare, qualora ne ricorrano i presupposti, il diritto di chiedere al titolare
					del trattamento l'accesso ai dati personali che lo riguardano, la rettifica o la cancellazione degli
					stessi, la limitazione del trattamento, rivolgendosi al Ministero dell’Interno, con sede in Piazza
					del Viminale n. 1, IT – 00184, Roma (numero centralino +39 06.4651).
				</p>
				<br />
				8. DIRITTO DI PROPORRE RECLAMO A UN’AUTORITÀ DI CONTROLLO
				<p>
					L’interessato ha il diritto di proporre reclamo presso un’Autorità di controllo secondo quanto
					stabilito all'art. 77. del GDPR.
				</p>
			</div>
		</card>
		<card v-if="app.code == 'coa2021' && app.public">
			<h4 class="card-title">{{ app.title }}</h4>
			<div class="m-3 p-3">
				<h4>
					INFORMATIVA AGLI INTERESSATI IN MATERIA DI PROTEZIONE DEI DATI PERSONALI EX ART. 13-14 REGOLAMENTO
					UE N. 679/2016 “REGOLAMENTO GENERALE SULLA PROTEZIONE DEI DATI”.
				</h4>
				<p>
					Ai sensi degli art. 13-14 del Reg. UE/679/2016, di seguito viene riportata l’informativa riservata
					ai candidati partecipanti al
					<i
						>“Concorso pubblico, per esami, per l’ammissione di quattrocentoquarantotto borsisti al
						corso-concorso selettivo di formazione - edizione 2021 per il conseguimento dell’abilitazione
						richiesta ai fini dell’iscrizione trecentoquarantacinque segretari comunali nella fascia
						iniziale dell’Albo Nazionale dei segretari comunali e provinciali”</i
					>, che utilizzano gli strumenti ed i servizi telematici per l’acquisizione e la gestione delle
					domande di partecipazione alla procedura selettiva per l’accesso in carriera dei segretari comunali
					e provinciali e di ogni comunicazione concernente il concorso.
				</p>
			</div>
			<div class="m-3 p-3">
				<p>
					A tal fine, si fa presente che: <br />
					1. TITOLARE DEL TRATTAMENTO In relazione all’utilizzo degli strumenti e dei servizi telematici per
					l’acquisizione e la gestione delle domande di partecipazione dei candidati alla procedura selettiva
					per l’accesso in carriera dei segretari comunali e provinciali e di ogni comunicazione concernente
					il concorso sono trattati dati relativi a persone identificate o identificabili. Il Titolare del
					trattamento dei dati personali è il Ministero dell’Interno, con sede in Piazza del Viminale n. 1, IT
					– 00184, Roma (numero centralino +39 06.4651).
				</p>

				<p>
					2. RESPONSABILE DEL TRATTAMENTO In relazione all’utilizzo degli strumenti e dei servizi telematici
					per l’acquisizione e la gestione delle domande di partecipazione dei candidati alla procedura
					selettiva per l’accesso in carriera dei segretari comunali e provinciali e di ogni comunicazione
					concernente il concorso sono trattati dati relativi a persone identificate o identificabili. Il
					Responsabile del trattamento dei dati personali è la società Scanshare S.r.l., con sede legale in
					C.da Cutura, 7 – 87036 Rende (CS) nella persona di Simona Zicarelli.
				</p>
				<p>
					3. RESPONSABILE DELLA PROTEZIONE DEI DATI Il responsabile della protezione dei dati è la società
					Scanshare S.r.l., con sede legale in C.da Cutura, 7 – 87036 Rende (CS) nella persona di Irene
					Tropea.
				</p>
				<p>
					4. FINALITÀ E BASE GIURIDICA DEL TRATTAMENTO I dati personali oggetto del trattamento sono: a) dati
					necessari ai fini dell’identificazione dei candidati in occasione dell’accesso ai servizi della
					piattaforma utilizzata per l’erogazione dei servizi (dati anagrafici e di contatto, dati richiesti
					dal bando di concorso, indirizzo IP del proprio dispositivo); b) altri dati, anche di natura
					sensibile, comunicati o comunque resi disponibili dai candidati durante lo svolgimento del concorso.
					Tali dati vengono trattati esclusivamente per il perseguimento delle finalità connesse
					all’acquisizione e alla gestione delle domande di partecipazione dei candidati alla procedura
					selettiva per l’accesso in carriera dei segretari comunali e provinciali e di ogni comunicazione
					concernente il concorso, ex art. 6, comma 1, lett. e) del GDPR. La base giuridica del trattamento è
					quindi l’esecuzione di compiti di interesse pubblico o comunque connessi all'esercizio di pubblici
					poteri di cui è investito il titolare del trattamento. Data l’impossibilità di individuare modalità
					alternative, il conferimento dei dati è requisito necessario per poter accedere ai servizi
					informatici e telematici per l’acquisizione e la gestione delle domande di partecipazione dei
					candidati alla procedura selettiva per l’accesso in carriera dei segretari comunali e provinciali e
					di ogni comunicazione concernente il concorso; il mancato conferimento dei dati comporta
					l’impossibilità di accedere ai predetti servizi.
				</p>
				<p>
					5. MODALITÀ DEL TRATTAMENTO E CONSERVAZIONE DEI DATI La raccolta dei dati avviene nel rispetto dei
					principi di pertinenza, completezza e non eccedenza in relazione ai fini per i quali sono trattati.
					I dati personali conferiti sono trattati in osservanza dei principi di liceità, correttezza e
					trasparenza, previsti dall’art. 5 del GDPR, anche con l’ausilio di strumenti informatici e
					telematici atti a memorizzare e gestire i dati stessi e, comunque, in modo tale da garantirne la
					sicurezza e tutelare la massima riservatezza dell’interessato. Tali dati sono conservati per il
					tempo strettamente necessario a conseguire le finalità per le quali gli stessi sono e per la durata
					dei servizi affidati.
				</p>
				<p>
					6. COMUNICAZIONE DEI DATI A TERZI I dati trattati non sono oggetto di trasferimento a paesi esteri o
					a organizzazioni internazionali. Il trasferimento dei dati personali può avvenire qualora risulti
					necessario per la gestione dei servizi affidati. Per il trattamento delle informazioni e dei dati
					che saranno eventualmente comunicati a questi soggetti, saranno richiesti gli equivalenti livelli di
					protezione adottati per il trattamento dei dati personali dei propri dipendenti. In ogni caso
					saranno comunicati i soli dati necessari al perseguimento degli scopi previsti e saranno applicati
					gli strumenti normativi previsti dal Capo V del GDPR.
				</p>
				<p>
					7. DIRITTI DELL’INTERESSATO In ogni momento l’interessato potrà esercitare i suoi diritti nei
					confronti del Titolare del trattamento, ai sensi del Regolamento UE 679/2016. Ai sensi degli artt.
					da 15 a 21 del GDPR, l’interessato può esercitare, qualora ne ricorrano i presupposti, il diritto di
					chiedere al titolare del trattamento l'accesso ai dati personali che lo riguardano, la rettifica o
					la cancellazione degli stessi, la limitazione del trattamento, rivolgendosi al Ministero
					dell’Interno, con sede in Piazza del Viminale n. 1, IT – 00184, Roma (numero centralino +39
					06.4651). 8. DIRITTO DI PROPORRE RECLAMO A UN’AUTORITÀ DI CONTROLLO L’interessato ha il diritto di
					proporre reclamo presso un’Autorità di controllo ai sensi dell’art. 77 del GDPR
				</p>
			</div>
		</card>
	</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner';
import { BaseAlert } from '@/components';
import logo from './../assets/img/albo-nazionale-segretari.svg';

/* import validator from 'validator'; */
export default {
	components: {
		BaseAlert,
		VueSimpleSpinner
	},
	data() {
		return {
			token: null,
			type: ['primary', 'info', 'success', 'warning', 'danger'],
			notifications: {
				topCenter: false
			},
			logo
		};
	},
	computed: {
		validEmail() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.setForm.email);
		},
		validInput() {
			return this.validEmail && this.setForm.repeatEmail == this.setForm.email;
		}
	},
	methods: {
		async setPassword() {
			const auth = this.setForm;
			auth.token = this.token;
			auth.app = this.app.code;
			auth.token = await this.$store.dispatch('recoveryPassword', auth);
		}
	},

	async created() {
		this.app = this.$store.getters.app;
		this.token = this.$route.query.token;

		this.app = this.$store.getters.app;
	}
};
</script>
<style></style>
