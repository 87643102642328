<template>
	<card>
		<h5 slot="header" class="title">{{ formLabel }}</h5>
		<form @submit.prevent="onSubmit">
			<base-input label="Ente" placeholder="Ente" v-model="model.ente" disabled> </base-input>

			<base-input label="Indirizzo email" type="email" placeholder="" v-model="model.email">
			</base-input>

			<base-input label="Referente" v-model="model.referente" placeholder=""> </base-input>

			<base-input label="slug" v-model="model.slug" placeholder="" required> </base-input>

			<base-input>
				<label>Note</label>
				<textarea
					rows="2"
					cols="80"
					class="form-control"
					placeholder=""
					v-model="model.note"
				>
				</textarea>
			</base-input>

			Stato pubblicazione
			<div class="col-md-3">
				<toggle-button v-model="model.public" color="#419FC7" :labels="true" />
			</div>
			Stato attivazione
			<div class="col-md-3">
				<toggle-button v-model="model.active" color="#419FC7" :labels="true" />
			</div>

			<submit-button slot="footer" type="primary" fill>Salva</submit-button>
		</form>
	</card>
</template>
<script>
import BaseRadio from '../../components/BaseRadio.vue';
export default {
	components: { BaseRadio },

	computed: {
		formLabel() {
			return this.newRecord ? 'Crea nuovo servizio' : 'Modifica info servizio';
		}
	},
	props: {
		model: {
			type: Object,
			default: () => {
				return {};
			}
		},
		newRecord: { Type: Boolean, default: true }
	},
	methods: {
		onSubmit() {
			if (this.newRecord) {
				const formData = {
					ente: this.model.ente,
					email: this.model.email,
					referente: this.model.referente,
					note: this.model.note,
					slug: this.model.slug,
					public: this.model.public,
					active: this.model.active
				};

				if (!this.$store.getters.slugs.includes(this.model.slug)) {
					this.$store.dispatch('addService', formData).then(this.emptyForm);
				}
			} else {
				const formData = {
					ente: this.model.ente,
					email: this.model.email,
					referente: this.model.referente,
					note: this.model.note,
					slug: this.model.slug,
					public: this.model.public,
					active: this.model.active,
					id: this.model.id
				};
				console.log(formData);

				this.$store.dispatch('updateService', formData).then(this.emptyForm);
			}
		},
		emptyForm() {
			this.model.ente = '';
			this.model.email = '';
			this.model.referente = '';
			this.model.note = '';
			this.model.slug = '';
			this.model.public = false;
			this.model.active = false;
		}
	}
};
</script>
<style></style>
