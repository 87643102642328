<template>
	<card>
		<form @submit.prevent="onSubmit">
			<div class="row">
				<div class="col-md-6">
					<base-input label="Nome" placeholder="" v-model="model.nome"> </base-input>
				</div>

				<div class="col-md-6">
					<base-input label="Cognome" placeholder="" v-model="model.cognome"> </base-input>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<date-picker v-model="model.dataNascita" input-class="form-control">
						<label for="startDate" slot="before">Data di nascita</label>
					</date-picker>
				</div>

				<div class="col-md-6">
					<base-input label="Codice Fiscale" type="email" placeholder="" v-model="model.codiceFiscale">
					</base-input>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<base-input label="email" type="email" placeholder="" v-model="model.email"> </base-input>
				</div>
			</div>

			<submit-button slot="footer" type="primary" fill>Salva</submit-button>
		</form>
	</card>
</template>
<script>
import BaseRadio from '../../components/BaseRadio.vue';
export default {
	components: { BaseRadio },

	computed: {
		formLabel() {
			return this.newRecord ? 'Crea nuovo servizio' : 'Modifica info servizio';
		}
	},
	props: {
		model: {
			type: Object,
			default: () => {
				return {};
			}
		},
		newRecord: { Type: Boolean, default: true }
	},
	methods: {
		onSubmit() {
			const formData = {
				nome: this.app.model.nome,
				cognome: this.app.model.cognome,
				dataNascita: this.app.model.dataNascita,
				codiceFiscale: this.app.model.codiceFiscale,
				email: this.app.model.email
			};
			/* this.$store.dispatch('addUser', formData); */
			console.log(formData);
		},
		emptyForm() {
			this.model.nome = '';
			this.model.cognome = '';
			this.model.dataNascita = '';
			this.model.codiceFiscale = '';
			this.model.email = '';
		}
	}
};
</script>
<style></style>
