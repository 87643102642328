<template>
	<div>
		<!-- 		<vue-simple-spinner class="spinnerCenter" size="70" line-size="5" message="Caricamento..."></vue-simple-spinner>
 -->
		<div class="card">
			<div class="row center">
				<div class="col-xs-6 col-sm-3 col-md-4 col-lg-2 logoContainer">
					<img class="logo" :src="logo" />
				</div>

				<div class="col-sm-8 col-md-8 col-lg-10">
					<div class="card-body">
						<h4 class="card-title">
							<strong>Ministero dell'Interno</strong>
							<br />
							DIPARTIMENTO PER GLI AFFARI INTERNI E TERRITORIALI<br />DIREZIONE CENTRALE PER LE AUTONOMIE
							<br />Albo nazionale dei Segretari Comunali e Provinciali
						</h4>
					</div>
				</div>
			</div>
		</div>
		<card>
			<template v-if="(app.public || user.type == 'admin') && app.code == 'coa2021'">
				<b> CONCORSO {{ app.code.toUpperCase() }} </b> <span> </span> dal {{ app.openTime }} al
				{{ app.closeTime }}
				<h4 class="card-title">{{ app.title }}</h4>

				<div v-if="app.access">
					<div class="row">
						<p>
							Metodo di accesso: <b> {{ app.accessMethod.toUpperCase() }}</b>
						</p>
					</div>

					<div v-if="app.accessMethod == 'spid'">
						<a
							href="https://spid.scanshareservice.it/cas/oidc/authorize?response_type=code&client_id=DemoApp&redirect_uri=https://app.scanshareservice.it/albo-nazionale-segretari/coa2021/signin&scope=openid+profile"
							class="btn btn-primary"
							:class="{ disabled: !signinForm.privacy, btn: true, 'btn-primary': true }"
							>Accedi con SPID</a
						>
						<base-checkbox v-model="signinForm.privacy">
							dichiaro di aver preso visione dell'<router-link :to="{ name: 'privacy' }"
								>Informativa Privacy</router-link
							>
							ed esprimo il consenso al trattamento dei dati personali per le finalità indicate.
						</base-checkbox>
						<small class="form-text" :style="{ color: signinForm.privacy ? 'blue' : 'red' }">{{
							signinForm.privacy ? '' : 'Consenso al trattamento dati obbligatorio'
						}}</small>
						<br />
					</div>

					<div v-else>
						<input type="text" name="username" v-model="signinForm.username" placeholder="Username" />
						<input type="password" name="password" v-model="signinForm.password" placeholder="Password" />
						<button type="button" @click="signin()">Login</button>
					</div>
				</div>

				<div v-else>
					<div class="row">
						<p>
							<b>Accesso non consentito</b>
						</p>
					</div>
				</div>
			</template>

			<template v-else-if="app.public && app.code == 'coa6'">
				<b
					>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code }} SESSIONE
					{{ app.sessione.toUpperCase() }}</b
				>
				<h4 class="card-title">{{ app.title }}</h4>

				<div v-if="app.access">
					<div class="row card-body">
						<b
							><h4 class="card-title">Metodo di accesso: {{ app.accessMethod.toUpperCase() }}</h4></b
						>
						<b
							><h5 class="card-subtitle">
								<a style="cursor: pointer;" @click="recuperoPassword()"
									><b style="text-decoration:underline"
										>Configura o recupera password di accesso</b
									></a
								>
							</h5>
						</b>
					</div>

					<div v-if="app.accessMethod == 'spid'"></div>

					<div v-else>
						<div class="row">
							<base-input
								@change="validate()"
								:class="{ 'col-md-4': true }"
								type="email"
								name="email"
								v-model="signinForm.username"
								placeholder="Email"
							>
							</base-input>
						</div>

						<div class="row">
							<base-input
								type="password"
								name="password"
								v-model="signinForm.password"
								placeholder="password"
								:class="{
									'col-md-4': true,
									'has-danger': false
								}"
							>
							</base-input>
						</div>

						<base-checkbox v-model="signinForm.privacy">
							dichiaro di aver preso visione dell'<router-link :to="{ name: 'privacy' }"
								>Informativa Privacy</router-link
							>
							ed esprimo il consenso al trattamento dei dati personali per le finalità indicate.
						</base-checkbox>
						<small class="form-text" :style="{ color: signinForm.privacy ? 'blue' : 'red' }">{{
							signinForm.privacy ? '' : 'Consenso al trattamento dati obbligatorio'
						}}</small>
						<br />

						<base-button
							:class="{ disabled: !validInput }"
							native-type="submit"
							type="primary"
							@click="signin()"
							>Accedi</base-button
						>
					</div>
				</div>
				<div v-else>
					<div class="row">
						<p>
							<b>Accesso non consentito</b>
						</p>
					</div>
				</div>
			</template>
			<template v-else-if="app.public && app.code == 'coa6-sa'">
				<b
					>CORSO CONCORSO {{ app.code == 'coa6' ? 'CO.A6' : app.code.toUpperCase() }} SESSIONE
					{{ app.sessione.toUpperCase() }}</b
				>
				<h4 class="card-title">{{ app.title }}</h4>

				<div v-if="app.access">
					<div class="row card-body">
						<b
							><h4 class="card-title">Metodo di accesso: {{ app.accessMethod.toUpperCase() }}</h4></b
						>
						<b
							><h5 class="card-subtitle">
								<a style="cursor: pointer;" @click="recuperoPassword()"
									><b style="text-decoration:underline"
										>Configura o recupera password di accesso</b
									></a
								>
							</h5>
						</b>
					</div>
					<div v-if="app.accessMethod == 'spid'"></div>

					<div v-else>
						<div class="row">
							<base-input
								@change="validate()"
								:class="{ 'col-md-4': true }"
								type="email"
								name="email"
								v-model="signinForm.username"
								placeholder="Email"
							>
							</base-input>
						</div>

						<div class="row">
							<base-input
								type="password"
								name="password"
								v-model="signinForm.password"
								placeholder="password"
								:class="{
									'col-md-4': true,
									'has-danger': false
								}"
							>
							</base-input>
						</div>

						<base-checkbox v-model="signinForm.privacy">
							dichiaro di aver preso visione dell'<router-link :to="{ name: 'privacy' }"
								>Informativa Privacy</router-link
							>
							ed esprimo il consenso al trattamento dei dati personali per le finalità indicate.
						</base-checkbox>
						<small class="form-text" :style="{ color: signinForm.privacy ? 'blue' : 'red' }">{{
							signinForm.privacy ? '' : 'Consenso al trattamento dati obbligatorio'
						}}</small>
						<br />

						<base-button
							:class="{ disabled: !validInput }"
							native-type="submit"
							type="primary"
							@click="signin()"
							>Accedi</base-button
						>
					</div>
				</div>
				<div v-else>
					<div class="row">
						<p>
							<b>Accesso non consentito</b>
						</p>
					</div>
				</div>
			</template>
		</card>
	</div>
</template>
<script>
import VueSimpleSpinner from 'vue-simple-spinner';
import { BaseAlert } from '@/components';
import logo from './../assets/img/albo-nazionale-segretari.svg';
import axios from 'axios';
export default {
	components: {
		BaseAlert,
		VueSimpleSpinner
	},
	data() {
		return {
			token: null,
			type: ['primary', 'info', 'success', 'warning', 'danger'],
			notifications: {
				topCenter: false
			},
			logo,
			signinForm: {
				username: '',
				password: '',
				privacy: false,
				accessMethod: ''
			}
		};
	},
	computed: {
		validEmail() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.signinForm.username);
		},
		validInput() {
			return this.validEmail && this.signinForm.privacy ? this.signinForm.password != '' : false;
		},
		user() {
			return this.$store.getters.user;
		}
	},
	methods: {
		async signin() {
			if (this.validEmail && this.validInput && this.signinForm.privacy) {
				let auth = this.signinForm;
				await this.$store.dispatch('signIn', auth);
			}
		},
		recuperoPassword() {
			this.$router.push({ name: 'recoveryPassword', params: { app: this.app.code } });
		},
		validate() {
			let rulesemail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return rulesemail.test(this.signinForm.username);
		}
	},

	async created() {
		this.app = this.$store.getters.app;
		if (this.$store.getters.user && this.$store.getters.user.type != 'admin') {
			this.$router.replace({ name: 'app', params: { app: this.app.code } });
		}
		/* 		this.token = this.$route.query.code;
		this.app = this.$store.getters.app;

		if (this.token) {
			this.$store.dispatch('sendRequestToken', this.token);
		} */
	}
};
</script>
<style>
ul {
	/* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}
li {
	/* Remove bullets */
	padding: 3px; /* Remove padding */
	margin: 0; /* Remove margins */
}
.logo {
	padding-left: 5px;
	margin: 0 auto;
	display: block;
}
.logoContainer {
	display: flex;
	align-items: center;
}
.page {
	width: 21cm;
	min-height: 29.7cm;
	padding: 2cm;
	margin: 1cm auto;
	border: 1px #d3d3d3 solid;
	border-radius: 5px;
	background: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
